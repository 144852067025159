import { DistributionChannelTypes } from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const getDistributionChannel = async (token: string) => {
  const { data } = await apiInstance(token).get<DistributionChannelTypes>(
    `/backoffice/shipto/distribution-channel`
  );
  return data;
};

export const useDistributionChannel = () => {
  const session = useAuthStore();

  const query = useQuery({
    queryKey: ['DistributionChannel'],
    queryFn: () => getDistributionChannel(session.access_token),
    staleTime: 60000,
    retry: false
  });

  const options = useMemo(() => {
    return (query.data?.data || []).map((dc) => ({
      label: dc.distribution_channel_desc,
      value: dc.distribution_channel,
    }));
  }, [query.data?.data]);

  return {
    ...query,
    options,
  };
};
