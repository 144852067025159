import { QueryStringSearch } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useQuery } from '@tanstack/react-query';

import { getProductHighLightDetail, getProductHighLightItem } from '../queries';

export const useProductHighLightDetail = (id: string) => {
  const session = useAuthStore();
  return useQuery({
    queryKey: ['ProductHighLightDetail', id],
    queryFn: () => getProductHighLightDetail(session.access_token, id),
    enabled: id !== '' && id !== undefined
  });
};

export const useProductHighLightItem = (
  id: string,
  params: Readonly<QueryStringSearch>
) => {
  const session = useAuthStore();
  return useQuery({
    queryKey: ['ProductHighLightItem', params],
    queryFn: () => getProductHighLightItem(session.access_token, id, params),
    enabled: id !== '' && id !== undefined
  });
};
