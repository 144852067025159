import {
  adminCaptureException,
  apiInstance,
  buyerCaptureException,
} from '@diamond/shared/utils';
import { AxiosError } from 'axios';

export function PatchDataWrapper<Response>(
  url: string,
  token?: string,
  captureException?: 'admin' | 'buyer',
  mapError?: (error?: string, message?: string) => string
) {
  async function patchData<T>(
    payload: T,
    id?: number | string,
    optionalPath?: number | string
  ): Promise<Response | undefined> {
    try {
      const response = await apiInstance(token).patch(
        optionalPath
          ? `${url}/${optionalPath}`
          : id
          ? `${url}/${id}`
          : `${url}`,
        JSON.stringify({ id, ...payload })
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (captureException === 'admin') {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'PatchDataWrapper error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(url, payload);
            scope.setLevel('error');
          });
        }
        if (captureException === 'buyer') {
          buyerCaptureException(error.response?.data, (scope) => {
            scope.setTags({
              queryKey: 'PatchDataWrapper error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(url, payload);
            scope.setLevel('error');
          });
        }
        let errorMessage = error.response?.data.message;
        if (typeof mapError === 'function') {
          errorMessage = mapError(error.response?.data.error, errorMessage);
        }
        throw new Error(errorMessage);
      }
      return;
    }
  }

  return { patchData };
}
