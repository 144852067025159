import * as Yup from 'yup';

export const CheckoutValidationSchema = Yup.object().shape({
  payment_method: Yup.string()
    .required('Metode Pembayaran harus diisi')
    .nullable(),
  ship_to_contact: Yup.object().shape({
    name: Yup.string()
      .transform((value) => (value ? value.trim() : value))
      .required('Nama Pemesan harus diisi')
      .nullable(),
    telp: Yup.string()
      .matches(/^(628|08)\d+/, 'Nomor HP harus diawali dengan 628 atau 08')
      .matches(/^\d+$/, 'Nomor HP hanya boleh berisi angka')
      .min(10, 'Nomor HP minimal 10 Digit')
      .max(14, 'Nomor HP maksimal 14 Digit')
      .required('Nomor HP harus diisi')
      .nullable(),
  }),
  delivery_date: Yup.string()
    .required('Tanggal pengiriman harus dipilih')
    .nullable(),
});
