import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  SimpleGrid,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { Button, Text } from '@diamond/shared/ui';
import { FC } from 'react';

interface ErrorPageProps {
  message: string;
  subtitle: string;
  buttonTitle: string;
  action: () => void;
}

export const ErrorPage: FC<ErrorPageProps> = ({
  message,
  subtitle,
  buttonTitle,
  action,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Box>
      <Container
        as={SimpleGrid}
        maxW="6xl"
        minH="100vh"
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, md: 'unset' }}
      >
        <Flex align="center" justify="center">
          <VStack spacing={4} align="stretch">
            <Box alignContent="center" alignSelf="center">
              <Image
                src="/assets/images/sukanda-logo.svg"
                alt="Sukanda Onelink"
                height={isMobile ? '30px' : '40px'}
              />
            </Box>
            <Box alignContent="center" alignSelf="center" py={10}>
              <Image
                src="/assets/images/error_Illustration.svg"
                alt="Sukanda Onelink"
                height={isMobile ? '220px' : '340px'}
              />
            </Box>
            <Box
              alignContent="center"
              alignSelf="center"
              py={{ base: 4, md: 6 }}
            >
              <Text
                fontSize={{ base: '2xl', md: '3xl' }}
                fontWeight="bold"
                align="center"
              >
                {message}
              </Text>
              <Text
                fontSize={{ base: 'md', md: 'xl' }}
                fontWeight="medium"
                align="center"
                mt={2}
              >
                {subtitle}
              </Text>
            </Box>
            <Box>
              <Center>
                <Button
                  bg="blue"
                  color="white"
                  variant="solid"
                  _hover={{ bg: 'navy' }}
                  type="button"
                  px={10}
                  onClick={action}
                >
                  {buttonTitle}
                </Button>
              </Center>
            </Box>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default ErrorPage;
