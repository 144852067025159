import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';

export interface ChakraModalProps extends ModalProps {
  title?: string;
  name: string;
  isOpen: boolean;
  // onClose?: () => void;
  onSubmit?: () => void;
  isLoading?: boolean;
  hideAction?: boolean;
  hideCancel?: boolean;
  hideClose?: boolean;
  hideSubmit?: boolean;
  labelCancel?: string;
  labelSubmit?: string;
  idButton?: string;
  buttonColor?: string;
  scrollBehavior?: 'inside' | 'outside';
  actionButtonPosition?: 'left' | 'center' | 'right';
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | 'full';
  fullWidth?: boolean;
  stickyToBottom?: boolean;
  children: React.ReactNode;
  modalContentProps?: ComponentProps<typeof ModalContent>;
  modalBodyProps?: ComponentProps<typeof ModalBody>;
  modalHeaderProps?: ComponentProps<typeof ModalHeader>;
}

export function Modal({
  size,
  title,
  isOpen,
  onClose,
  onSubmit,
  isLoading = false,
  hideAction = false,
  hideCancel = false,
  hideClose = false,
  hideSubmit = false,
  labelCancel = 'Batalkan',
  labelSubmit = 'Oke',
  buttonColor = 'blue',
  scrollBehavior = 'inside',
  actionButtonPosition = 'center',
  fullWidth = false,
  idButton = 'button modal',
  stickyToBottom = false,
  modalContentProps,
  modalBodyProps,
  modalHeaderProps,
  children,
  ...props
}: ChakraModalProps) {
  const handleClose = () => {
    if (onClose) onClose();
  };
  return (
    <ChakraModal
      onClose={handleClose}
      size={size}
      isOpen={isOpen}
      scrollBehavior={scrollBehavior}
      {...props}
    >
      <ModalOverlay />
      <ModalContent
        alignSelf={stickyToBottom ? 'flex-end' : 'initial'}
        paddingBottom={stickyToBottom ? 1 : 4}
        mx={{ base: fullWidth ? 0 : 4, md: 0 }}
        mb={stickyToBottom ? 1 : 4}
        {...modalContentProps}
      >
        {title && (
          <ModalHeader
            fontSize={{ base: '18px', md: '20px' }}
            {...modalHeaderProps}
          >
            {title}
          </ModalHeader>
        )}
        {!hideClose && <ModalCloseButton />}

        <ModalBody {...modalBodyProps}>{children}</ModalBody>
        {!hideAction && (
          <ModalFooter justifyContent={actionButtonPosition}>
            {!hideCancel && (
              <Button
                onClick={handleClose}
                isDisabled={isLoading}
                variant="outline"
                borderWidth="1px"
                borderColor="blue"
                textColor="blue"
                mr={4}
                w={{ base: '28', md: '36' }}
                size={{ base: 'sm', md: 'md' }}
              >
                {labelCancel}
              </Button>
            )}
            {!hideSubmit && (
              <Button
                onClick={onSubmit}
                type={onSubmit ? 'button' : 'submit'}
                isDisabled={isLoading}
                bgColor={buttonColor}
                id={idButton}
                borderColor={buttonColor}
                textColor="white"
                _hover={{ bgColor: buttonColor }}
                _active={{ bgColor: buttonColor }}
                w={{ base: '28', md: '36' }}
                size={{ base: 'sm', md: 'md' }}
              >
                {labelSubmit}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
