import {
  adminCaptureException,
  apiInstance,
  buyerCaptureException,
} from '@diamond/shared/utils';
import { AxiosError } from 'axios';

interface QueryStringSearch {
  [key: string]: string | number | boolean | null | string[];
}

export function DeleteDataWrapper<T>(
  url: string,
  token?: string,
  captureException?: 'admin' | 'buyer'
) {
  async function deleteData<T>(id: number | string): Promise<unknown> {
    try {
      const response = await apiInstance(token).delete<T>(`${url}/${id}`);
      if (response.status !== 200) {
        throw new Error('something goes wrong');
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (captureException === 'admin') {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'DeleteDataWrapper error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(url, id);
            scope.setLevel('error');
          });
          throw new Error(error.response?.data.message);
        }
        if (captureException === 'buyer') {
          buyerCaptureException(error.response?.data, (scope) => {
            scope.setTags({
              queryKey: 'DeleteDataWrapper error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(url, id);
            scope.setLevel('error');
          });
          throw new Error(error.response?.data.message);
        }
      }
      return;
    }
  }

  async function deleteDataCustomUrl<T>(
    custUrl: number | string
  ): Promise<unknown> {
    try {
      const response = await apiInstance(token).delete<T>(`${url}/${custUrl}`);
      if (response.status !== 200) {
        throw new Error('something goes wrong');
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      return;
    }
  }

  async function deleteDataWithObject<T>(
    custUrl: number | string,
    id: string | string[]
  ): Promise<unknown> {
    try {
      const response = await apiInstance(token).delete<T>(`${url}/${custUrl}`, {
        data: {
          product_id: id,
        },
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      return;
    }
  }

  async function deleteDataWithCustomObject<T>(
    custUrl: number | string,
    payload?: Readonly<QueryStringSearch>
  ): Promise<unknown> {
    try {
      const response = await apiInstance(token).delete<T>(`${url}/${custUrl}`, {
        data: payload,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      return;
    }
  }

  return {
    deleteData,
    deleteDataCustomUrl,
    deleteDataWithObject,
    deleteDataWithCustomObject,
  };
}
