import { BackofficeDetailPromoManagement } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useQuery } from '@tanstack/react-query';

import {
  BackofficePromoError,
  getPromoManagementDetail,
} from '../queries/promo';

export const BACKOFFICE_DETAIL_PROMO_QUERY_KEY = 'PromoDetail';

export function usePromoManagementDetail(promo_id: string) {
  const { access_token } = useAuthStore();
  return useQuery<BackofficeDetailPromoManagement, BackofficePromoError>({
    queryKey: [BACKOFFICE_DETAIL_PROMO_QUERY_KEY, promo_id],
    queryFn: () => getPromoManagementDetail(access_token, promo_id),
  });
}
