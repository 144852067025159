/**
 * Read:
 * - https://github.com/pmndrs/zustand/issues/882
 * - https://github.com/pmndrs/zustand/blob/main/docs/guides/updating-state.md
 */

import { ShipTo } from '@diamond/shared/types';
import create from 'zustand';
import { persist } from 'zustand/middleware';

export type ShipToState = {
  selectedShipToId: string;
  selectedShipToDc: string;
  availableShipTo: ShipTo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedShipTo: any;
};

type ActionState = {
  setSelectedShipToId: (userId: ShipToState['selectedShipToId']) => void;
  setSelectedShipToDc: (userId: ShipToState['selectedShipToDc']) => void;
  setSelectedShipTo: (userId: ShipToState['selectedShipTo']) => void;
  setAvailableShipTo: (userId: ShipToState['availableShipTo']) => void;
};

export const useShipToStore = create<ShipToState & ActionState>()(
  persist(
    (set) => ({
      selectedShipToId: '',
      selectedShipToDc: '',
      availableShipTo: [],
      selectedShipTo: null,
      setSelectedShipToId: (selectedShipToId) =>
        set(() => ({ selectedShipToId: selectedShipToId })),
      setSelectedShipToDc: (selectedShipToDc) =>
        set(() => ({ selectedShipToDc: selectedShipToDc })),
      setAvailableShipTo: (availableShipTo) =>
        set(() => ({ availableShipTo: availableShipTo })),
      setSelectedShipTo: (selectedShipTo) =>
        set(() => ({ selectedShipTo: selectedShipTo })),
    }),
    {
      name: 'shipto-store', // must be a unique name
      getStorage: () => localStorage, // sessionStorage or localStorage
    }
  )
);
