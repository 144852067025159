import { Box, Flex, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import { PostDataWrapper } from '@diamond/shared/data-access';
import {
  ADDITIONAL_CLIENT_HEADERS,
  API_URL,
} from '@diamond/shared/environments';
import {
  Breadcrumbs,
  Button,
  Divider,
  Dropzone,
  showToast,
} from '@diamond/shared/ui';
import { adminCaptureException } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { AdminLayout } from '@diamond/sol-admin/common';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export function MinimumOrderPage() {
  const session = useAuthStore();
  const methods = useForm();
  const toast = useToast();

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Minimum Order Management',
      link: '/minimum-order',
    },
  ];

  const { postData } = PostDataWrapper(
    '/backoffice/order/minimum-spend/upload',
    session.access_token,
    true,
    'admin'
  );
  const stringError = methods.formState;
  const formData = new FormData();
  const fileData = methods.watch('file-minimum-order');
  const [isReady, setIsReady] = useState<boolean>(false);

  const onSubmit = methods.handleSubmit(() => {
    if (fileData) {
      formData.append('file', fileData[0], fileData[0].name);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${session.access_token}`,
      },
    };

    postData(formData, config)
      .then(() => {
        showToast(toast, 'success', 'Minimum Order Anda berhasil disimpan!');
        methods.reset();
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
      });
  });

  const downloadTemplate = async () => {
    const windowUrl = window.URL || window.webkitURL;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    fetch(`${API_URL}/backoffice/order/minimum-spend/download/template`, {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
        ...ADDITIONAL_CLIENT_HEADERS,
      },
    })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = windowUrl.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = 'template_minimum_order.xlsx';
        anchor.click();
        windowUrl.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'useQueryGetAll error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setLevel('error');
          });
          showToast(toast, 'error', `${error.message}`);
          throw new Error('Terjadi Kesalahan useQueryData');
        }
        showToast(toast, 'error', `${error.message}`);
      });
  };

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Flex>
            <Text fontSize="2xl" as="b">
              Minimum Order Management
            </Text>
            <Spacer />
            <Button variant="solid" type="submit" isDisabled={!isReady}>
              Simpan
            </Button>
          </Flex>
          <Stack pt={4} pb={8}>
            <Divider />
          </Stack>
          <Stack
            border="1px"
            borderRadius="md"
            borderColor="gray.33"
            px={4}
            py={6}
          >
            <Text fontSize="xl" as="b">
              Pilih File
            </Text>
            <Stack py={4}>
              <Divider variant="dashed" />
            </Stack>
            <Stack border="1px" borderRadius="md" borderColor="gray.33" p={6}>
              <Dropzone
                name="file-minimum-order"
                accept={{
                  'application/vnd.ms-excel': ['.xlsx'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    ['.xlsx'],
                }}
                errors={stringError.errors}
                onEmptied={() => setIsReady(false)}
                setIsFileAttached={(val) => setIsReady(val)}
                customErrorMessage="Maaf, file minimum order tidak sesuai"
              />
              <Flex>
                <Spacer />
                <Text as="sub">Hanya menerima format .XLSX</Text>
              </Flex>
            </Stack>
          </Stack>
          <Stack
            mt={8}
            p={4}
            border="1px"
            borderRadius="md"
            borderColor="gray.33"
            alignItems="flex-start"
          >
            <Text as="b" fontSize="xl">
              Tolong gunakan template dibawah ini
            </Text>
            <Button
              variant="solid"
              bgColor="azure"
              onClick={() => downloadTemplate()}
            >
              Download Template Excel
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </AdminLayout>
  );
}

export default MinimumOrderPage;
