import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import { SelectProps } from './types';

/* eslint-disable-next-line */
export interface DropdownProps extends SelectProps {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  errors?: Partial<FieldErrorsImpl<{ [x: string]: string }>>;
  disablePlaceholder?: boolean;
}

export function Dropdown({
  name,
  items,
  placeholder = 'Pilih satu',
  variant = 'outline',
  label,
  register,
  errors,
  disablePlaceholder,
  ...props
}: DropdownProps) {
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';
  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      {label && (
        <FormLabel
          aria-label={label}
          htmlFor={props.id}
          fontSize={{ base: '12px', md: '16px' }}
        >
          {label}
        </FormLabel>
      )}
      <Select
        fontSize={{ base: '12px', md: '16px' }}
        variant={variant}
        {...(disablePlaceholder === true ? {} : { placeholder: placeholder })}
        borderColor="gray.33"
        _hover={{ borderColor: 'cyan' }}
        _active={{ borderColor: 'blue' }}
        {...register(name)}
        {...props}
      >
        {items &&
          items.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
      </Select>
      <FormErrorMessage textColor="red" fontSize={{ base: '12px', md: '16px' }}>
        {errorMessage as string}
      </FormErrorMessage>
    </FormControl>
  );
}

export default Dropdown;
