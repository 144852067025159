import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch as ChakraSwitch,
  SwitchProps,
} from '@chakra-ui/react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

export interface ToggleButtonProps extends SwitchProps {
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: Partial<FieldErrorsImpl<{ [x: string]: any }>>;
}

export function ToggleButton({
  name,
  label,
  value,
  children,
  errors,
  register,
  ...props
}: ToggleButtonProps) {
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      <FormLabel aria-label={label} htmlFor={props.id}>
        {label}
      </FormLabel>
      <ChakraSwitch
        value={value}
        aria-label={name}
        {...register(name)}
        {...props}
      >
        {children}
      </ChakraSwitch>
      <FormErrorMessage>{errorMessage as string}</FormErrorMessage>
    </FormControl>
  );
}

export default ToggleButton;
