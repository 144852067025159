/**
 * Read:
 * - https://github.com/pmndrs/zustand/issues/882
 * - https://github.com/pmndrs/zustand/blob/main/docs/guides/updating-state.md
 */

import create from 'zustand';
// import createContext from 'zustand/context';
import { persist } from 'zustand/middleware';

type Option = {
  value: string;
  label: string;
};

export type SessionState = {
  userId: string;
  userName: string;
  userEmail: string;
  userRole: string;
  userCatalog: [string];
  activityStatus: string;
  category2: string;
  access_token: string;
  selectedCustomerTypes: Option[];
};

type ActionState = {
  setUserId: (userId: SessionState['userId']) => void;
  setUserName: (userId: SessionState['userName']) => void;
  setUserEmail: (userId: SessionState['userEmail']) => void;
  setUserRole: (userId: SessionState['userRole']) => void;
  setUserCatalog: (userId: SessionState['userCatalog']) => void;
  setActivityStatus: (userId: SessionState['activityStatus']) => void;
  setCategory2: (userId: SessionState['activityStatus']) => void;
  setAccessToken: (userId: SessionState['access_token']) => void;
  setSelectedCustomerTypes: (
    newCustomerTypes: SessionState['selectedCustomerTypes']
  ) => void;
};

export const useAuthStore = create<SessionState & ActionState>()(
  persist(
    (set) => ({
      userId: '',
      userName: '',
      userEmail: '',
      userRole: '',
      userCatalog: [''],
      activityStatus: '',
      category2: '',
      access_token: '',
      selectedCustomerTypes: [],
      setUserId: (userId) => set(() => ({ userId: userId })),
      setUserName: (userName) => set(() => ({ userName: userName })),
      setUserEmail: (userEmail) => set(() => ({ userEmail: userEmail })),
      setUserRole: (userRole) => set(() => ({ userRole: userRole })),
      setUserCatalog: (userCatalog) =>
        set(() => ({ userCatalog: userCatalog })),
      setActivityStatus: (activityStatus) =>
        set(() => ({ activityStatus: activityStatus })),
      setCategory2: (category2) => set(() => ({ category2: category2 })),
      setAccessToken: (access_token) =>
        set(() => ({ access_token: access_token })),
      setSelectedCustomerTypes: (vals) =>
        set(() => ({ selectedCustomerTypes: vals })),
    }),
    {
      name: 'session-store', // must be a unique name
      getStorage: () => localStorage, // sessionStorage or localStorage
    }
  )
);

// This will replace current AuthContext in the future
// export const AuthContext = createContext<typeof useAuthStore>();
