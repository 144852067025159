import { QueryWrapper } from '@diamond/shared/data-access';
import { CancelReason } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';

type queryString = {
  size: number;
  page: number;
};

export const useCancelReason = (queryString: queryString) => {
  const session = useAuthStore();

  const queryCancelReason = QueryWrapper(
    `/backoffice/order/cancel-reason`,
    session.access_token
  );

  const { data: cancelReasonData } =
    queryCancelReason.useQueryGetAll<CancelReason>(
      'CancelReason',
      queryString,
      undefined,
      undefined,
      { prefetchNextPage: false }
    );

  return {
    cancelReasonData,
  };
};
