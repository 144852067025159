import { QueryWrapper } from '@diamond/shared/data-access';
import { AccountContactDetail } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';

export const useAccountContactDetail = (userId: string, shipToId: string) => {
  const session = useAuthStore();

  const query = QueryWrapper('/backoffice/account', session.access_token);

  const { data: accountDetail, isLoading: isLoadingDetail } =
    query.useQueryGetById<AccountContactDetail>(
      `${userId}/ship-to/${shipToId}`,
      'AccountContactDetail'
    );

  return {
    accountDetail,
    isLoadingDetail,
  };
};

export default useAccountContactDetail;
