import { Avatar as ChakraAvatar, AvatarProps } from '@chakra-ui/react';

export interface AvatarChakraProps extends AvatarProps {
  name?: string;
}

export function Avatar({ name, loading = 'lazy', ...props }: AvatarProps) {
  return <ChakraAvatar name={name} loading={loading} {...props} />;
}

export default Avatar;
