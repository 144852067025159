import { UAParser } from 'ua-parser-js';

export const API_URL = process.env['NX_API_URL'] ?? '';

export const DEFAULT_API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const validOS = ['windows', 'linux', 'macos', 'android', 'ios'];
const osName = UAParser().os.name?.toLowerCase().replace(/\s/g, '');

const isValidOS = typeof osName === 'string' && validOS.includes(osName);
const clientOS = (() => {
  if (isValidOS) {
    return osName;
  }
  return 'windows';
})();

const clientBrowserName = UAParser().browser.name;
const clientBrowserVersion = UAParser().browser.version;

export const ADDITIONAL_CLIENT_HEADERS = {
  'X-Client-OS': String(clientOS),
  'X-Client-Device': String(clientBrowserName),
  'X-Client-Version': String(clientBrowserVersion),
};

export const BUYER_BASE_URL = process.env['NX_BUYER_BASE_URL'] ?? '';

export const ADMIN_BASE_URL = process.env['NX_ADMIN_BASE_URL'] ?? '';

// Default auth session expiration is 12 hours (43200 seconds)
export const SESSION_EXPIRY = process.env['NX_SESSION_EXPIRY'] || 43200;

// Default auth session expiration is 15 days (1296000 seconds)
export const REMEMBER_SESSION_EXPIRY =
  process.env['NX_REMEMBER_SESSION_EXPIRY'] || Number(3600 * 24 * 15);

export const PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=com.sukanda.onelink';

export const APPSTORE_URL =
  'https://apps.apple.com/id/app/sukanda-onelink/id1544732426';

export const DEV_BUYER_HOSTNAME = 'diamond-dev-buyer.zero-one.cloud';
export const DEV_ADMIN_HOSTNAME = 'diamond-dev-admin.zero-one.cloud';
export const STAGING_BUYER_HOSTNAME = 'diamond-staging-buyer.zero-one.cloud';
export const STAGING_ADMIN_HOSTNAME = 'diamond-staging-admin.zero-one.cloud';
export const LOCALHOSTNAME = 'localhost';

export const getWebportalAPIURL = () => {
  const env = process.env['ENV'];

  let webportalAPIURL: string | undefined;
  switch (env) {
    case 'STAGING':
    case 'DEVELOPMENT':
      webportalAPIURL = process.env['SCHEDULER_WEBPORTAL_API_URL_STAGING'];
      break;

    case 'PROD':
      webportalAPIURL = process.env['SCHEDULER_WEBPORTAL_API_URL_PROD'];
      break;
  }

  // if no webportal env match, throw error
  if (webportalAPIURL === undefined)
    throw Error(
      `Webportal URL for env ${env} is not found!. Update the environment variables to include correct value`
    );

  return webportalAPIURL;
};
