import { alertAnatomy } from '@chakra-ui/anatomy';
import { AlertStatus, createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

function bgColorStatus(value: AlertStatus) {
  let color = '';
  switch (value) {
    case 'success':
      color = '#DEF0D6';
      break;
    case 'warning':
      color = '#FAECD6';
      break;
    case 'info':
      color = '#D6D6F0';
      break;
    case 'error':
      color = '#FFD6D6';
      break;
    default:
      color = 'gray.33';
      break;
  }
  return color;
}
const subtleVariant = definePartsStyle((props) => {
  const { status } = props;
  return {
    container: {
      borderRadius: 'md',
      _light: {
        bg: bgColorStatus(status),
      },
    },
  };
});

export const alertTheme = defineMultiStyleConfig({
  variants: {
    subtle: subtleVariant,
  },
});
