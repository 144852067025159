import {
  Divider as ChakraDivider,
  DividerProps as ChakraDividerProps,
} from '@chakra-ui/react';

export interface DividerProps extends ChakraDividerProps {
  variant?: 'dashed' | 'solid';
}

export function Divider({ variant = 'solid', ...props }: DividerProps) {
  return (
    <ChakraDivider
      borderColor="gray.75"
      borderRadius="lg"
      zIndex={0}
      variant={variant}
      {...props}
    />
  );
}

export default Divider;
