import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { UseFormRegister } from 'react-hook-form';

/* eslint-disable-next-line */
export interface SearchboxProps extends InputProps {
  register: UseFormRegister<any>;
  placeholder: string;
  isDisabled?: boolean;
  type?: string;
}

export function Searchbox({
  register,
  placeholder,
  isDisabled,
  type = 'text',
  ...props
}: SearchboxProps) {
  const name = 'search_query';
  return (
    <InputGroup mx={0} maxW={{ md: '527px', lg: '672px' }} w="full">
      <InputLeftElement
        pointerEvents="none"
        children={<SearchOutlinedIcon />}
      />
      <Input
        type={type}
        placeholder={placeholder}
        bgColor="white"
        aria-label={name}
        isDisabled={isDisabled}
        {...register(name)}
        {...props}
      />
    </InputGroup>
  );
}

export default Searchbox;
