import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { CardPromoInfo, Modal } from '@diamond/shared/ui';

type ChangeQuantityModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedDataId?: string;
  isDiscount: boolean;
  duration: string;
  text: string;
  item: { id: string; img: string; price: number; qty: number; uom: string };
  onSubmit: () => void;
};
export function ChangeQuantityModal({
  isOpen,
  onClose,
  selectedDataId,
  isDiscount,
  duration,
  text,
  item,
  onSubmit,
}: ChangeQuantityModalProps) {
  return (
    <Modal
      name="change_quantity"
      title="Konfirmasi Perubahan Kuantitas Produk "
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      hideSubmit
      closeOnEsc={false}
      closeOnOverlayClick={false}
      hideCancel
      isCentered
    >
      <Stack gap={5}>
        <Stack>
          <Text fontSize="sm">
            Penggantian jumlah produk akan mempengaruhi promo yang customer
            dapatkan.
          </Text>
          <Text mt={-5} fontSize="sm">
            Apakah Anda yakin ingin melanjutkan perubahan jumlah produk yang
            dipesan?
          </Text>
        </Stack>

        {isDiscount ? (
          <CardPromoInfo
            type="discount"
            duration={duration}
            text={text}
            isInvalid
          />
        ) : (
          <CardPromoInfo
            type="free-item"
            duration={duration}
            text={text}
            isInvalid
            item={item}
          />
        )}
      </Stack>
      <Flex justify="flex-end" gap={2} mt={4}>
        <Button
          variant="outline"
          borderWidth="1px"
          borderColor="blue"
          textColor="blue"
          onClick={onClose}
        >
          Batal
        </Button>
        <Button
          onClick={() => {
            onSubmit();
          }}
        >
          Lanjutkan
        </Button>
      </Flex>
    </Modal>
  );
}
export default ChangeQuantityModal;
