import {
  chakra,
  HStack,
  Stack,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { PatchDataWrapper, PostDataWrapper } from '@diamond/shared/data-access';
import { Banner } from '@diamond/shared/types';
import {
  Button,
  Divider,
  Dropzone,
  Modal,
  showToast,
  Text,
  TextField,
} from '@diamond/shared/ui';
import { isValidUrl } from '@diamond/shared/utils';
import { BannerFormValidationSchema } from '@diamond/shared/validation';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import { CustomerTypeInput } from '../CustomerTypeInput';

interface EditBannerProps {
  isOpen: boolean;
  onClose: () => void;
  bannerData: Banner[0];
}

export function EditBanner({
  isOpen = false,
  onClose,
  bannerData,
}: EditBannerProps) {
  const session = useAuthStore();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(BannerFormValidationSchema),
    shouldUnregister: false,
  });
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countTitleChars, setCountTitleChars] = useState(0);
  const [isReady, setIsReady] = useState<boolean>(true);

  const { postData: postUpload } = PostDataWrapper(
    '/backoffice/banner/upload',
    session.access_token,
    true,
    'admin'
  );
  const { patchData } = PatchDataWrapper(
    `/backoffice/banner`,
    session.access_token
  );

  const onSubmit: SubmitHandler<FieldValues> = async (formValues) => {
    const { title, url, banner, business_segmentation, distribution_channel } =
      formValues;
    const urlForSubmit =
      url && !validator.contains(url, 'http') ? `https://${url}` : url;

    if (url && !isValidUrl(urlForSubmit as string)) {
      return showToast(toast, 'error', 'URL tidak sesuai');
    }

    if (!banner) {
      setIsLoading(true);
      return patchData(
        {
          title,
          ...(urlForSubmit && { url: urlForSubmit }),
          image: bannerData.image,
          business_segmentation:
            business_segmentation?.map((val: any) => val.value) ?? [],
          distribution_channel:
            distribution_channel?.map((val: any) => val.value) ?? [],
        },
        bannerData.id
      )
        .then(async () => {
          showToast(toast, 'success', 'Banner berhasil disimpan');
          navigate(0);
          setIsLoading(false);
        })
        .catch((error) => {
          showToast(toast, 'error', error.message);
          methods.reset();
          setIsLoading(false);
        });
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', banner[0], banner[0].name);

    return postUpload(formValues, {
      headers: {
        authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(async (res: any) => {
        patchData(
          { title, ...(urlForSubmit && { url: urlForSubmit }), image: res.url },
          bannerData.id
        ).then(() => {
          showToast(toast, 'success', 'Banner berhasil disimpan');
          navigate(0);
        });
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
        methods.reset();
        setIsLoading(false);
      });
  };

  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-edit-banner"
      title="Edit Banner"
      hideAction
    >
      <Divider borderColor="black" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack py={4}>
            <Dropzone
              name="banner"
              urlDownload={bannerData.image}
              fileName={bannerData.filename}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={2}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG dan JPG dengan ukuran maksimal 2MB.
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 1312x512 pixel.
            </Text>
          </Stack>
          <Stack pt={4} pb={8}>
            <TextField
              name="title"
              label="Judul Banner"
              placeholder="contoh: Diamond"
              register={methods.register}
              defaultValue={bannerData.title}
              onChange={(e) => setCountTitleChars(e.target.value.length)}
              maxLength={100}
              errors={methods.formState.errors}
            />
            <Text fontSize="sm" color="gray" textAlign="right">
              {`${countTitleChars}/100`}
            </Text>
            <CustomerTypeInput
              selectedBusinessSegmentation={bannerData.business_segmentation}
              selectedDistributionChannel={bannerData.distribution_channel}
            />
            <TextField
              name="url"
              aria-label="Link"
              label={
                <>
                  Link <chakra.span color="gray.75">(Opsional)</chakra.span>
                </>
              }
              placeholder="Masukkan Link"
              register={methods.register}
              defaultValue={bannerData.url as string}
              maxLength={100}
              errors={methods.formState.errors}
            />
          </Stack>
          <Divider borderColor="black" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    isDisabled={isLoading}
                  >
                    Tutup
                  </Button>
                  <Button
                    type="submit"
                    variant="solid"
                    isLoading={isLoading}
                    isDisabled={!isReady}
                  >
                    <span>Simpan</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
}
