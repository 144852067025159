import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertStatus,
  Box,
  CloseButton,
  Icon,
  ToastProps,
} from '@chakra-ui/react';

import { getStatusColorScheme, getStatusIcon } from '../utils';

export interface AlertChakraProps extends ToastProps {
  description: string;
  status: AlertStatus;
  variant?: 'subtle';
}

export function Alert({
  status,
  description,
  variant = 'subtle',
  onClose,
}: AlertChakraProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const baseIcon = getStatusIcon(status!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const colorScheme = getStatusColorScheme(status!);

  return (
    <ChakraAlert
      status={status}
      variant={variant}
      borderRadius="md"
      boxShadow="lg"
    >
      <Box borderRadius="lg" display="flex" p={1} mr={4} bgColor={colorScheme}>
        <Icon as={baseIcon} color="white" />
      </Box>
      <AlertDescription display="block">{description}</AlertDescription>
      {onClose && (
        <CloseButton
          size="md"
          onClick={onClose}
          position="absolute"
          insetEnd={1}
        />
      )}
    </ChakraAlert>
  );
}

export default Alert;
