import { Container } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

interface AdminLayoutProps {
  children: ReactNode;
  pageTitle?: string;
  maxWidth?: string;
}

export function AdminLayout({
  children,
  maxWidth = 'container.xl',
}: AdminLayoutProps) {
  return (
    <>
      <Header />
      <Container maxW={maxWidth} as="main" mt="8">
        {children}
      </Container>
      <Footer />
    </>
  );
}

export default AdminLayout;
