import * as Yup from 'yup';
const PASSWD_VALIDATION = /^(?=.*[A-Za-z])(?=.*\d).{5,}$/;

export const DeleteAccountValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email harus email yang valid')
    .required('Email harus diisi')
    .label('Email'),
  name: Yup.string().required('Nama harus diisi').label('Nama Lengkap'),
  reason: Yup.string()
    .required('Alasan harus diisi')
    .label('Alasan Penghapusan'),
});

export const ChangePasswordtValidationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Silakan masukkan kata sandi lama')
    .label('Kata Sandi Lama'),
  new_password: Yup.string()
    .required('Silakan masukkan kata sandi baru')
    .label('Kata Sandi Baru')
    .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka'),
  confirm_new_password: Yup.string()
    .required('Silakan masukkan konfirmasi kata sandi baru')
    .oneOf(
      [Yup.ref('new_password')],
      'Kata sandi harus sama dengan kata sandi baru'
    )
    .label('Konfirmasi Kata Sandi Baru')
    .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka'),
});
