import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface IconButtonProps extends ChakraIconButtonProps {
  name: string;
  icon: ReactElement;
  children?: React.ReactNode;
  variant?: 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled';
}

export function IconButton({
  name,
  icon,
  variant = 'ghost',
  children,
  ...props
}: IconButtonProps) {
  return (
    <ChakraIconButton
      name={name}
      variant={variant}
      textAlign="center"
      icon={icon}
      {...props}
    />
  );
}
