import {
  Box,
  Center,
  CircularProgress,
  HStack,
  Input,
  Stack,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  DeleteDataWrapper,
  PatchDataWrapper,
  PostDataWrapper,
} from '@diamond/shared/data-access';
import {
  Button,
  Divider,
  DropResult,
  IconButton,
  SelectAutocomplete,
  showToast,
  Sortable,
  SortableItem,
  Text,
  TextField,
} from '@diamond/shared/ui';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import {
  getProducts,
  reorderProductHighLightItem,
  useProductHighLightDetail,
  useProductHighLightItem,
} from '@diamond/sol-admin-context';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { UseMutationResult } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { SelectedOption } from 'react-select-search';
import * as Yup from 'yup';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CustomerTypeInput } from '../../../common/src/lib/components/CustomerTypeInput';

const ENDPOINT_PRODUCT_HIGHLIGHT = '/backoffice/product/highlight';

const FormValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Judul harus di isi')
    .trim('Harus berupa karakter')
    .strict(true),
});

export interface FormHighlightProps {
  id: string;
  modalState: 'edit' | 'create';
  allProductHighlightData: { id: string; title: string }[];
  createHighlight: UseMutationResult;
  onClose: () => void;
  refetch: () => void;
}

function mapDistributionChannelAndBusinessSegmentation(values: any) {
  return {
    distribution_channel:
      values.distribution_channel?.map((val: any) => val.value) ?? [],
    business_segmentation:
      values.business_segmentation?.map((val: any) => val.value) ?? [],
  };
}

export function FormHighlight({
  id,
  modalState,
  allProductHighlightData,
  createHighlight,
  onClose,
  refetch,
}: FormHighlightProps) {
  const session = useAuthStore();
  const toast = useToast();

  const [updatedItems, setUpdatedItems] = useState<string[]>([]);
  const [options, setOptions] = useState<{ value: string; name: string }[]>([]);
  const [selectedName, setSelectedName] = useState<string>('');

  const methods = useForm({
    resolver: yupResolver(FormValidationSchema),
  });

  const {
    handleSubmit,
    register,
    watch,
    resetField,
    reset,
    control,
    formState: { errors },
  } = methods;
  const productsOptions = useMemo(() => {
    return options;
  }, [watch('product'), selectedName]);

  const {
    data: dataItem,
    isFetching,
    refetch: refetchProductHighlightItem,
  } = useProductHighLightItem(id, {
    size: 999,
    page: 1,
  });

  const { data: detailData } = useProductHighLightDetail(id);

  const { fields, prepend, remove, move } = useFieldArray({
    control,
    name: 'products',
    shouldUnregister: true,
  });

  const { postData: postDataItems } = PostDataWrapper(
    `${ENDPOINT_PRODUCT_HIGHLIGHT}/${id}/item`,
    session.access_token,
    false,
    'admin'
  );

  const { deleteDataWithCustomObject: deleteItem } = DeleteDataWrapper(
    ENDPOINT_PRODUCT_HIGHLIGHT,
    session.access_token
  );

  async function addProduct() {
    const item = watch('product');
    if (modalState === 'edit') {
      setUpdatedItems([...updatedItems, item]);
    }
    const products = getProductIds();
    const duplicate = products?.includes(item);

    if (duplicate) {
      showToast(toast, 'error', 'Produk Sudah Ada!');
    } else {
      prepend({
        product_id: item,
        product_title: selectedName,
      });

      if (modalState === 'edit') {
        await postDataItems({ products: [item] }).catch((error) => {
          showToast(
            toast,
            'error',
            `Gagal mengupdate item,\n message: ${error.message}`
          );
        });
      }
    }
  }

  async function removeProduct(index: number, productId: string) {
    if (modalState === 'edit') {
      await deleteItem(`${id}/item`, { products: [productId] }).catch(
        (error) => {
          showToast(
            toast,
            'error',
            `Gagal menghapus item,\n message: ${error.message}`
          );
        }
      );
    }
    remove(index);
  }

  const { patchData } = PatchDataWrapper(
    ENDPOINT_PRODUCT_HIGHLIGHT,
    session.access_token,
    'admin'
  );

  const updateProductHighLight = async (values: any) => {
    await patchData(values, id)
      .then(() => {
        showToast(toast, 'success', 'Produk Highlight Anda berhasil diubah!');
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
      });
  };

  const getProductIds = () => {
    const products = watch('products');
    return products?.map((val: { product_id: string }) => {
      return val.product_id;
    });
  };

  const onSubmit: SubmitHandler<any> = async (values) => {
    const products = getProductIds();

    if (modalState === 'create') {
      const payload = {
        index: allProductHighlightData.length + 1,
        title: values.title,
        ...mapDistributionChannelAndBusinessSegmentation(values),
        products: [...products].reverse(),
      };
      createHighlight.mutate(payload);
      onClose();
    } else if (modalState === 'edit') {
      await updateProductHighLight({
        title: values.title,
        ...mapDistributionChannelAndBusinessSegmentation(values),
      });
      onClose();
      refetch();
    }
  };

  const isEditing =
    modalState === 'edit' && detailData && id !== '' && !isFetching;

  useEffect(() => {
    remove();
    if (modalState === 'edit') {
      if (detailData && id !== '' && !isFetching) {
        reset(
          {
            ...detailData,
            business_segmentation: undefined,
            distribution_channel: undefined,
          },
          {
            keepIsSubmitted: true,
            keepTouched: true,
            keepDirty: true,
            keepDefaultValues: !isFetching,
          }
        );
        if (dataItem && !isFetching && id !== '') {
          dataItem.data.forEach((val: { id: string; title: string }) => {
            prepend({ product_id: val.id, product_title: val.title });
          });
        }
      }
    }
  }, [
    detailData,
    dataItem,
    id,
    modalState,
    reset,
    remove,
    isFetching,
    prepend,
  ]);

  const handleReorder = async (result: DropResult) => {
    if (result.destination && modalState === 'edit') {
      await reorderProductHighLightItem(
        session.access_token,
        id,
        result.draggableId,
        fields.length - result.destination.index
      );
      refetchProductHighlightItem();
    }
  };

  const items: SortableItem[] = fields.map((item, index) => ({
    // @ts-expect-error product_id exist in item
    id: item['product_id'],
    children: (
      <HStack key={item.id} alignItems="end">
        <TextField
          name={`products.${index}.product_title`}
          register={register}
          errors={errors}
          isRequired
          isDisabled
          border="none"
          _disabled={{ cursor: 'not-allowed' }}
        />
        <Input type="hidden" {...register(`products.${index}.product_id`)} />
        <IconButton
          icon={<DeleteOutlined />}
          name="hapus-produk"
          aria-label="hapus-produk"
          color="white"
          bgColor="red"
          _hover={{ bgColor: 'maroon' }}
          _active={{ bgColor: 'red' }}
          onClick={() => {
            // @ts-expect-error product_id exist in item
            removeProduct(index, item['product_id']);
          }}
        />
      </HStack>
    ),
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack minH="96" marginTop={0}>
          <TextField
            name="title"
            label="Judul"
            placeholder="contoh: Diamond"
            register={register}
            errors={errors}
            labelProps={{ fontSize: 'small' }}
            isRequired
          />

          <CustomerTypeInput
            selectedBusinessSegmentation={
              isEditing ? detailData.business_segmentation : undefined
            }
            selectedDistributionChannel={
              isEditing ? detailData.distribution_channel : undefined
            }
          />

          <HStack alignItems="end" marginTop={2}>
            <SelectAutocomplete
              name="product"
              label="Pilih Produk"
              placeholder="Pilih Produk"
              emptyMessage={<Text>Produk Tidak Ditemukan</Text>}
              debounce={300}
              options={productsOptions}
              onChange={(_, options) => {
                const { name } = options as SelectedOption;
                setSelectedName(name);
              }}
              getOptions={(query) => {
                return new Promise((resolve, reject) => {
                  getProducts(session.access_token, {
                    page: 1,
                    size: 100,
                    search_query: query,
                  })
                    .then((val) => {
                      const data = val.data.map(
                        (item: { id: string; title: string }) => ({
                          value: item.id,
                          name: item.title,
                        })
                      );
                      if (watch('product') && !query) {
                        resolve(productsOptions);
                      } else {
                        resolve(data);
                      }
                      setOptions(data);
                    })
                    .catch(reject);
                });
              }}
              labelProps={{ fontSize: 'small' }}
            />
            <IconButton
              icon={<AddOutlined />}
              name="tambah-produk"
              aria-label="tambah-produk"
              color="white"
              bgColor="blue"
              _hover={{ bgColor: 'azure' }}
              _active={{ bgColor: 'blue' }}
              isDisabled={!watch('product')}
              onClick={() => {
                addProduct();
                resetField('product');
              }}
            />
          </HStack>
          {isFetching && (
            <Center py="4">
              <CircularProgress isIndeterminate color="blue" size="40px" />
            </Center>
          )}

          <Box id="form-highlight-list">
            {!isFetching ? (
              <Sortable
                handleReorderIndex={move}
                handleChange={handleReorder}
                data={items}
                sortableCardProps={{
                  padding: 0,
                  border: 0,
                  shadow: 'none',
                  bgColor: 'transparent',
                }}
              />
            ) : null}
          </Box>
        </Stack>
        <Box position="sticky" bottom={0} bg="white">
          <Stack py={2}>
            <Divider borderColor="black" />
          </Stack>
          <Wrap justify="end">
            <WrapItem>
              <HStack>
                <Button
                  size="sm"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Tutup
                </Button>
                {modalState === 'create' ? (
                  <Button
                    type="submit"
                    variant="solid"
                    size="sm"
                    isDisabled={fields.length < 1}
                  >
                    Tambahkan
                  </Button>
                ) : (
                  <Button size="sm" type="submit" variant="solid">
                    Simpan
                  </Button>
                )}
              </HStack>
            </WrapItem>
          </Wrap>
        </Box>
      </form>
    </FormProvider>
  );
}
