export const isValidUrl = (urlString: string): boolean => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const isEven = (num: number): boolean => {
  const integerPart = Math.floor(num);
  return integerPart % 2 === 0;
};

export function isDecimal(value: string): boolean {
  const sanitizedValue = value.replace(',', '.');
  const parsedValue = parseFloat(sanitizedValue);

  if (isNaN(parsedValue)) {
    return false;
  }

  if (sanitizedValue.includes(',')) {
    const [, fractionalPart] = sanitizedValue.split('.');
    return fractionalPart.length > 0;
  }

  return parsedValue % 1 !== 0;
}

export const passwordValidation = (input: string): boolean => {
  const PASSWD_VALIDATION = /^(?=.*[A-Za-z])(?=.*\d).{5,}$/;
  return PASSWD_VALIDATION.test(input);
};
