import { DataProps } from '@diamond/shared/types';
import * as SentryNext from '@sentry/nextjs';
import * as SentryReact from '@sentry/react';

export function adminCaptureException(
  response: DataProps,
  configureScopeCallback: (scope: SentryReact.Scope) => void
) {
  return SentryReact.captureException(
    new Error(JSON.stringify(response)),
    (scope) => {
      configureScopeCallback(scope as SentryReact.Scope);
      return scope;
    }
  );
}

export function buyerCaptureException(
  response: DataProps,
  configureScopeCallback: (
    scope: SentryNext.Scope,
    user?: SentryNext.User
  ) => void
) {
  return SentryNext.captureException(
    new Error(JSON.stringify(response)),
    (scope) => {
      const currentUser = scope.getUser();
      configureScopeCallback(scope as SentryNext.Scope, currentUser);
      return scope;
    }
  );
}
