import { createColumnHelper } from '@tanstack/react-table';
import { HTMLProps, useEffect, useRef } from 'react';

import { Activities } from './types';

const columnHelper = createColumnHelper<Activities>();

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input type="checkbox" ref={ref} className="cursor-pointer" {...rest} />
  );
}

export const tableColumnActivities: any = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }: any) => (
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  },
  columnHelper.accessor('id', {
    cell: (info) => info.getValue(),
    header: 'ID',
  }),
  columnHelper.accessor('type', {
    cell: (info) => info.getValue(),
    header: 'Type',
  }),
  columnHelper.accessor('action_time', {
    cell: (info) => info.getValue(),
    header: 'Action Time',
  }),
  columnHelper.accessor('account_external_id', {
    cell: (info) => info.getValue(),
    header: 'Account External ID',
  }),
  columnHelper.accessor('account_name', {
    cell: (info) => info.getValue(),
    header: 'Account Name',
  }),
  columnHelper.accessor('account_distribution_channel', {
    cell: (info) => info.getValue(),
    header: 'Account Distribution Channel',
  }),
  columnHelper.accessor('catalog_external_id', {
    cell: (info) => info.getValue(),
    header: 'Catalog External ID',
  }),
];
