import {
  BackofficeActivitiesOrderItems,
  BackofficeOrderDetail,
} from '@diamond/shared/types';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

type UseUpdateActivitiesDetailColumnArgs = {
  onUpdate: (value: string) => void;
  allowEmpty?: boolean;
};

type UseUpdateActivitiesDetailQueryDataArgs = {
  queryKey: QueryKey;
  name: string;
  info: CellContext<BackofficeActivitiesOrderItems[number], unknown>;
};

export function useUpdateActivitiesDetailQueryData({
  queryKey,
  name,
  info,
}: UseUpdateActivitiesDetailQueryDataArgs) {
  const queryClient = useQueryClient();
  return (value: string | number | boolean | null) =>
    queryClient.setQueryData<BackofficeOrderDetail>(queryKey, (oldData) => {
      return oldData
        ? {
            ...oldData,
            order_items: oldData.order_items.map((e) => {
              if (e.id === info.row.original.id) {
                return { ...e, [name]: value };
              }
              return e;
            }),
          }
        : undefined;
    });
}

export function useUpdateActivitiesDetailColumn({
  onUpdate,
  allowEmpty = false,
}: UseUpdateActivitiesDetailColumnArgs) {
  const [value, setValue] = useState<string>();
  const debouncedValue = useDebounce<string | undefined>(value, 500);
  useEffect(() => {
    const shouldTriggerUpdate = allowEmpty
      ? debouncedValue !== undefined
      : !!debouncedValue;

    if (shouldTriggerUpdate) {
      onUpdate(debouncedValue as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return setValue;
}
