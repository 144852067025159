import {
  Currency,
  Dinero,
  dinero,
  multiply,
  toDecimal,
  toSnapshot,
} from 'dinero.js';

export const IDR: Currency<number> = {
  code: 'IDR',
  base: 10,
  exponent: 0,
};

export const STOCK: Currency<number> = {
  code: 'STOCK',
  base: 10,
  exponent: 3,
};

export const STOCK_UI: Currency<number> = {
  code: 'STOCK',
  base: 10,
  exponent: 3,
};

// terms magnifier inspired by xendit articles about a story how
// they persists monetary value into DB
export const STOCK_MAGNIFIER = (STOCK.base as number) ** STOCK.exponent;

export function dineroFromFloat(value: number, currency: Currency<number>) {
  const factor = (currency.base as number) ** currency.exponent;
  const amount = Math.round(value * factor);

  return dinero({ amount, currency, scale: currency.exponent });
}

export function magnifyValue(value: number | string, type: 'idr' | 'stock') {
  const rawValue: number = typeof value === 'string' ? Number(value) : value;
  let dineroObj: Dinero<number>;
  switch (type) {
    case 'idr':
      dineroObj = dineroFromFloat(rawValue, IDR);
      break;

    case 'stock':
      dineroObj = dineroFromFloat(rawValue, STOCK);
      break;
  }

  const snapshot = toSnapshot(dineroObj);
  return {
    magnifiedValue: snapshot.amount,
    magnifier: (snapshot.currency.base as number) * snapshot.currency.exponent,
    raw: dineroObj,
  };
}

export function toDinero(value: number, type: 'stock' | 'idr'): Dinero<number> {
  let dineroObj: Dinero<number>;
  switch (type) {
    case 'idr':
      dineroObj = dineroFromFloat(value, IDR);
      break;

    case 'stock':
      dineroObj = dineroFromFloat(value, STOCK);
      break;
  }

  return dineroObj;
}

export function dineroStockMultiplyCeil(
  dineroObj: Dinero<number>,
  mutiplier: number
): number {
  const dineroMultipl = multiply(dineroObj, {
    amount: mutiplier,
    scale: STOCK.exponent,
  });

  return Math.ceil(Number(toDecimal(dineroMultipl)));
}
