import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      color: 'black',
    },
  },
  variants: {
    outline: {
      borderColor: 'black',
      field: {
        boxShadow: 'gray.75',
        _focus: {
          borderColor: 'black',
          _placeholder: {
            color: 'black',
          },
        },
      },
    },
  },
};
