import { BackofficeOrderDetail } from '@diamond/shared/types';

type Order = BackofficeOrderDetail['order'];

export function formatDeliveryDate(
  deliveryOption?: Order['delivery_option'],
  deliveryDate?: Order['delivery_date']
): string {
  if (!deliveryOption || !deliveryDate) return '-';
  if (deliveryOption === 'immediate') return 'Pengiriman Segera';
  const offset = deliveryOption[deliveryOption.length - 1];
  return `H+${offset} Pemesanan • ${deliveryDate}`;
}
