import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useQuery } from '@tanstack/react-query';

import { getOrderHistory } from '../queries';

const ORDER_HISTORY_QUERY_KEY = 'OrderHistory';

export function useOrderHistory(activityId: string | undefined) {
  const { access_token } = useAuthStore();
  return useQuery({
    queryKey: [ORDER_HISTORY_QUERY_KEY, activityId],
    queryFn: () => getOrderHistory(access_token, activityId || ''),
    enabled: !!activityId,
  });
}
