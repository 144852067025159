import { AlertStatus, Spinner } from '@chakra-ui/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const STATUSES = {
  info: { icon: InfoOutlinedIcon, colorScheme: 'blue', textColor: 'navy' },
  warning: {
    icon: ErrorOutlineOutlinedIcon,
    colorScheme: 'orange',
    textColor: 'orange',
  },
  success: {
    icon: CheckCircleOutlineIcon,
    colorScheme: 'green',
    textColor: 'green',
  },
  error: {
    icon: ErrorOutlineOutlinedIcon,
    colorScheme: 'red',
    textColor: 'maroon',
  },
  loading: { icon: Spinner, colorScheme: 'blue', textColor: 'navy' },
};

export function getStatusIcon(val: AlertStatus) {
  return STATUSES[val].icon;
}

export function getStatusColorScheme(status: AlertStatus) {
  return STATUSES[status].colorScheme;
}

export function getTextColor(status: AlertStatus) {
  return STATUSES[status].textColor;
}
