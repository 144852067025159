import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

export interface InputDateProps extends InputProps {
  name: string;
  register: UseFormRegister<FieldValues>;
  errors?: Partial<FieldErrorsImpl<{ [x: string]: string }>>;
}

export function InputDate({
  name,
  errors,
  register,
  ...props
}: InputDateProps) {
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <InputGroup>
      <InputLeftElement
        children={<Icon as={CalendarTodayOutlinedIcon} color="gray.75" />}
      />
      <Input
        variant="outline"
        placeholder="DD/MM/YYYY"
        {...register(name)}
        {...props}
      />
      {errorMessage && <span>This field is required</span>}
      <InputRightElement
        children={<Icon as={ExpandMoreOutlinedIcon} color="gray.75" />}
      />
    </InputGroup>
  );
}

export default InputDate;
