import { ShipmentDates } from '@diamond/shared/types';
import { apiInstance, buyerCaptureException } from '@diamond/shared/utils';
import { isAxiosError } from 'axios';

export class ShipmentDateException extends Error {
  constructor() {
    super('ShipmentDateException');
  }
}

type ShipmentDateFetcher = {
  mockFailedRequest?: 'empty' | 'throw' | number;
};

type CreateShipmentDateFetcherFn = (
  token: string,
  warehouse: string
) => Promise<ShipmentDates>;

function createShipmentDateFetcher({
  // Change this to any number to mock request
  mockFailedRequest,
}: ShipmentDateFetcher): CreateShipmentDateFetcherFn {
  let failedAttempt = 0;

  async function fetchShipmentDates(
    token: string,
    warehouse: string
  ): Promise<ShipmentDates> {
    const { data } = await apiInstance(token).get<ShipmentDates>(
      `/order/shipment-dates?warehouse=${warehouse}`
    );
    return data;
  }

  async function mockFetchShipmentDates(
    token: string,
    warehouse: string
  ): Promise<ShipmentDates> {
    if (mockFailedRequest === 'throw')
      throw new Error('ShipmentDates mock throw error');
    if (mockFailedRequest === 'empty')
      return Promise.resolve({
        dates_available: [],
        dates_available_ui: [],
      });

    const res =
      failedAttempt === mockFailedRequest
        ? Promise.resolve({
            dates_available: [
              '2024-08-07',
              '2024-08-08',
              '2024-08-09',
              '2024-08-10',
              '2024-08-12',
            ],
            dates_available_ui: [
              '07/08/24',
              '08/08/24',
              '09/08/24',
              '10/08/24',
              '12/08/24',
            ],
          } as ShipmentDates)
        : Promise.resolve({
            dates_available: [],
            dates_available_ui: [],
          });

    failedAttempt++;

    return res;
  }

  return mockFailedRequest ? mockFetchShipmentDates : fetchShipmentDates;
}

export function isShipmentDatesEmpty(data: ShipmentDates) {
  return (
    data.dates_available === null ||
    data.dates_available_ui === null ||
    data.dates_available.length === 0 ||
    data.dates_available_ui.length === 0
  );
}

const fetchShipmentDates = createShipmentDateFetcher({
  // Change this to to mock failed request
  // mockFailedRequest: 'empty',
  // mockFailedRequest: 'throw',
  // mockFailedRequest: 2,
});

export const getShipmentDates = async (token: string, warehouse: string) => {
  try {
    const data = await fetchShipmentDates(token, warehouse);

    if (isShipmentDatesEmpty(data)) {
      throw new ShipmentDateException();
    }
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      buyerCaptureException(error.response?.data, (scope, user) => {
        scope.setTag('ShipmentDates', `warehouse ${warehouse}`);
        scope.setUser({
          ...user,
        });
        scope.setLevel('error');
      });
    } else if (error instanceof ShipmentDateException) {
      buyerCaptureException({ message: error.message }, (scope, user) => {
        scope.setTag('ShipmentDates', `warehouse ${warehouse}`);
        scope.setTag('type', 'ShipmentDateEmptyException');
        scope.setUser({
          ...user,
        });
        scope.setLevel('error');
      });
    }
    throw new ShipmentDateException();
  }
};
