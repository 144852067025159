import { BackofficePromoQuery } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getPromoData } from '../queries';

export const BACKOFFICE_PROMO_QUERY_KEY = 'BackofficePromo';

export function useBackofficePromo(
  status: BackofficePromoQuery,
  search_query: string,
  size: number
) {
  const { access_token } = useAuthStore();
  return useInfiniteQuery({
    queryKey: [BACKOFFICE_PROMO_QUERY_KEY, status, search_query, size],
    queryFn: ({ pageParam }) =>
      getPromoData(access_token, status, pageParam, search_query, size),
    initialPageParam: 1,
    getNextPageParam: ({ nextPage }) => nextPage,
  });
}
