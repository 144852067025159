import { useAuthStore } from '@diamond/sol-admin/authentication';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { getBannerById, getBanners } from '../queries/banner';

export function getBannerOptions(access_token: string) {
  return queryOptions({
    queryKey: ['Banners'],
    queryFn: () => getBanners(access_token),
  });
}

export const useGetBanners = () => {
  const session = useAuthStore();
  return useQuery(getBannerOptions(session.access_token));
};

export const useGetBannerById = (id: string) => {
  const session = useAuthStore();
  return useQuery({
    queryKey: ['Banner', id],
    queryFn: () => getBannerById(session.access_token, id),
  });
};
