import { Box, FlexProps, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import React, { CSSProperties, FC, ReactElement } from 'react';

import SortableCard from './sortable-card';
import type { SortableItem } from './types';

const scrollContainerHeight = 250;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

interface Props {
  listId?: string;
  listType?: string;
  items: SortableItem[];
  internalScroll?: boolean;
  scrollContainerStyle?: CSSProperties;
  isDropDisabled?: boolean;
  style?: CSSProperties;
  ignoreContainerClipping?: boolean; // may not be provided - and might be null
  sortableCardProps?: FlexProps;
  isPromotion?: boolean;
  handleClickIndexing?: (id: string) => void;
  pageLoadedIndex?: number[];
  isShowButton?: boolean;
}

interface SortableItemListProps {
  items: SortableItem[];
  sortableCardProps?: FlexProps;
  isPromotion?: boolean;
  handleClickIndexing?: (id: string) => void;
  pageLoadedIndex?: number[];
  isShowButton?: boolean;
}

function InnerSortableItemList(props: SortableItemListProps): ReactElement {
  return (
    <>
      {props.items.map((item: SortableItem, index: number) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(
            dragProvided: DraggableProvided,
            dragSnapshot: DraggableStateSnapshot
          ) => (
            <SortableCard
              key={item.id}
              item={item}
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
              props={props.sortableCardProps}
              isPromotion={props.isPromotion}
              handleClickIndexing={props.handleClickIndexing}
              pageLoadedIndex={props.pageLoadedIndex}
              isShowButton={props.isShowButton}
            />
          )}
        </Draggable>
      ))}
    </>
  );
}

const InnerSortableItemListMemo = React.memo<SortableItemListProps>(
  InnerSortableItemList
);

interface InnerListProps {
  dropProvided: DroppableProvided;
  items: SortableItem[];
  sortableCardProps?: FlexProps;
  isPromotion?: boolean;
  handleClickIndexing?: (id: string) => void;
  pageLoadedIndex?: number[];
  isShowButton?: boolean;
}

const InnerList: FC<InnerListProps> = ({
  items,
  dropProvided,
  sortableCardProps,
  isPromotion,
  handleClickIndexing,
  pageLoadedIndex,
  isShowButton,
}) => {
  return (
    <VStack
      spacing={2}
      align="stretch"
      maxW="container.xl"
      ref={dropProvided.innerRef}
    >
      <InnerSortableItemListMemo
        items={items}
        sortableCardProps={sortableCardProps}
        isPromotion={isPromotion}
        handleClickIndexing={handleClickIndexing}
        pageLoadedIndex={pageLoadedIndex}
        isShowButton={isShowButton}
      />
      {dropProvided.placeholder}
    </VStack>
  );
};

export default function SortableItemList(props: Props): ReactElement {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    listId = 'LIST',
    listType,
    items,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={false}
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot
      ) => (
        <Box
          backgroundColor={
            dropSnapshot.isDraggingOver ||
            Boolean(dropSnapshot.draggingFromThisWith)
              ? 'gray.50'
              : 'white'
          }
          opacity={isDropDisabled ? 0.5 : 'inherit'}
          {...dropProvided.droppableProps}
          minWidth="inherit"
          maxW="inherit"
          alignItems="center"
          gap="2"
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                items={items}
                dropProvided={dropProvided}
                sortableCardProps={props.sortableCardProps}
                isPromotion={props.isPromotion}
                handleClickIndexing={props.handleClickIndexing}
                pageLoadedIndex={props.pageLoadedIndex}
                isShowButton={props.isShowButton}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              items={items}
              dropProvided={dropProvided}
              sortableCardProps={props.sortableCardProps}
              isPromotion={props.isPromotion}
              handleClickIndexing={props.handleClickIndexing}
              pageLoadedIndex={props.pageLoadedIndex}
              isShowButton={props.isShowButton}
            />
          )}
        </Box>
      )}
    </Droppable>
  );
}
