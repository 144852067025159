import {
  ADDITIONAL_CLIENT_HEADERS,
  API_URL,
} from '@diamond/shared/environments';
import axios, { AxiosRequestConfig } from 'axios';

function getContentType(config?: AxiosRequestConfig<unknown>) {
  const contentType: Record<string, string> =
    (config?.method ?? '').toUpperCase() === 'DELETE'
      ? {}
      : { 'Content-Type': 'application/json' };
  return contentType;
}

function getTimeoutConfig(): AxiosRequestConfig {
  return {
    timeout: 120_000,
  };
}

/**
 * to call api with token that automatically returns the JSON
 *
 * @param token Bearer token
 * @param config AxiosRequestConfig
 * @param baseURL Additional base url options
 */
export function apiInstance(
  token?: string | null,
  config?: AxiosRequestConfig<unknown>,
  baseURL = API_URL
) {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      ...ADDITIONAL_CLIENT_HEADERS,
      ...config?.headers,
      ...getContentType(config),
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    ...getTimeoutConfig(),
    ...config,
  });

  return axiosInstance;
}
