import {
  Box,
  Center,
  Flex,
  HStack,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  SimpleGrid,
  Spacer,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import { PatchDataWrapper } from '@diamond/shared/data-access';
import { AccountContactTransactionItem } from '@diamond/shared/types';
import {
  Button,
  DataTable,
  DISPLAY_IN_LIMITS,
  Divider,
  Dropdown,
  Icon,
  Pagination,
  Searchbox,
  showToast,
  Text,
} from '@diamond/shared/ui';
import {
  adminCurrencyFormatter,
  adminDateFormatter,
} from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { IndeterminateCheckbox } from '@diamond/sol-admin/common';
import { useAccountContactTransaction } from '@diamond/sol-admin-context';
import { Edit } from '@mui/icons-material';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

export interface TransactionListProps {
  shipToId: string;
  userId: string;
}

export function TransactionList({ shipToId, userId }: TransactionListProps) {
  const session = useAuthStore();
  const toast = useToast();
  const { register, watch } = useFormContext();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const searchQuery = useDebounce<string>(watch('search_query'), 1000);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const { patchData: processOrder } = PatchDataWrapper(
    '/backoffice/order/process',
    session.access_token,
    'admin'
  );

  const columnHelper = createColumnHelper<AccountContactTransactionItem>();

  const {
    accountTranscation,
    refetchTransaction,
    totalTransaction,
    countTransaction,
  } = useAccountContactTransaction(userId, shipToId, {
    size: size,
    page: page,
    search_query: searchQuery && searchQuery.trimStart().trimEnd(),
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'page-size') {
        setSize(+value[name]);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [setSize, watch]);

  const activitiesData = accountTranscation ? accountTranscation.data : [];

  const tableColumnActivities: any = [
    {
      id: 'select',
      header: ({ table }: any) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          onClick={() => {
            handleSelectAll(
              table.getRowModel().rows,
              table.getIsAllRowsSelected()
            );
          }}
        />
      ),
      cell: ({ row }: any) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          onClick={() => {
            handleSelectOneRow(row, row.getIsSelected());
          }}
        />
      ),
    },
    columnHelper.accessor('transaction_id', {
      cell: (info) => (
        <ChakraLink
          as={Link}
          textDecoration="underline"
          to={`/activities/${info.row.original.id}`}
        >
          {info.getValue()}
        </ChakraLink>
      ),
      header: 'Nomor PO',
    }),
    columnHelper.accessor('sub_po_number', {
      cell: (info) => (
        <ChakraLink
          as={Link}
          textDecoration="underline"
          to={`/activities/${info.row.original.id}`}
        >
          {info.getValue()}
        </ChakraLink>
      ),
      header: 'Nomor Sub PO',
    }),
    columnHelper.accessor('type', {
      cell: (info) => info.getValue(),
      header: 'Type',
    }),
    columnHelper.accessor('created_at', {
      cell: (info) => {
        return adminDateFormatter(info.getValue());
      },
      header: 'Action Time',
    }),
    columnHelper.accessor('account_external_id', {
      cell: (info) => info.getValue(),
      header: 'Account External ID',
    }),
    columnHelper.accessor('account_name', {
      cell: (info) => info.getValue(),
      header: 'Account Name',
    }),
    columnHelper.accessor('account_distribution_channel', {
      cell: (info) => info.getValue(),
      header: 'Account Distribution Channel',
    }),
    columnHelper.accessor('catalog_external_id', {
      cell: (info) => {
        const masking = [
          {
            label: 'Diamond',
            value: 'diamond',
          },
          {
            label: 'Trading',
            value: 'trading',
          },
          {
            label: 'FineFood',
            value: 'food',
          },
          {
            label: 'FineFood',
            value: 'fnb',
          },
          {
            label: 'Equipment',
            value: 'equipment',
          },
        ];
        const outsideDAMasking = [
          {
            label: 'Food',
            value: 'diamond',
          },
          {
            label: 'Food',
            value: 'trading',
          },
          {
            label: 'Food',
            value: 'food',
          },
          {
            label: 'Food',
            value: 'fnb',
          },
          {
            label: 'Equipment',
            value: 'equipment',
          },
        ];
        const catalogMasking = (catalog: string) => {
          if (info.row.original.account_distribution_channel === 'DA') {
            return masking.filter((val) => val.value === catalog)[0];
          } else {
            return outsideDAMasking.filter((val) => val.value === catalog)[0];
          }
        };

        return catalogMasking(info.getValue() as string).label;
      },
      header: 'Catalog External ID',
    }),
  ];

  const handleSelectAll = (values: any, isChecked: boolean) => {
    let list = [];
    if (!isChecked) {
      for (let i = 0; i < values.length; i++) {
        const el = values[i];
        list.push(el);
      }
    } else {
      list = [];
    }
    setSelectedData(list);
  };

  const handleSelectOneRow = (value: any, isCheck: boolean) => {
    const list = [...selectedData];
    if (list.length > 0 && isCheck) {
      list.splice(list.indexOf(value), 1);
      setSelectedData(list);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };

  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    page,
    setPage,
    refetchTransaction,
    countTransaction
  );

  const handleProsesPesanan = () => {
    for (let i = 0; i < selectedData.length; i++) {
      const element = selectedData[i].original;
      processOrder('', element.id)
        .then(() => {
          showToast(toast, 'success', 'Order Berhasil Diproses');
        })
        .catch(({ response }) => {
          showToast(toast, 'error', response.data.message);
        });
    }
  };

  const handleProsesActivities = () => {
    for (let i = 0; i < selectedData.length; i++) {
      const element = selectedData[i].original;
      processOrder('', element.id)
        .then(() => {
          showToast(toast, 'success', 'Order Berhasil Diproses');
        })
        .catch(({ response }) => {
          showToast(toast, 'error', response.data.message);
        });
    }
  };

  return (
    <Stack>
      <Flex>
        {selectedData.length === 1 ? (
          <Menu>
            <MenuButton as={Button} aria-label="menu" variant="solid" px={0}>
              <Icon as={Edit} />
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem onClick={handleProsesPesanan}>
                  Proses Pesanan
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        ) : selectedData.length > 1 ? (
          <Menu>
            <MenuButton as={Button} aria-label="menu" variant="solid" px={0}>
              <Icon as={Edit} />
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem onClick={handleProsesActivities}>
                  Process Activities
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        ) : null}
        <Center>
          <Text textColor="blue" ml={4} verticalAlign="baseline">
            {`${totalTransaction} Hasil`}
          </Text>
        </Center>
        <Spacer />
        <HStack>
          <Searchbox register={register} placeholder="Cari Produk ..." />
          <Dropdown
            name="total_sum"
            placeholder={`Total Nilai Order: ${adminCurrencyFormatter(
              accountTranscation?.total_price
            )}`}
            items={[
              {
                label: `Total Jumlah Barang: ${accountTranscation?.total_items}`,
                value: '2',
              },
            ]}
            register={register}
          />
        </HStack>
      </Flex>
      <Stack py={4}>
        <Divider borderWidth="1px" borderColor="gray.33" />
      </Stack>
      <SimpleGrid columns={2} pb={4}>
        <Text as="b">Nama: {accountTranscation?.name}</Text>
        <Text as="b">ShiptoID: {accountTranscation?.ship_to_id}</Text>
      </SimpleGrid>
      <DataTable data={activitiesData} columns={tableColumnActivities} />
      <Center>
        <Pagination
          currentPage={page}
          totalPage={countTransaction}
          nextPage={nextPage}
          firstPage={firstPage}
          lastPage={lastPage}
          previousPage={previousPage}
          filled={false}
        />
        <Box ml={8}>
          <HStack>
            <Text fontSize="sm">Lihat</Text>
            <Dropdown
              register={register}
              name="page-size"
              placeholder=""
              items={DISPLAY_IN_LIMITS}
            />
            <Text fontSize="sm">Per&nbsp;halaman</Text>
          </HStack>
        </Box>
      </Center>
    </Stack>
  );
}

export default TransactionList;
