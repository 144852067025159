/* eslint-disable @typescript-eslint/no-empty-function */
import { apiInstance } from '@diamond/shared/utils';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { useAuthStore } from './useAuthStore';

type AuthContext = {
  loggedIn: boolean;
  isAdmin: boolean;
  isCsKatalog: boolean;
  isCsCabang: boolean;
  isTokenValid: number;
  login: (token: string) => void;
  logout: (token: string) => void;
  logoutTokenNotValid: () => void;
  checkStatusToken: () => void;
};

export const DefaultAuthContext: AuthContext = {
  loggedIn: false,
  isAdmin: false,
  isTokenValid: 0,
  isCsKatalog: false,
  isCsCabang: false,
  logoutTokenNotValid: () => {},
  login: () => {},
  logout: () => {},
  checkStatusToken: () => {},
};

export const AuthContext = createContext(DefaultAuthContext);

export function AuthProvider({ children }: { children?: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [
    userRole,
    access_token,
    setUserId,
    setUserName,
    setUserEmail,
    setUserRole,
    setUserCatalog,
    setAccessToken,
    setActivityStatus,
  ] = useAuthStore(
    (s) => [
      s.userRole,
      s.access_token,
      s.setUserId,
      s.setUserName,
      s.setUserEmail,
      s.setUserRole,
      s.setUserCatalog,
      s.setAccessToken,
      s.setActivityStatus,
    ],
    shallow
  );

  const isTokenExist =
    access_token !== null && access_token !== undefined && access_token !== '';

  const [loggedIn, setLoggedIn] = useState(isTokenExist);

  const [isAdmin, setIsAdmin] = useState<boolean>(
    Boolean(userRole === 'super_admin')
  );
  const [isTokenValid, setTokenValid] = useState<number>(200);
  const [isCsKatalog, setIsCsKatalog] = useState<boolean>(
    Boolean(userRole === 'cs_katalog')
  );

  const [isCsCabang, setIsCsCabang] = useState<boolean>(
    Boolean(userRole === 'cs_cabang')
  );
  const checkStatusToken = async () => {
    try {
      const response = await apiInstance(access_token).get('/account/me');
      if (response.data.statusCode) {
        setTokenValid(response.data.statusCode);
      } else {
        setTokenValid(200);
      }
    } catch (error: any) {
      setTokenValid(error.response.status);
    }
  };
  useEffect(() => {
    if (access_token) {
      checkStatusToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const login = async (token: string) => {
    try {
      const { data: userMeta } = await apiInstance(token).get('/account/me');
      const user_name = `${userMeta.first_name} ${userMeta.last_name}`;

      setUserId(userMeta.id);
      setUserName(user_name);
      setUserEmail(userMeta.email);
      setUserRole(userMeta.role);
      setUserCatalog(userMeta.catalog);
      setAccessToken(token);
      setActivityStatus('semua');

      const checkIsAdmin = userMeta.role === 'super_admin';
      const checkIsCsKatalog = userMeta.role === 'cs_katalog';
      const checkIsCsCabang = userMeta.role === 'cs_cabang';

      const allowedRoles = ['super_admin', 'cs_katalog', 'cs_cabang'];

      if (!allowedRoles.includes(userMeta.role)) {
        localStorage.clear();
        setLoggedIn(false);
        navigate('/login');
      }

      setIsAdmin(checkIsAdmin);
      setIsCsKatalog(checkIsCsKatalog);
      setIsCsCabang(checkIsCsCabang);
      setLoggedIn(true);
    } catch (error: any) {
      console.error('Error decoding JWT:', error.message);
    }
  };

  const logout = async (token: string) => {
    const response = await apiInstance(token).delete('/auth/logout');
    if (response.status === 204) {
      localStorage.clear();
      setLoggedIn(false);
      navigate('/login');
    } else {
      throw new Error('something goes wrong');
    }
  };
  const logoutTokenNotValid = () => {
    setTokenValid(200);
    localStorage.clear();
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        isAdmin,
        isCsKatalog,
        isCsCabang,
        isTokenValid,
        logoutTokenNotValid,
        login,
        logout,
        checkStatusToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthentication(): AuthContext {
  return useContext(AuthContext);
}
