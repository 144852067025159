import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  Icon,
} from '@chakra-ui/react';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ChakraBreadcrumbProps extends BreadcrumbProps {
  links: {
    title?: string;
    link?: string;
  }[];
  useRouterDom?: boolean;
}

export function Breadcrumbs({
  links,
  useRouterDom = false,
  ...props
}: ChakraBreadcrumbProps) {
  return (
    <ChakraBreadcrumb
      spacing="6px"
      separator={<Icon as={KeyboardArrowRightOutlinedIcon} color="gray.75" />}
      overflow="visible"
      {...props}
    >
      {links.map((item, index) => (
        <BreadcrumbItem
          key={index}
          color={index === links.length - 1 ? 'blue' : 'gray.75'}
          alignItems={{ base: 'center', md: 'stretch' }}
          alignSelf={{ base: 'center', md: 'stretch' }}
          fontSize="14px"
        >
          {useRouterDom ? (
            <BreadcrumbLink
              as={Link}
              to={item.link ?? ''}
              _hover={{ textDecoration: 'none' }}
              lineHeight="1.5rem"
              whiteSpace="nowrap"
            >
              {item.title}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              href={item.link ?? ''}
              lineHeight="1.5rem"
              _hover={{ textDecoration: 'none' }}
              whiteSpace="nowrap"
            >
              {item.title}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  );
}

export default Breadcrumbs;
