import {
  Box,
  Center,
  Spacer,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  DeleteDataWrapper,
  PostDataWrapper,
  QueryWrapper,
} from '@diamond/shared/data-access';
import { ProductHighlight } from '@diamond/shared/types';
import {
  ActionButton,
  Breadcrumbs,
  Button,
  Divider,
  DropResult,
  Modal,
  PageLoader,
  showToast,
  Sortable,
  SortableItem,
  Text,
} from '@diamond/shared/ui';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { AdminLayout, HighlightItem } from '@diamond/sol-admin/common';
import { FormHighlight } from '@diamond/sol-admin-components';
import AddIcon from '@mui/icons-material/Add';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

const ENDPOINT_PRODUCT_HIGHLIGHT = '/backoffice/product/highlight';

export function HighlightPage() {
  const session = useAuthStore();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const [modalState, setModalState] = useState<'create' | 'edit'>('create');
  const [id, setId] = useState<string>('');

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Highlight Management',
      link: '/highlight',
    },
  ];

  const { postData } = PostDataWrapper(
    ENDPOINT_PRODUCT_HIGHLIGHT,
    session.access_token,
    false,
    'admin'
  );

  const { deleteData } = DeleteDataWrapper(
    ENDPOINT_PRODUCT_HIGHLIGHT,
    session.access_token,
    'admin'
  );

  const {
    data: allProductHighlight,
    refetch: refetchProductHighlight,
    isLoading,
  } = QueryWrapper(
    ENDPOINT_PRODUCT_HIGHLIGHT,
    session.access_token
  ).useQueryGet<ProductHighlight>('ProductHighlight');

  const allProductHighlightData =
    allProductHighlight !== undefined && allProductHighlight.length > 0
      ? allProductHighlight.map((val: { id: string; title: string }) => {
          return {
            id: val.id,
            title: val.title,
          };
        })
      : [];

  const createHighlight = useMutation({
    mutationFn: async (payload: unknown) => {
      postData(payload)
        .then(() => {
          refetchProductHighlight();
          showToast(
            toast,
            'success',
            'Produk Highlight Anda berhasil disimpan!'
          );
        })
        .catch((error) => {
          showToast(toast, 'error', error.message);
        });
    },
  });

  const highlights: SortableItem[] = allProductHighlightData.map(
    (highlight) => {
      return {
        id: highlight.id,
        children: (
          <HighlightItem
            data={highlight}
            ActionButton={() => (
              <ActionButton
                onClickEdit={() => {
                  handleEdit(highlight.id);
                }}
                onClickDelete={() => {
                  setId(highlight.id);
                  onOpenDelete();
                }}
              />
            )}
          />
        ),
      };
    }
  );

  const updateIndex = async (id: string, index: number) => {
    return await apiInstance(session.access_token).patch(
      `${ENDPOINT_PRODUCT_HIGHLIGHT}/${id}/reorder`,
      { index }
    );
  };

  const handleIndexChange = (result: DropResult) => {
    if (result.destination) {
      updateIndex(result.draggableId, result.destination.index + 1);
    }
  };

  async function handleEdit(id: string) {
    setId(id);
    setModalState('edit');
    onOpen();
  }

  function handleDelete(id: string) {
    deleteData(id)
      .then(() => {
        refetchProductHighlight();
        showToast(toast, 'success', 'Produk Highlight Anda berhasil dihapus!');
      })
      .catch((err) => {
        showToast(toast, 'error', err.message);
      });
  }

  function handleAddHighlight() {
    setModalState('create');
    onOpen();
  }

  if (isLoading) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack spacing={8} direction="row" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Highlight Product
          </Text>
          <Spacer />
          <Button
            leftIcon={<AddIcon />}
            bg="blue"
            color="white"
            variant="solid"
            _hover={{ bg: 'navy' }}
            onClick={handleAddHighlight}
          >
            Tambah Highlight
          </Button>
        </Stack>
      </Box>

      <Divider />

      <Box my={8}>
        <Sortable data={highlights} handleChange={handleIndexChange} />
      </Box>

      <Modal
        title={`${
          modalState === 'edit' ? 'Edit' : 'Tambahkan'
        } Highlight Product`}
        size="xl"
        name="form-add-highlight-product"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setId('');
        }}
        isCentered
        hideAction
        modalBodyProps={{ pb: 0 }}
        modalContentProps={{
          maxH: 'clamp(0px, 100%, 750px)',
          mt: 0,
        }}
        modalHeaderProps={{ fontSize: { base: '16px', md: '18px' }, pb: 2 }}
      >
        <FormHighlight
          id={id}
          modalState={modalState}
          allProductHighlightData={allProductHighlightData}
          createHighlight={createHighlight}
          onClose={onClose}
          refetch={refetchProductHighlight}
        />
      </Modal>
      <Modal
        name="delete-highlight"
        isOpen={isOpenDelete}
        labelCancel="Kembali"
        labelSubmit="Hapus"
        buttonColor="red"
        onClose={onCloseDelete}
        onSubmit={() => {
          handleDelete(id);
          onCloseDelete();
        }}
        isCentered
      >
        <Center>
          <Text fontSize="2xl" as="b">
            Hapus Highlight?
          </Text>
        </Center>
      </Modal>
    </AdminLayout>
  );
}

export default HighlightPage;
