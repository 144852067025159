import LoadingOverlay from '../overlay/loading-overlay';

interface PageLoaderProps {
  text?: string;
}

export function PageLoader({ text = 'Memuat data...' }: PageLoaderProps) {
  return <LoadingOverlay isLoading={true} text={text} />;
}

export default PageLoader;
