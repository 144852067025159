import * as Yup from 'yup';

const PASSWD_VALIDATION = /^(?=.*[A-Za-z])(?=.*\d).{5,}$/;

export const RegisterValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email yang Anda masukkan salah')
    .required('Email harus diisi')
    .label('Email'),
  first_name: Yup.string()
    .required('Nama Depan harus diisi')
    .trim('Masukkan Nama Depan yang valid')
    .strict(true)
    .label('Nama Depan'),
  last_name: Yup.string()
    .trim('Masukkan Nama Belakang yang valid')
    .strict(true)
    .label('Nama Belakang'),
  password: Yup.string()
    .required('Kata Sandi harus diisi')
    .label('Kata Sandi')
    .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka'),
  confirm_password: Yup.string()
    .required('Konfirmasi Kata Sandi harus diisi')
    .oneOf(
      [Yup.ref('password')],
      'Kata sandi harus sama dengan konfirmasi kata sandi'
    )
    .label('Konfirmasi Kata Sandi')
     .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka'),
  customer_code: Yup.string()
    .required('Kode Customer harus diisi')
    .trim('Masukkan Kode Customer yang valid')
    .strict(true)
    .label('Kode Bill To Customer'),
  company_name: Yup.string()
    .required('Nama Perusahaan harus diisi')
    .trim('Masukkan Nama Perusahaan yang valid')
    .strict(true)
    .label('Nama Perusahaan'),
});

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email yang Anda masukkan salah')
    .required('Email harus diisi')
    .label('Email'),
  password: Yup.string().required('Kata sandi harus diisi').label('Password'),
});

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email yang Anda masukkan salah')
    .required('Email harus diisi')
    .label('Email'),
});

export const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Kata sandi baru harus diisi')
    .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .label('Kata Sandi'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Kata sandi tidak sesuai')
    .required('Konfirmasi kata sandi baru harus diisi')
    .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .label('Konfirmasi Kata Sandi'),
});

export const ChangePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Kata sandi sekarang harus diisi')
    .label('Kata Sandi'),
  new_password: Yup.string()
    .required('Kata sandi baru harus diisi')
     .min(5, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .label('Kata Sandi Baru'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Kata sandi tidak sesuai')
    .min(5, 'Kata Sandi minimal 5 Karakter')
    .required('Konfirmasi kata sandi baru harus diisi')
    .oneOf(
      [Yup.ref('new_password')],
      'Konfirmasi kata sandi baru harus sesuai dengan kata sandi baru'
    )
    .matches(PASSWD_VALIDATION, 'Kata Sandi minimal 5 karakter dan 1 angka')
    .label('Konfirmasi Kata Sandi'),
});

export const EmailPemulihanValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email yang Anda masukkan salah')
    .required('Email harus diisi')
    .label('Email'),
});
