import {
  Box,
  Center,
  chakra,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import { PatchDataWrapper } from '@diamond/shared/data-access';
import { BackofficeOrderData } from '@diamond/shared/types';
import {
  Breadcrumbs,
  Button,
  Divider,
  Dropdown,
  Icon,
  LoadingOverlay,
  Modal,
  PageLoader,
  Pagination,
  RangeDatePicker,
  showToast,
  Text,
  TextField,
} from '@diamond/shared/ui';
import {
  adminCurrencyFormatter,
  apiInstance,
  dateToUnix,
  getSAPErrorMessage,
  numberSeparator,
  sapErrors,
} from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { AdminLayout } from '@diamond/sol-admin/common';
import { IndeterminateCheckbox } from '@diamond/sol-admin/common';
import {
  ActivitiesSmartSearch,
  OrderHistoryModal,
} from '@diamond/sol-admin-components';
import {
  useActivitiesData,
  useCustomerTypes,
} from '@diamond/sol-admin-context';
import { Edit } from '@mui/icons-material';
import { DateRangeOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { CellContext, HeaderContext, Row } from '@tanstack/react-table';
import cleanDeep from 'clean-deep';
import { format, subDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { query } from 'urlcat';
import shallow from 'zustand/shallow';

import { columns } from './ActivitiesColumns';
import { StickyTable } from './StickyTable';

const SUBMIT_ORDER_URL = 'backoffice/order/submit';

function getDefaultParams(today: Date) {
  const dateFormat = 'dd-MM-yyyy';
  const defaultStartDate = format(subDays(today, 7), dateFormat);
  const defaultEndDate = format(today, dateFormat);

  const defaultSearchParams = {
    page: '1',
    query: '',
    distribution_channel: '',
    startdate: defaultStartDate,
    enddate: defaultEndDate,
  };

  return defaultSearchParams;
}

export function ActivitiesPage() {
  const session = useAuthStore();
  const date = useDisclosure();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingSmartSearch, setIsLoadingSmartSearch] = useState(false);
  const [isLoadingActivitiesSearch, setIsLoadingActivitiesSearch] =
    useState(false);

  const [
    activityStatus,
    category2,
    selectedCustomerTypes,
    setActivityStatus,
    setCategory2,
  ] = useAuthStore(
    (s) => [
      s.activityStatus,
      s.category2,
      s.selectedCustomerTypes,
      s.setActivityStatus,
      s.setCategory2,
    ],
    shallow
  );
  const [selectedData, setSelectedData] = useState<Row<BackofficeOrderData>[]>(
    []
  );

  const customerTypesQuery = useCustomerTypes();

  const { patchData: submitOrder } = PatchDataWrapper(
    SUBMIT_ORDER_URL,
    session.access_token,
    'admin',
    (error, message) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (sapErrors.includes(error as any)) {
        return getSAPErrorMessage(error);
      }
      return message || '';
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [todayDate] = useState(() => new Date());
  const defaultSearchParams = getDefaultParams(todayDate);

  const setParams = useCallback(
    (nextParams: Record<string, string>) => {
      const prevParams = {
        ...defaultSearchParams,
        ...Object.fromEntries(searchParams.entries()),
      };

      setSearchParams({
        ...cleanDeep({ ...prevParams, ...nextParams }),
        startdate:
          nextParams['startdate'] !== undefined ? nextParams['startdate'] : '',
        enddate:
          nextParams['enddate'] !== undefined ? nextParams['enddate'] : '',
      });
    },
    [defaultSearchParams, searchParams, setSearchParams]
  );

  const params = useMemo(() => {
    const rawParams = {
      ...defaultSearchParams,
      ...Object.fromEntries(searchParams.entries()),
    };
    return {
      ...rawParams,
      page: Number(rawParams.page),
      startdate: rawParams.startdate.split('-').join('/'),
      enddate: rawParams.enddate.split('-').join('/'),
    };
  }, [defaultSearchParams, searchParams]);

  const methods = useForm({
    defaultValues: {
      status: activityStatus,
      distribution_channel: params.distribution_channel,
      'order_date-from': params.startdate.split('-').join('/'),
      'order_date-to': params.enddate.split('-').join('/'),
    } as FieldValues,
  });

  const searchMethods = useForm({
    defaultValues: {
      search_query: params.query,
    } as FieldValues,
  });

  const handleSubmitSearch: SubmitHandler<FieldValues> = (data) => {
    setParams({ page: '1', query: data['search_query'] });
    setIsLoadingActivitiesSearch(true);
  };

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'status' || name === 'customer_types') {
        setIsLoadingSmartSearch(true);
        setParams({ page: '1' });
      }

      if (name === 'distribution_channel') {
        setIsLoadingSmartSearch(true);
        setParams({ page: '1', distribution_channel: value[name] });
      }
      if (name === 'order_date-from' || name === 'order_date-to') {
        setParams({
          page: '1',
          startdate: value['order_date-from']?.split('/').join('-'),
          enddate: value['order_date-to']?.split('/').join('-'),
        });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [setParams, methods, setActivityStatus]);

  const handleFilterTanggal = (status: string) => {
    const today = new Date();
    const dateFormat = 'dd/MM/yyyy';
    let dateFrom;
    let dateEnd = format(today, dateFormat);
    switch (status) {
      case 'all':
        dateFrom = '';
        dateEnd = '';
        break;
      case '7d':
        dateFrom = format(subDays(today, 7), dateFormat);
        break;
      case '1m':
        dateFrom = format(subDays(today, 30), dateFormat);
        break;
      case '3m':
        dateFrom = format(subDays(today, 90), dateFormat);
        break;
      case '6m':
        dateFrom = format(subDays(today, 180), dateFormat);
        break;
      case '1y':
        dateFrom = format(subDays(today, 365), dateFormat);
        break;
      default:
        break;
    }

    methods.setValue('order_date-from', dateFrom);
    methods.setValue('order_date-to', dateEnd);
  };

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Activities',
      link: '/activities',
    },
  ];

  const unixStartDate = params.startdate
    ? dateToUnix(params.startdate, '00:00:00', true)
    : null;
  const unixEndDate = params.enddate
    ? dateToUnix(params.enddate, '23:59:59', true)
    : null;

  const queryString = {
    search_query: params.query.trimStart().trimEnd(),
    page: params.page,
    search_by: '',
    order_by: '',
    direction: '',
    category_2:
      category2 === 'processed' || category2 === 'semua' ? '' : category2,
    status: activityStatus !== 'semua' ? activityStatus : '',
    start_date: unixStartDate,
    end_date: unixEndDate,
    distribution_channel: params.distribution_channel,
    cg_1_business_segmentation: selectedCustomerTypes.map((it) => it.value),
  };

  const { activitiesData, pageCount, total, refetch, isLoading, isFetched } =
    useActivitiesData(queryString);
  const tableData = activitiesData?.data ? activitiesData?.data : [];

  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    params.page,
    (value) => setParams({ page: `${value}` }),
    refetch,
    pageCount
  );

  function handleSelectAll(
    values: Row<BackofficeOrderData>[],
    isChecked: boolean
  ) {
    let list = [];
    if (!isChecked) {
      for (let i = 0; i < values.length; i++) {
        const el = values[i];
        list.push(el);
      }
    } else {
      list = [];
    }
    setSelectedData(list);
  }

  function handleSelectOneRow(
    value: Row<BackofficeOrderData>,
    isCheck: boolean
  ) {
    const list = [...selectedData];
    if (list.length > 0 && isCheck) {
      list.splice(list.indexOf(value), 1);
      setSelectedData(list);
    } else {
      setSelectedData([...selectedData, value]);
    }
  }

  const isNotSubmitted =
    selectedData.length > 0 &&
    selectedData.some((value) => value.original.status !== 'submitted');

  const checkboxColumn = [
    {
      id: 'select',
      header: ({ table }: HeaderContext<BackofficeOrderData, unknown>) => {
        return (
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            onClick={() => {
              handleSelectAll(
                table.getRowModel().rows,
                table.getIsAllRowsSelected()
              );
            }}
          />
        );
      },
      cell: ({ row }: CellContext<BackofficeOrderData, unknown>) => {
        return (
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
            onClick={() => {
              handleSelectOneRow(row, row.getIsSelected());
            }}
          />
        );
      },
    },
  ];

  const baseColumns = useMemo(() => {
    return columns({
      customerTypeRender(id) {
        return customerTypesQuery.maps[id] || '-';
      },
    });
  }, [customerTypesQuery.maps]);

  const tableColumns = [...checkboxColumn, ...baseColumns];

  function handleProcessOrder() {
    if (selectedData.length <= 2) {
      setIsLoadingSubmit(true);
      selectedData.forEach((value) => {
        submitOrder('', value.original.id)
          .then(async () => {
            showToast(toast, 'success', 'Berhasil Submit Order');
            setIsLoadingSubmit(false);
            window.location.reload();
          })
          .catch((error) => {
            showToast(toast, 'error', error.message);
            setIsLoadingSubmit(false);
            refetch();
          });
      });
    } else if (selectedData.length > 2) {
      showToast(toast, 'error', 'Hanya Dapat Proses Pesanan Hingga 2 Order');
    }
  }

  // Modal Status History per Activity Row
  const handleStatusHistory = () => {
    onOpen();
  };

  useEffect(() => {
    if (isFetched && isLoadingSmartSearch) {
      setIsLoadingSmartSearch(false);
    }
    if (isFetched && isLoadingActivitiesSearch) {
      setIsLoadingActivitiesSearch(false);
    }
  }, [isFetched, isLoadingSmartSearch, isLoadingActivitiesSearch]);

  const handleExportExcel = async () => {
    setIsLoadingExport(true);
    const windowUrl = window.URL || window.webkitURL;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    const queryParams = {
      ...queryString,
      size: 1,
    };

    await apiInstance(session.access_token)
      .get('/backoffice/order/export', {
        params: cleanDeep(queryParams),
        paramsSerializer: (params) => {
          return query(params, { arrayFormat: 'repeat' });
        },
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob',
      })
      .then((response) => {
        const objectUrl = windowUrl.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        anchor.href = objectUrl;
        anchor.download =
          response.headers['content-disposition'].split('filename=')[1];
        anchor.click();

        document.body.removeChild(anchor);
        windowUrl.revokeObjectURL(objectUrl);
        setIsLoadingExport(false);
      })
      .catch(({ response }) => {
        setIsLoadingExport(false);
        const idToast = 'error-export';
        if (response.status === 422) {
          showToast(
            toast,
            'error',
            'Pilih tanggal untuk melakukan export data.',
            idToast,
            undefined,
            5000
          );
        }
      });
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  const FilterButton = () => {
    return [
      <Box display={{ base: 'none', md: 'flex' }}>
        <Button
          px="4"
          variant={category2 === 'non_commodity' ? 'solid' : 'outline'}
          onClick={() => {
            if (category2 !== 'non_commodity') {
              methods.setValue('category_2', 'non_commodity');
              methods.setValue('status', 'submitted');
              setActivityStatus('submitted');
              setCategory2('non_commodity');
            }
          }}
        >
          Order Baru - Non Komoditi
        </Button>
      </Box>,
      <Box display={{ base: 'none', md: 'flex' }}>
        <Button
          px="4"
          variant={category2 === 'commodity' ? 'solid' : 'outline'}
          onClick={() => {
            if (category2 !== 'commodity') {
              methods.setValue('category_2', 'commodity');
              methods.setValue('status', 'submitted');
              setActivityStatus('submitted');
              setCategory2('commodity');
            }
          }}
        >
          Order Baru - Komoditi
        </Button>
      </Box>,
      <Box display={{ base: 'none', md: 'flex' }}>
        <Button
          px="4"
          variant={category2 === 'processed' ? 'solid' : 'outline'}
          onClick={() => {
            if (category2 !== 'processed') {
              methods.setValue('status', 'processed');
              methods.setValue('category_2', '');
              setCategory2('processed');
              setActivityStatus('processed');
            }
          }}
        >
          Order Yang Telah Diproses
        </Button>
      </Box>,
      <Box display={{ base: 'none', md: 'flex' }}>
        <Button
          px="4"
          variant={category2 === 'semua' ? 'solid' : 'outline'}
          onClick={() => {
            if (category2 !== 'semua') {
              methods.setValue('status', 'semua');
              methods.setValue('category_2', '');
              setCategory2('semua');
              setActivityStatus('semua');
            }
          }}
        >
          Semua Order
        </Button>
      </Box>,
    ];
  };

  return (
    <AdminLayout maxWidth="full">
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <LoadingOverlay
        isLoading={isLoadingSmartSearch}
        text="Memuat Data berdasarkan Smart Search"
      />
      <LoadingOverlay
        isLoading={isLoadingActivitiesSearch}
        text="Memuat Data berdasarkan pencarian"
      />
      <LoadingOverlay
        isLoading={isLoadingSubmit}
        text="Submitting Your Order, Please Don't Refresh This Page!"
      />
      <FormProvider {...methods}>
        <Box>
          <HStack
            direction="row"
            justifyContent="space-between"
            verticalAlign="center"
          >
            <HStack spacing={10}>
              {selectedData.length > 0 && (
                <Menu autoSelect={false}>
                  <MenuButton
                    as={Button}
                    aria-label="menu"
                    variant="solid"
                    px={0}
                  >
                    <Icon as={Edit} />
                  </MenuButton>
                  <Portal>
                    {selectedData.length === 1 && (
                      <MenuList>
                        <MenuItem
                          onClick={handleProcessOrder}
                          isDisabled={isNotSubmitted}
                        >
                          Proses Pesanan
                        </MenuItem>
                        <MenuItem onClick={handleStatusHistory}>
                          Riwayat Pesanan
                        </MenuItem>
                      </MenuList>
                    )}
                    {selectedData.length > 1 && (
                      <MenuList>
                        <MenuItem
                          onClick={handleProcessOrder}
                          isDisabled={isNotSubmitted}
                        >
                          Process Activities
                        </MenuItem>
                      </MenuList>
                    )}
                  </Portal>
                </Menu>
              )}
              <Text fontSize="lg" fontWeight="bold">
                {numberSeparator(total)} Hasil
              </Text>
            </HStack>
            <HStack py={4}>
              <Box display={{ base: 'none', md: 'flex' }} zIndex="100">
                <Menu>
                  <MenuButton
                    as={Button}
                    leftIcon={<DateRangeOutlined />}
                    rightIcon={<ExpandMoreOutlined />}
                    alignContent="center"
                  >
                    {methods.watch('order_date-from') &&
                    methods.watch('order_date-to')
                      ? `${methods.watch(
                          'order_date-from'
                        )} s/d ${methods.watch('order_date-to')}`
                      : 'Pilih Tanggal Order'}
                  </MenuButton>

                  <MenuList>
                    <MenuItem onClick={() => handleFilterTanggal('all')}>
                      Semua
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterTanggal('7d')}>
                      7 Hari Terakhir
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterTanggal('1m')}>
                      1 Bulan Terakhir
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterTanggal('3m')}>
                      3 Bulan Terakhir
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterTanggal('6m')}>
                      6 Bulan Terakhir
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterTanggal('1y')}>
                      1 Tahun Terakhir
                    </MenuItem>
                    <MenuItem onClick={date.onOpen}>Pilih Tanggal</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <chakra.form
                ml="2"
                w="full"
                onSubmit={searchMethods.handleSubmit(handleSubmitSearch)}
              >
                <TextField
                  name="search_query"
                  placeholder="Cari..."
                  register={searchMethods.register}
                  registerOptions={{
                    onBlur: (e) => {
                      e.preventDefault();
                      const search_query = searchMethods.watch('search_query');
                      handleSubmitSearch({ search_query });
                    },
                  }}
                />
              </chakra.form>
              <Dropdown
                w="25rem"
                name="total_sum"
                placeholder={`Total Nilai Order: ${adminCurrencyFormatter(
                  activitiesData?.total
                )}`}
                items={[
                  {
                    label: `Total Jumlah Barang: ${activitiesData?.total_items}`,
                    value: '2',
                  },
                ]}
                register={methods.register}
              />
            </HStack>
          </HStack>
        </Box>
        <Divider />
        {session.userRole === 'cs_katalog' ||
        session.userRole === 'cs_cabang' ? (
          <Stack
            p="5"
            my="5"
            bgColor="#F3F4F5"
            borderWidth="1px"
            borderColor="#D9D9D9"
            borderRadius="lg"
            direction="row"
            spacing="5"
            w="full"
            pb="6"
            overflow="auto"
          >
            {FilterButton().map((button) => button)}
          </Stack>
        ) : (
          ''
        )}
        <Divider />
        <HStack justifyContent="space-between" alignItems="baseline">
          <ActivitiesSmartSearch />
          <Button
            variant="solid"
            onClick={handleExportExcel}
            isLoading={isLoadingExport}
            isDisabled={isLoadingExport}
            minW={125}
          >
            <span>Export Excel</span>
          </Button>
        </HStack>
        <Box width="full">
          <StickyTable columns={tableColumns} data={tableData} slices={5} />
          <Center my={8}>
            <Pagination
              currentPage={params.page}
              totalPage={pageCount}
              nextPage={nextPage}
              firstPage={firstPage}
              lastPage={lastPage}
              previousPage={previousPage}
            />
          </Center>
        </Box>
        <Modal
          size="2xl"
          title="Pilih Tanggal"
          name="date_order"
          isOpen={date.isOpen}
          onClose={date.onClose}
          onSubmit={() => {
            date.onClose();
          }}
        >
          <Center>
            <Stack>
              <DateFilter />
            </Stack>
          </Center>
        </Modal>
        <OrderHistoryModal
          isOpen={isOpen}
          onClose={onClose}
          selectedDataId={selectedData[0]?.original.id || ''}
        />
      </FormProvider>
    </AdminLayout>
  );
}

function DateFilter() {
  const selectedDateRangeState = useState<DateRange | undefined>();
  const { setValue } = useFormContext();
  return (
    <>
      <Flex>
        <Spacer />
        <Button
          color="red"
          variant="link"
          border="none"
          fontWeight="normal"
          onClick={() => {
            setValue('order_date-from', '');
            setValue('order_date-to', '');
          }}
        >
          Hapus
        </Button>
      </Flex>
      <RangeDatePicker
        name="order_date"
        mode="range"
        selectedDateRangeState={selectedDateRangeState}
      />
    </>
  );
}

export default ActivitiesPage;
