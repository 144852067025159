import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import {
  BackofficeDetailPromoManagement,
  BackofficePromoUpdateRequest,
} from '@diamond/shared/types';
import { Dropzone, showToast, TextField } from '@diamond/shared/ui';
import { promoValidFromToFormat } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import {
  BACKOFFICE_DETAIL_PROMO_QUERY_KEY,
  BACKOFFICE_PROMO_QUERY_KEY,
  updatePromoDetail,
  uploadPromoBanner,
} from '@diamond/sol-admin-context';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModeEditOutline } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { PromoStatusBadge } from './PromoStatusBadge';

const PromoFormValidationSchema = Yup.object().shape({
  promo_name: Yup.string()
    .required('Nama harus diisi')
    .trim('Harus berupa karakter')
    .strict(true),
  banner: Yup.mixed().when('banner_url', (banner_url) => {
    if (!banner_url) return Yup.mixed().required('Banner harus diupload');
    return Yup.mixed();
  }),
});

export function PromoDetailForm({
  data,
}: {
  data: BackofficeDetailPromoManagement;
}) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isReady, setIsReady] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { access_token } = useAuthStore();

  const initialFormValues = {
    banner: null as null | File[],
    promo_name: data.promo_name,
    banner_url: data.banner_url,
    terms_and_conditions: data.terms_and_conditions,
    mechanism: data.mechanism,
    is_enabled: data.is_enabled,
  };

  const promoFormMethods = useForm({
    values: initialFormValues,
    resolver: yupResolver(PromoFormValidationSchema),
  });

  const promoDetailMutation = useMutation({
    mutationFn: (payload: BackofficePromoUpdateRequest) =>
      updatePromoDetail(access_token, data.id, payload),
    onSuccess: () => {
      showToast(toast, 'success', 'Berhasil update promo');
      setIsEditMode(false);
    },
    onError: (err) => {
      showToast(toast, 'error', err.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_DETAIL_PROMO_QUERY_KEY, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_PROMO_QUERY_KEY],
      });
    },
  });

  const bannerMutation = useMutation({
    mutationFn: (payload: unknown) => uploadPromoBanner(access_token, payload),
    onSuccess: (res) => {
      promoDetailMutation.mutate({
        banner_url: res.url,
        promo_name: promoFormMethods.watch('promo_name'),
        terms_and_conditions: promoFormMethods.watch('terms_and_conditions'),
        mechanism: promoFormMethods.watch('mechanism'),
        is_enabled: promoFormMethods.watch('is_enabled'),
      });
    },
    onSettled: () => {
      setIsReady(false);
    },
  });

  return (
    <FormProvider {...promoFormMethods}>
      <Flex my="4" gap={2}>
        <Box
          flexBasis="50%"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
        >
          <Flex justifyContent="space-between">
            <Heading color="black" fontSize="md">
              Informasi Promo
            </Heading>
            {isEditMode ? (
              <Flex justifyContent="flex-end" gap="2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setIsEditMode(false);
                    promoFormMethods.reset(initialFormValues);
                  }}
                >
                  Batalkan
                </Button>
                <Button
                  isLoading={promoDetailMutation.isPending}
                  size="sm"
                  onClick={promoFormMethods.handleSubmit((data) => {
                    if (!data.banner) {
                      const { banner, ...payload } = data;
                      promoDetailMutation.mutate(payload);
                      return;
                    }
                    const formData = new FormData();
                    formData.append(
                      'file',
                      data.banner[0],
                      data.banner[0].name
                    );
                    bannerMutation.mutate(formData);
                  })}
                >
                  Simpan
                </Button>
              </Flex>
            ) : (
              <Button
                leftIcon={<Icon as={ModeEditOutline} fontSize="16px" />}
                variant="outline"
                size="sm"
                onClick={() => setIsEditMode(true)}
              >
                Ubah
              </Button>
            )}
          </Flex>
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={4}
            mt={6}
            alignItems="center"
          >
            <GridItem>
              <Text textColor="gray.75" fontSize="sm">
                Nama Promo
              </Text>
            </GridItem>
            <GridItem justifySelf="stretch" colSpan={2}>
              {isEditMode ? (
                <Flex gap="1" alignItems="center" w="full">
                  <Text
                    flex="none"
                    textColor="#D9D9D9"
                    fontSize="sm"
                    color="#0000A3"
                    fontWeight="medium"
                  >
                    {': '}
                  </Text>
                  <TextField
                    flex="1"
                    name="promo_name"
                    size="sm"
                    register={promoFormMethods.register}
                    errors={promoFormMethods.formState.errors}
                  />
                </Flex>
              ) : (
                <Text
                  textColor="#D9D9D9"
                  fontSize="sm"
                  color="#0000A3"
                  fontWeight="medium"
                >
                  : {data.promo_name}
                </Text>
              )}
            </GridItem>

            <GridItem>
              <Text textColor="gray.75" fontSize="sm">
                Periode Promo
              </Text>
            </GridItem>
            <GridItem justifySelf="flex-start" colSpan={2}>
              <Text
                textColor="black"
                fontSize="sm"
                color="#0000A3"
                fontWeight="medium"
              >
                : {promoValidFromToFormat(data.valid_from, data.valid_until)}
              </Text>
            </GridItem>
            <GridItem>
              <Text textColor="gray.75" fontSize="sm">
                Status
              </Text>
            </GridItem>
            <GridItem
              justifySelf="flex-start"
              colSpan={2}
              color="#0000A3"
              fontWeight="medium"
            >
              <Flex>
                <Text fontWeight="medium" color="#005527">
                  :
                </Text>
                <PromoStatusBadge status={data.status} />
              </Flex>
            </GridItem>
            <GridItem>
              <Text textColor="gray.75" fontSize="sm">
                Tampil di SOL
              </Text>
            </GridItem>
            <GridItem justifySelf="flex-start" colSpan={2}>
              <Text
                textColor="gray.75"
                fontSize="sm"
                color="#0000A3"
                fontWeight="medium"
              >
                : {data.is_enabled ? 'Tampil' : 'Tidak'}
              </Text>
            </GridItem>
            <GridItem>
              <Text textColor="gray.75" fontSize="sm">
                Mekanisme
              </Text>
            </GridItem>
            <GridItem justifySelf="stretch" colSpan={2}>
              {isEditMode ? (
                <Flex gap="1" alignItems="center" w="full">
                  <Text
                    flex="none"
                    textColor="#D9D9D9"
                    fontSize="sm"
                    color="#0000A3"
                    fontWeight="medium"
                  >
                    {': '}
                  </Text>
                  <TextField
                    flex="1"
                    name="mechanism"
                    size="sm"
                    register={promoFormMethods.register}
                    errors={promoFormMethods.formState.errors}
                  />
                </Flex>
              ) : (
                <Text
                  textColor="#D9D9D9"
                  fontSize="sm"
                  color="#0000A3"
                  fontWeight="medium"
                >
                  : {data.mechanism}
                </Text>
              )}
            </GridItem>
          </Grid>
          <Stack mt={4}>
            <Flex justifyContent="space-between">
              <Text fontSize="sm" textColor="gray.75">
                Syarat dan Ketentuan
              </Text>
            </Flex>
            <Textarea
              h="150px"
              isDisabled={!isEditMode}
              border={isEditMode ? '1px solid' : 'none'}
              borderColor="gray.33"
              _disabled={{
                color: '#0000A3',
              }}
              placeholder="Masukkan syarat dan ketentuan disini"
              {...promoFormMethods.register('terms_and_conditions')}
            ></Textarea>
          </Stack>
        </Box>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          flex="1"
          p={4}
        >
          <Heading color="black" fontSize="md">
            Pengaturan Banner
          </Heading>

          <Stack py={4}>
            <Dropzone
              name="banner"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png'],
              }}
              urlDownload={data.banner_url || undefined}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              errors={promoFormMethods.formState.errors}
              maxSize={2}
              height={20}
              showPreviews
            />
            <Text fontSize="xs">
              Hanya menerima format PNG dan JPG dengan ukuran maksimal 2MB.
            </Text>
            <Text fontSize="xs">
              Resolusi gambar disarankan berukuran 1312x512 pixel.
            </Text>
          </Stack>
          <Stack>
            <Button
              isDisabled={!isReady}
              isLoading={
                promoDetailMutation.isPending || bannerMutation.isPending
              }
              onClick={promoFormMethods.handleSubmit((data) => {
                const formData = new FormData();
                if (!data.banner) return;
                formData.append('file', data.banner[0], data.banner[0].name);
                bannerMutation.mutate(formData);
              })}
            >
              Upload Banner
            </Button>
          </Stack>
        </Box>
      </Flex>
    </FormProvider>
  );
}
