import {
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

export interface CheckboxPrimaryProps extends CheckboxProps {
  name: string;
  label?: string;
  register: UseFormRegister<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: Partial<FieldErrorsImpl<{ [x: string]: any }>>;
}

export function Checkbox({
  name,
  label,
  value,
  children,
  errors,
  register,
  ...props
}: CheckboxPrimaryProps) {
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      <ChakraCheckbox
        value={value}
        aria-label={label}
        {...register(name)}
        {...props}
      >
        {label}
        {children}
      </ChakraCheckbox>
      <FormErrorMessage>{errorMessage as string}</FormErrorMessage>
    </FormControl>
  );
}

export default Checkbox;
