import { QueryWrapper } from '@diamond/shared/data-access';
import { ValuationTypeItem } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';

type queryString = {
  size: number;
  page: number;
};

export const useValuationType = (
  productId: string,
  queryString: queryString
) => {
  const session = useAuthStore();

  const queryCancelReason = QueryWrapper(
    `/backoffice/product/${productId}/valuation-type`,
    session.access_token
  );

  const { data: valuationTypeData } =
    queryCancelReason.useQueryGetAll<ValuationTypeItem>(
      `ValuationType${productId}`,
      queryString
    );

  return {
    valuationTypeData,
  };
};
