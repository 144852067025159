import { Box, BoxProps, Text } from '@chakra-ui/react';

import { LABEL_PROMO_TYPES } from './constants';
import { Promo } from './types';

interface BaseLabelPromoProps extends BoxProps {
  type: Promo;
}

interface LabelPromoPropsFreeGoods extends BaseLabelPromoProps {
  type: 'free-item';
  qtyFreeGoods?: number;
  minOrderQty?: number;
  isFreeGoods?: boolean;
}

interface LabelDiscount extends BaseLabelPromoProps {
  type: 'discount';
}
interface LabelCashback extends BaseLabelPromoProps {
  type: 'cashback';
}

interface LabelMultiFreeGoods extends BaseLabelPromoProps {
  type: 'multi-free-item';
  qtyFreeGoods?: number;
  minOrderQty?: number;
}

type LabelPromoProps =
  | LabelPromoPropsFreeGoods
  | LabelDiscount
  | LabelCashback
  | LabelMultiFreeGoods;

const LABEL_PROMO_DISPLAY_TYPES = {
  [LABEL_PROMO_TYPES.DISCOUNT]: 'Diskon',
  [LABEL_PROMO_TYPES.CASHBACK]: 'Cashback',
  [LABEL_PROMO_TYPES.MULTI_FREE_ITEM]: 'Produk Gratis',
};

const LABEL_PROMO_BACKGROUND_TYPES = {
  [LABEL_PROMO_TYPES.FREE_ITEM]: '#007AFF',
  [LABEL_PROMO_TYPES.MULTI_FREE_ITEM]: '#007AFF',
  [LABEL_PROMO_TYPES.DISCOUNT]: '#FF3B30',
  [LABEL_PROMO_TYPES.CASHBACK]: '#1831B5',
};

const PromoWrapper = ({
  children,
  type,
  ...rest
}: BoxProps & { type: Promo }) => (
  <Box
    backgroundColor={LABEL_PROMO_BACKGROUND_TYPES[type]}
    px="2"
    py="0.5"
    borderTopLeftRadius="xl"
    borderBottomRightRadius="xl"
    color="white"
    textAlign="center"
    fontWeight="bold"
    fontSize="9px"
    letterSpacing="0.05em"
    flex="none"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    {...rest}
  >
    <Text>{children}</Text>
  </Box>
);

export function LabelPromo(props: LabelPromoProps) {
  const { type, ...rest } = props;

  if (type === 'free-item') {
    const {
      type,
      minOrderQty = 0,
      qtyFreeGoods = 0,
      isFreeGoods,
      ...rest
    } = props;
    return (
      <PromoWrapper type={type} {...rest}>
        {isFreeGoods
          ? 'FREE GOODS'
          : `Beli ${minOrderQty || 1} dapat ${qtyFreeGoods || 1}`}
      </PromoWrapper>
    );
  }

  return (
    <PromoWrapper type={type} {...rest}>
      {LABEL_PROMO_DISPLAY_TYPES[type]}
    </PromoWrapper>
  );
}

export default LabelPromo;
