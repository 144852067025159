/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Breadcrumbs, Searchbox, Text } from '@diamond/shared/ui';
import { AdminLayout, PromoItemList } from '@diamond/sol-admin/common';
import cleanDeep from 'clean-deep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

export function PromoManagementPage() {
  const [tabsIndex, setTabsIndex] = useState<number>(0);

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Promo Management',
      link: '/promo',
    },
  ];

  const handleChangeTabs = (index: number) => {
    setTabsIndex(index);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = useCallback(
    (nextParams: Record<string, any>) => {
      const prevParams = {
        query: '',
        ...Object.fromEntries(searchParams.entries()),
      };

      setSearchParams(cleanDeep({ ...prevParams, ...nextParams }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  const params = useMemo(() => {
    const rawParams = {
      query: '',
      ...Object.fromEntries(searchParams.entries()),
    };
    return {
      query: rawParams.query,
    };
  }, [searchParams]);
  const { register, watch } = useForm({
    defaultValues: {
      search_query: params.query,
    } as FieldValues,
  });
  const timeout = useRef<any>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'search_query') {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          setParams({ query: value[name] });
        }, 500);
      }
    });
    return () => {
      clearTimeout(timeout.current);
      subscription.unsubscribe();
    };
  }, [setParams, watch]);

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack spacing={8} direction="row" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Promo Management
          </Text>
        </Stack>
        <Searchbox register={register} placeholder="Cari..." />
      </Box>
      <Tabs
        onChange={handleChangeTabs}
        tabIndex={tabsIndex}
        isLazy
        variant="unstyled"
      >
        <TabList>
          <Text fontWeight="bold" mt={4}>
            Status
          </Text>
          <Tab
            _selected={{
              color: 'blue.500',
              fontWeight: 'bold',
              borderBottom: '2px solid transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Button
              variant="outline"
              borderColor={tabsIndex === 0 ? 'blue' : 'gray.75'}
              color={tabsIndex === 0 ? 'blue' : 'gray.75'}
            >
              Aktif
            </Button>
          </Tab>
          <Tab
            _selected={{
              color: 'blue.500',
              fontWeight: 'bold',
              borderBottom: '2px solid transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Button
              variant="outline"
              borderColor={tabsIndex === 1 ? 'blue' : 'gray.75'}
              color={tabsIndex === 1 ? 'blue' : 'gray.75'}
            >
              Tidak Aktif
            </Button>
          </Tab>
          <Tab>
            <Button
              variant="outline"
              borderColor={tabsIndex === 2 ? 'blue' : 'gray.75'}
              color={tabsIndex === 2 ? 'blue' : 'gray.75'}
            >
              Belum Aktif
            </Button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PromoItemList status="active" searchQuery={params.query} />
          </TabPanel>
          <TabPanel>
            <PromoItemList status="inactive" searchQuery={params.query} />
          </TabPanel>
          <TabPanel>
            <PromoItemList status="not_yet_active" searchQuery={params.query} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminLayout>
  );
}

export default PromoManagementPage;
