import { useSearch } from '@diamond/shared/context';
import { adminCaptureException, apiInstance } from '@diamond/shared/utils';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, CustomParamsSerializer } from 'axios';
import cleanDeep from 'clean-deep';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface QueryStringSearch {
  [key: string]: string | number | boolean | string[] | null;
}

type Pagination = { pagination: { rows: number; pages: number } };

type QueryGetAllOptions = {
  staleTime?: number;
  prefetchNextPage?: boolean;
  paramsSerializer?: CustomParamsSerializer;
  enabled?: boolean;
};
const defaultOptionsQueryGetAll: QueryGetAllOptions = {
  prefetchNextPage: true,
};

export function QueryWrapper(url: string, token?: string) {
  //page default value
  const page = 1;

  function useQueryGet<K>(
    queryKey: string,
    params?: Readonly<QueryStringSearch>
  ) {
    const fetchData = async () => {
      try {
        const res = await apiInstance(token).get(url, {
          params: cleanDeep(params),
        });
        return res.data;
      } catch (error) {
        throw new Error('Terjadi Kesalahan QueryWrapper');
      }
    };

    const { data, isPending, refetch } = useQuery<K>({
      queryKey: [queryKey],
      queryFn: fetchData,
    });

    return {
      data: data ?? [],
      isLoading: isPending,
      refetch,
    };
  }

  function useQueryGetAll<K extends Pagination>(
    queryKey: string,
    payload?: Readonly<QueryStringSearch>,
    cacheTime?: number,
    navigateError?: string,
    options?: QueryGetAllOptions
  ) {
    const { keyword } = useSearch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const optionsDefault = {
      ...defaultOptionsQueryGetAll,
      ...options,
    };

    const params = useMemo(
      () =>
        cleanDeep({
          page,
          size: 25,
          search_query: keyword,
          ...payload,
        }),
      [keyword, payload]
    );

    const fetchData = async (prefetchNextPage = false) => {
      const paramPage = params.page || 1;
      const newParams = {
        ...params,
        page: prefetchNextPage ? paramPage + 1 : paramPage,
      };

      try {
        const res = await apiInstance(token).get<K>(url, {
          params: newParams,
          paramsSerializer: optionsDefault.paramsSerializer,
        });
        return res.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          if (navigateError) {
            navigate(navigateError);
            adminCaptureException(error.response?.data.message, (scope) => {
              scope.setTags({
                queryKey: 'useQueryGetAll error',
                status_code:
                  error instanceof AxiosError
                    ? error.response?.data.statusCode
                    : undefined,
              });
              scope.setContext('error', {
                error:
                  error instanceof AxiosError
                    ? error.response?.data
                    : undefined,
              });
              scope.setExtra(queryKey, newParams);
              scope.setLevel('error');
            });
            throw new Error('Terjadi Kesalahan useQueryGetAll');
          } else {
            adminCaptureException(error.response?.data.message, (scope) => {
              scope.setTags({
                queryKey: 'useQueryGetAll error',
                status_code:
                  error instanceof AxiosError
                    ? error.response?.data.statusCode
                    : undefined,
              });
              scope.setContext('error', {
                error:
                  error instanceof AxiosError
                    ? error.response?.data
                    : undefined,
              });
              scope.setExtra(queryKey, newParams);
              scope.setLevel('error');
            });
            throw new Error('Terjadi Kesalahan useQueryGetAll');
          }
        }

        throw new Error('Terjadi Kesalahan useQueryGetAll');
      }
    };

    const {
      data,
      isPending,
      isPlaceholderData: isPreviousData,
      refetch,
      isFetching,
      isFetched,
    } = useQuery<K>({
      queryKey: [queryKey, page, params],
      queryFn: () => fetchData(),
      placeholderData: keepPreviousData,
      gcTime: cacheTime,
      staleTime: optionsDefault.staleTime,
      enabled: optionsDefault.enabled,
    });

    useEffect(() => {
      if (optionsDefault.prefetchNextPage && optionsDefault.enabled) {
        queryClient.prefetchQuery({
          queryKey: [
            queryKey,
            page,
            { ...params, page: (params.page || 1) + 1 },
          ],
          queryFn: () => fetchData(true),
          staleTime: 30000,
          retry: false,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, params, options, queryKey]);

    return {
      data: data,
      pageCount: data?.pagination.pages ?? 0,
      total: data?.pagination.rows ?? 0,
      isLoading: isPending,
      isPreviousData,
      isFetching,
      isFetched,
      refetch,
    };
  }

  function useQueryGetById<K>(
    id: string | null,
    queryKey: string,
    payload?: Readonly<QueryStringSearch>,
    retry?: boolean
  ) {
    const params = { ...payload };
    const fetchData = async () => {
      try {
        const res = await apiInstance(token).get(`${url}/${id}`, {
          params: cleanDeep(params),
        });
        return res.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'useQueryGetAll error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(queryKey, params);
            scope.setLevel('error');
          });
          throw new Error('Terjadi Kesalahan useQueryData');
        }
        throw new Error('Terjadi Kesalahan useQueryGetById');
      }
    };
    const {
      data,
      isPending,
      isPlaceholderData: isPreviousData,
      refetch,
    } = useQuery<K>({
      queryKey: [queryKey, id],
      queryFn: fetchData,
      enabled: id !== null && id !== undefined && id !== '',
      retry: retry,
    });

    return {
      data: data,
      isLoading: isPending,
      isPreviousData,
      refetch,
    };
  }

  function useQueryData<K>(
    queryKey: string,
    payload?: Readonly<QueryStringSearch>,
    disablePrefetch?: boolean
  ) {
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const { keyword } = useSearch();
    const queryClient = useQueryClient();
    const params = {
      page,
      size: 25,
      search_query: keyword,
      ...payload,
    };

    const fetchData = async () => {
      try {
        const res = await apiInstance(token).get(url, {
          params: cleanDeep(params),
        });
        setPageCount(Number(res.data.pagination.pages));
        setTotal(Number(res.data.pagination.rows));
        return res.data.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'useQueryGetAll error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(queryKey, params);
            scope.setLevel('error');
          });
          throw new Error('Terjadi Kesalahan useQueryData');
        }
        throw new Error('Terjadi Kesalahan useQueryData');
      }
    };

    const {
      data,
      isPending,
      isPlaceholderData: isPreviousData,
      refetch,
      isStale,
      isFetching,
    } = useQuery<K[]>({
      queryKey: [queryKey, page, keyword, payload],
      queryFn: fetchData,
      placeholderData: keepPreviousData,
    });

    useEffect(() => {
      if (disablePrefetch !== true) {
        queryClient.prefetchQuery({
          queryKey: [queryKey, page + 1, keyword],
          queryFn: fetchData,
          retry: false,
          staleTime: 30000,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disablePrefetch, keyword, queryClient, queryKey]);

    return {
      data: data ?? [],
      pageCount,
      total,
      isLoading: isPending,
      isPreviousData,
      isStale,
      isFetching,
      refetch,
    };
  }

  function useQueryGetByIdPager<K extends Pagination>(
    id: string | null,
    queryKey: string,
    payload?: Readonly<QueryStringSearch>,
    isRetry?: boolean
  ) {
    const { keyword } = useSearch();

    const params = useMemo(
      () =>
        cleanDeep({
          page,
          size: 25,
          search_query: keyword,
          ...payload,
        }),
      [keyword, payload]
    );

    const fetchData = async () => {
      try {
        const res = await apiInstance(token).get<K>(`${url}/${id}`, { params });
        return res.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'useQueryGetAll error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(queryKey, params);
            scope.setLevel('error');
          });
          throw new Error('Terjadi Kesalahan useQueryData');
        }
        throw new Error('Terjadi Kesalahan useQueryGetByIdPager');
      }
    };

    const {
      data,
      isPending,
      isPlaceholderData: isPreviousData,
      refetch,
    } = useQuery<K>({
      queryKey: [queryKey, id, params],
      queryFn: fetchData,
      retry: isRetry,
    });

    return {
      data: data,
      isLoading: isPending,
      isPreviousData,
      pageCount: data?.pagination?.pages || 0,
      total: data?.pagination?.rows || 0,
      refetch,
    };
  }

  return {
    useQueryGet,
    useQueryGetById,
    useQueryData,
    useQueryGetByIdPager,
    useQueryGetAll,
  };
}
