import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useQuery } from '@tanstack/react-query';

import { getOrderChangelogs } from '../queries';

export const ORDER_CHANGELOG_QUERY_KEY = 'OrderChangelogs';

export function useOrderChangelogs(activityId: string | undefined) {
  const { access_token } = useAuthStore();
  return useQuery({
    queryKey: [ORDER_CHANGELOG_QUERY_KEY, activityId],
    queryFn: () => getOrderChangelogs(access_token, activityId || ''),
    enabled: !!activityId,
  });
}
