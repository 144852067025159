import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  BackofficeDetailPromoItemWithDiscount,
  BackofficeDetailPromoItemWithFreeGoods,
  BackofficeDetailPromoManagement,
} from '@diamond/shared/types';
import { adminCurrencyFormatter } from '@diamond/shared/utils';

type PromoItems = BackofficeDetailPromoManagement['items'];

type PromoDetailTableProps = {
  data: PromoItems;
};

const STOCK_MAGNIFIER = 1000;

/**
 * Promo Table
 * Ref: https://docs.google.com/spreadsheets/d/1yG1jj-0wb2h5C-0JLNJra88glALZF0PK4eFwJbkxVkw/edit?gid=0#gid=0
 */
export function PromoDetailTable({ data }: PromoDetailTableProps) {
  if (isFreeGoods(data)) {
    return <FreeGoodsTable data={data} />;
  }

  if (isDiscount(data)) {
    return <DiscountTable data={data} />;
  }

  return null;
}

type FreeGoodsTableProps = {
  data: Array<BackofficeDetailPromoItemWithFreeGoods>;
};

export function FreeGoodsTable({ data }: FreeGoodsTableProps) {
  const qtyTitle =
    data[0].product_free_good.minimum_order_quantity > 0 ||
    data[0].product_free_good.sum_minimum_order_quantity > 0
      ? 'Kuantitas'
      : 'SO Amount';

  return (
    <Table variant="promo">
      <Thead>
        <Tr>
          <Th colSpan={2} w="50%">
            Syarat
          </Th>
          <Th colSpan={2} w="50%">
            Promo
          </Th>
        </Tr>
        <Tr>
          <Th w="35%">Produk</Th>
          <Th w="15%">{qtyTitle}</Th>
          <Th w="35%">Produk</Th>
          <Th w="15%">Kuantitas</Th>
        </Tr>
      </Thead>
      <Tbody>{generateFreeGoodsRows(data)}</Tbody>
    </Table>
  );
}

function generateFreeGoodsRows(
  data: Array<BackofficeDetailPromoItemWithFreeGoods>
): Array<React.ReactElement> {
  const isBundling =
    data[0].product_free_good.sum_minimum_order_quantity > 0 ||
    data[0].product_free_good.sum_minimum_order_amount > 0;

  const formatMinimumQty = createMinimumOrderQtyFormatter(isBundling);
  const formatDeductionAmount = createFreeGoodsQtyFormatter();

  const minimumQtyRowSpans = calculateRowspan(data, isBundling, (item) =>
    formatMinimumQty(item.product_free_good, item.product.sales_unit_desc)
  );
  const freeGoodRowSpans = calculateRowspan(
    data,
    isBundling,
    formatDeductionAmount
  );

  return data.map((item, i) => {
    const currentQty = formatMinimumQty(
      item.product_free_good,
      item.product.sales_unit_desc
    );
    const currentQtyFreeItem = formatDeductionAmount(item);
    return (
      <Tr key={i}>
        <Td>
          {item.product.title} ({item.product.item_code})
        </Td>
        {minimumQtyRowSpans[i] > 0 ? (
          <Td rowSpan={minimumQtyRowSpans[i]}>{currentQty}</Td>
        ) : null}
        <Td>
          {item.product_free_good.title} (
          {item.product_free_good.free_goods_product_item_code})
        </Td>
        {freeGoodRowSpans[i] > 0 ? (
          <Td rowSpan={freeGoodRowSpans[i]}>{currentQtyFreeItem}</Td>
        ) : null}
      </Tr>
    );
  });
}

type DiscountTableProps = {
  data: Array<BackofficeDetailPromoItemWithDiscount>;
};

// TODO: handle brand

export function DiscountTable({ data }: DiscountTableProps) {
  const isBundling =
    data[0].product_discount.sum_minimum_order_quantity > 0 ||
    data[0].product_discount.sum_minimum_order_amount > 0;

  const qtyTitle =
    data[0].product_discount.minimum_order_quantity > 0 ||
    data[0].product_discount.sum_minimum_order_quantity > 0
      ? 'Kuantitas'
      : 'SO Amount';

  const promoTitle = data[0].product_discount.is_discount
    ? 'Diskon'
    : 'Cashback';

  return (
    <Table variant="promo">
      <Thead>
        <Tr>
          <Th colSpan={2} w={isBundling ? '50%' : '75%'}>
            Syarat
          </Th>
          <Th colSpan={2} w="50%">
            Promo
          </Th>
        </Tr>
        <Tr>
          <Th w={isBundling ? '35%' : '50%'}>Produk</Th>
          <Th w={isBundling ? '15%' : '30'}>{qtyTitle}</Th>
          {isBundling ? <Th w="25%">Prorate</Th> : null}
          <Th w={isBundling ? '25%' : '20%'}>{promoTitle}</Th>
        </Tr>
      </Thead>
      <Tbody>{generateDiscountRows(data, isBundling)}</Tbody>
    </Table>
  );
}

function generateDiscountRows(
  data: Array<BackofficeDetailPromoItemWithDiscount>,
  isBundling: boolean
): Array<React.ReactElement> {
  const formatMinimumQty = createMinimumOrderQtyFormatter(isBundling);
  const formatDeductionAmount = createDeductionAmountFormatter();

  const minimumQtyRowSpans = calculateRowspan(data, isBundling, (item) =>
    formatMinimumQty(item.product_discount, item.product.sales_unit_desc)
  );
  const freeGoodRowSpans = calculateRowspan(
    data,
    isBundling,
    formatDeductionAmount
  );

  return data.map((item, i) => {
    const currentQty = formatMinimumQty(
      item.product_discount,
      item.product.sales_unit_desc
    );
    const deductionAmount = formatDeductionAmount(item);

    return (
      <Tr key={i}>
        <Td>
          {item.product.title} ({item.product.item_code})
        </Td>
        {minimumQtyRowSpans[i] > 0 ? (
          <Td rowSpan={minimumQtyRowSpans[i]}>{currentQty}</Td>
        ) : null}
        {isBundling ? (
          <Td>{item.product_discount.prorate ? 'Ya' : 'Tidak'}</Td>
        ) : null}
        {freeGoodRowSpans[i] > 0 ? (
          <Td rowSpan={freeGoodRowSpans[i]} textAlign="right">
            {deductionAmount}
          </Td>
        ) : null}
      </Tr>
    );
  });
}

/**
 * Utility functions for the TypeScript compiler to know which promo
 */
const isFreeGoods = (
  data: PromoItems
): data is Array<BackofficeDetailPromoItemWithFreeGoods> =>
  'product_free_good' in data[0];
const isDiscount = (
  data: PromoItems
): data is Array<BackofficeDetailPromoItemWithDiscount> =>
  'product_discount' in data[0];

/**
 * Utility functions to calculate the rowspan on each items
 */

function calculateRowspan<TItem, TPredResult>(
  data: Array<TItem>,
  isBundling: boolean,
  pred: (item: TItem) => TPredResult
): Array<number> {
  const spans = Array(data.length).fill(1);

  // If it's single, there are no spans
  if (!isBundling) return spans;

  let current = 0;

  for (let i = 1; i <= data.length; i++) {
    const currentItem = data[i - 1];
    const nextItem = data[i];

    const currentQty = pred(currentItem);
    const nextQty = nextItem ? pred(nextItem) : null;

    if (currentQty !== nextQty) {
      spans[current] = i - current;
      current = i;
    } else {
      spans[i] = 0;
    }
  }

  return spans;
}

/**
 * Utility functions to construct minimum qty / amount text
 */
const createMinimumOrderQtyFormatter =
  (isBundled = false) =>
  <
    T extends Pick<
      BackofficeDetailPromoItemWithFreeGoods['product_free_good'],
      | 'minimum_order_amount'
      | 'minimum_order_quantity'
      | 'sum_minimum_order_amount'
      | 'sum_minimum_order_quantity'
    >
  >(
    item: T,
    salesUnitDesc: string
  ) => {
    // If isBundled, use the `sum_minimum_order_*`
    if (isBundled) {
      return item.sum_minimum_order_quantity > 0
        ? `${
            item.sum_minimum_order_quantity / STOCK_MAGNIFIER
          } ${salesUnitDesc}`
        : adminCurrencyFormatter(item.sum_minimum_order_amount);
    }

    return item.minimum_order_quantity > 0
      ? `${item.minimum_order_quantity / STOCK_MAGNIFIER} ${salesUnitDesc}`
      : adminCurrencyFormatter(item.minimum_order_amount);
  };

const createFreeGoodsQtyFormatter =
  () => (item: BackofficeDetailPromoItemWithFreeGoods) => {
    return `${
      (item.product_free_good.quantity_free_goods || 0) / STOCK_MAGNIFIER
    } ${item.product_free_good.sales_unit_desc}`;
  };

const createDeductionAmountFormatter =
  () => (item: BackofficeDetailPromoItemWithDiscount) => {
    if (item.product_discount.is_discount) {
      return `${item.product_discount.deduction_amount / 100}%`;
    }
    return `${adminCurrencyFormatter(item.product_discount.deduction_amount)}`;
  };
