import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  borderRadius: 0,
  textTransform: 'capitalize'
});
const gray = defineStyle({
  background: 'gray.33',
  color: 'black',
});

const blue = defineStyle({
  background: '#E6E6F6',
  color: 'blue',
});
const red = defineStyle({
  background: '#FFE6E6',
  color: 'red',
});
const aqua = defineStyle({
  background: '#E6F6FF',
  color: 'aqua',
});
const orange = defineStyle({
  background: '#FDF4E6',
  color: 'orange',
});
const purple = defineStyle({
  background: '#EEE6F6',
  color: 'purple',
});
const blueDark = defineStyle({
  background: '#E6F0F6',
  color: 'blueDark',
});
const green = defineStyle({
  background: '#E6F6ED',
  color: 'green',
});
const darkGreen = defineStyle({
  background: '#E6EEEA',
  color: 'darkGreen',
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
  variants: {
    gray,
    blue,
    red,
    aqua,
    orange,
    purple,
    blueDark,
    green,
    darkGreen,
  },
  defaultProps: {
    variant: 'gray',
  },
});
