import { chakra, FormControl, FormLabel } from '@chakra-ui/react';
import { MultiSelect } from '@diamond/shared/ui';
import {
  useCustomerTypes,
  useDistributionChannel,
} from '@diamond/sol-admin-context';
import { useFormContext } from 'react-hook-form';
import { Props } from 'react-select';

const reactSelectStyles: Props['styles'] = {
  container(base) {
    return {
      ...base,
      minWidth: 220,
    };
  },
  menu(base) {
    return {
      ...base,
      zIndex: 98, // below overlay
    };
  },
  placeholder(base) {
    return {
      ...base,
      color: 'black',
    };
  },
  indicatorSeparator() {
    return {
      display: 'none',
    };
  },
  valueContainer(base) {
    return {
      ...base,
      flexWrap: 'nowrap',
    };
  },
  control(base) {
    return {
      ...base,
      minHeight: 40,
      borderRadius: 'var(--chakra-radii-md)',
      borderColor: 'var(--chakra-colors-gray-75)',
    };
  },
};

type CustomerTypeInputProps = {
  selectedBusinessSegmentation?: Array<string>;
  selectedDistributionChannel?: Array<string>;
};

export function CustomerTypeInput({
  selectedBusinessSegmentation,
  selectedDistributionChannel,
}: CustomerTypeInputProps) {
  const { setValue, watch } = useFormContext();

  const { options: customerTypeOptions } = useCustomerTypes();
  const { options: distributionChannelOptions } = useDistributionChannel();

  if (
    watch('business_segmentation') === undefined &&
    selectedBusinessSegmentation &&
    customerTypeOptions.length > 0
  ) {
    setValue(
      'business_segmentation',
      customerTypeOptions.filter((item) =>
        selectedBusinessSegmentation?.includes(item.value)
      ) || []
    );
  }

  if (
    watch('distribution_channel') === undefined &&
    selectedDistributionChannel &&
    distributionChannelOptions.length > 0
  ) {
    setValue(
      'distribution_channel',
      distributionChannelOptions.filter((item) =>
        selectedDistributionChannel?.includes(item.value)
      ) || []
    );
  }

  return (
    <>
      <FormControl>
        <FormLabel
          aria-label="Tipe Customer (Opsional)"
          htmlFor="business_segmentation"
        >
          Tipe Customer <chakra.span color="gray.75">(Opsional)</chakra.span>
        </FormLabel>
        <MultiSelect
          id="business_segmentation"
          placeholder="Semua Tipe Customer"
          styles={reactSelectStyles}
          options={customerTypeOptions}
          value={watch('business_segmentation')}
          onChange={(vals) => {
            const selected = vals;
            setValue('business_segmentation', selected);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          aria-label="Cabang (Opsional)"
          htmlFor="distribution_channel"
        >
          Cabang <chakra.span color="gray.75">(Opsional)</chakra.span>
        </FormLabel>
        <MultiSelect
          id="distribution_channel"
          placeholder="Semua Cabang"
          styles={reactSelectStyles}
          options={distributionChannelOptions}
          value={watch('distribution_channel')}
          onChange={(vals) => {
            const selected = vals;
            setValue('distribution_channel', selected);
          }}
        />
      </FormControl>
    </>
  );
}
