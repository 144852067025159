import { Cart, CartTotalResponse } from '@diamond/shared/types';
import { D } from '@mobily/ts-belt';
import create from 'zustand';

type CartActions = {
  getCartItem: (cartId: string) => Cart;
  getCartEntries: () => Array<Cart>;
  getProduct: (productId: string) => Cart['product'];
  add: (product: Cart) => void;
  remove: (cartId: string) => void;
  reset: (cartItems: Array<Cart>) => void;
  setCompiledPromo: (cartTotalPromos: CartTotalResponse['promos']) => void;
};

type CartRecord = Record<string, Cart>;
type ProductsRecord = Record<string, Cart['product']>;

type CartPromoCompiledItem =
  CartTotalResponse['promos'][number]['compiled_product_discounts'][number];
type PromoRecord = Record<string, CartPromoCompiledItem>;

type CartState = {
  cartItems: Array<string>;
  cartRecord: CartRecord;
  promoRecord: PromoRecord;
  productsRecord: ProductsRecord;
};

export const useCartStore = create<CartState & CartActions>((set, get) => ({
  cartItems: [],
  cartRecord: {},
  promoRecord: {},
  productsRecord: {},
  setCompiledPromo: (cartTotalPromos: CartTotalResponse['promos']) => {
    const promoEntryTemp: Array<Readonly<[string, CartPromoCompiledItem]>> = [];

    cartTotalPromos.forEach((promoDivision) => {
      const promoCompiled = promoDivision.compiled_product_discounts
        .filter((promo) => promo.is_discount)
        .map((promo) => [promo.product_id, promo] as const);
      promoEntryTemp.push(...promoCompiled);
    });

    set(() => ({
      promoRecord: Object.fromEntries(promoEntryTemp),
    }));
  },
  getCartItem: (cartId) => get().cartRecord[cartId],
  getCartEntries: () =>
    get().cartItems.map((cartId) => get().getCartItem(cartId)),
  getProduct: (productId) => get().productsRecord[productId],
  add: (cartItem) =>
    set((state) => ({
      cartRecord: D.set(state.cartRecord, cartItem.id, cartItem),
      cartItems: [...state.cartItems, cartItem.id],
      productsRecord: D.set(
        state.productsRecord,
        cartItem.product.id,
        cartItem.product
      ),
    })),
  remove: (cartItemId) => {
    if (cartItemId in get().cartRecord) {
      set((state) => {
        const productId = state.cartRecord[cartItemId].product.id;
        return {
          cartItems: state.cartItems.filter((id) => id !== cartItemId),
          cartRecord: D.deleteKey(state.cartRecord, cartItemId),
          productsRecord: D.deleteKey(state.productsRecord, productId),
        };
      });
    }
  },
  reset: (cartItems) =>
    set({
      cartRecord: Object.fromEntries(cartItems.map((cart) => [cart.id, cart])),
      cartItems: cartItems.map((c) => c.id),
      productsRecord: Object.fromEntries(
        cartItems.map((cart) => [cart.product.id, cart.product])
      ),
    }),
}));
