import { Box, Flex, Text } from '@chakra-ui/react';
import { BackofficeDetailPromoManagement } from '@diamond/shared/types';

type PromoStatusBadgeProps = {
  status: BackofficeDetailPromoManagement['status'];
};

const PROMO_STATUS_TYPES = {
  active: 'active',
  inactive: 'inactive',
  not_yet_active: 'not_yet_active',
} as const;

const PROMO_STATUS_DISPLAY = {
  [PROMO_STATUS_TYPES.active]: 'Aktif',
  [PROMO_STATUS_TYPES.inactive]: 'Tidak Aktif',
  [PROMO_STATUS_TYPES.not_yet_active]: 'Belum Aktif',
} as const;

const PROMO_STATUS_BACKGROUND_COLOR = {
  [PROMO_STATUS_TYPES.active]: '#00A34B',
  [PROMO_STATUS_TYPES.inactive]: '#999999',
  [PROMO_STATUS_TYPES.not_yet_active]: '#006BD1',
} as const;

export function PromoStatusBadge({ status }: PromoStatusBadgeProps) {
  return (
    <Flex w="100%" alignItems="center" gap="1" rounded="md" ml="2px">
      <Box
        mt="3px"
        w="10px"
        h="10px"
        rounded="full"
        bgColor={PROMO_STATUS_BACKGROUND_COLOR[status]}
      ></Box>

      <Text fontSize="sm" mt={0.5}>
        {PROMO_STATUS_DISPLAY[status]}
      </Text>
    </Flex>
  );
}
