import { HStack, IconButton } from '@chakra-ui/react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export interface ActionButtonProps {
  onClickDelete?: () => void;
  onClickEdit?: () => void;
}

export function ActionButton({
  onClickDelete,
  onClickEdit,
}: ActionButtonProps) {
  const handleDelete = () => {
    if (onClickDelete) {
      onClickDelete();
    }
  };

  const handleEdit = () => {
    if (onClickEdit) {
      onClickEdit();
    }
  };
  return (
    <HStack display="flex" justifyContent="flex-end">
      {onClickEdit && (
        <IconButton
          size="sm"
          aria-label="edit-button"
          color="white"
          bgColor="blue"
          border="none"
          _hover={{ bgColor: 'azure' }}
          _active={{ bgColor: 'blue' }}
          icon={<EditOutlinedIcon />}
          onClick={() => handleEdit()}
        />
      )}
      {onClickDelete && (
        <IconButton
          size="sm"
          aria-label="delete-button"
          color="white"
          bgColor="red"
          border="none"
          _hover={{ bgColor: 'pink' }}
          _active={{ bgColor: 'red' }}
          icon={<DeleteOutlinedIcon />}
          onClick={() => handleDelete()}
        />
      )}
    </HStack>
  );
}

export default ActionButton;
