import { Card, CardBody, HStack, SimpleGrid, Stack } from '@chakra-ui/react';
import { TextField } from '@diamond/shared/ui';
import { useFormContext } from 'react-hook-form';

export function CardAccountInfo() {
  const { register } = useFormContext();

  return (
    <Stack>
      <Card>
        <CardBody>
          <SimpleGrid columns={2} spacing={6}>
            <TextField
              label="ShiptoID"
              name="ship_tos[0].code"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Nama"
              name="name"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Akun Aktif"
              name="status"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Block Billto"
              name="bill_to.is_blocked"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
          </SimpleGrid>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stack>
            <TextField
              label="Jalan"
              name="ship_tos[0].address"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <SimpleGrid columns={2} spacing={6} py={2}>
              <TextField
                label="Kota"
                name="ship_tos[0].city"
                bgColor="gray.customGray"
                register={register}
                isReadOnly
              />
              <TextField
                label="Provinsi"
                name="ship_tos[0].province"
                bgColor="gray.customGray"
                register={register}
                isReadOnly
              />
              <TextField
                label="Negara"
                name="ship_tos[0].country"
                bgColor="gray.customGray"
                register={register}
                isReadOnly
              />
              <TextField
                label="Nama Billto"
                name="bill_to.name"
                bgColor="gray.customGray"
                register={register}
                isReadOnly
              />
            </SimpleGrid>
            <TextField
              label="Alamat Billto"
              name="bill_to.addresses"
              bgColor="gray.customGray"
              mb={4}
              register={register}
              isReadOnly
            />
            <TextField
              label="Kota Billto"
              name="bill_to.city"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <HStack spacing={6}>
            <TextField
              label="Metode Pembayaran"
              name="payment_method"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Limit Kredit"
              name="credit_limit"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
          </HStack>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <SimpleGrid columns={2} spacing={6}>
            <TextField
              label="Distribution Channel"
              name="ship_tos[0].distribution_channel"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Condition Group 1 - Segmentasi Usaha"
              name="ship_tos[0].cg_1_business_segmentation"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Condition Group 2 - Kategori Usaha"
              name="ship_tos[0].cg_2_business_category"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Condition Group 3 - Jenis Usaha"
              name="ship_tos[0].cg_3_business_type"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Condition Group 4 - Shipto Diamond"
              name="ship_tos[0].cg_4_manufacture"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
            <TextField
              label="Condition Group 5 - Shipto Trading"
              name="ship_tos[0].cg_5_trading"
              bgColor="gray.customGray"
              register={register}
              isReadOnly
            />
          </SimpleGrid>
        </CardBody>
      </Card>
    </Stack>
  );
}

export default CardAccountInfo;
