import { QueryWrapper } from '@diamond/shared/data-access';
import { StorageTypeItem } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';

export const useStorageType = () => {
  const session = useAuthStore();

  const storageTypeQuery = QueryWrapper(
    '/backoffice/product/storage-type',
    session.access_token
  );
  const { data } =
    storageTypeQuery.useQueryGet<StorageTypeItem>('StorageType');

  return {
    data,
  };
};
