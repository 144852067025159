import {
  Box,
  Container,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Divider } from '@diamond/shared/ui';

import pacakgeInfo from '../../../../../../package.json';

export function Footer() {
  return (
    <Container as="footer" role="contentinfo" maxW="container.xl">
      <Stack
        spacing="8"
        direction="row"
        justify="space-between"
        pt={{ base: '8', md: '12' }}
        pb={6}
      >
        <Stack spacing={{ base: '6', md: '8' }} align="start">
          <Image
            h={{ base: '32px', md: '48px' }}
            src="/assets/images/sukanda_logo_LR.png"
            alt="SOL Logo"
          />
        </Stack>
        <Stack spacing={{ base: '6', md: '8' }}>
          <HStack spacing="4" flex="1" alignItems="flex-end">
            <Link
              href="https://open.sukandaonelink.com/contact-us"
              fontWeight="semibold"
              target="_blank"
              rel="noopener noreferrer"
              color="subtle"
            >
              Kontak Kami
            </Link>
            <Text>&bull;</Text>
            <Link
              href="https://reportsol.sukandaonelink.com/faq/e6a238dca766d1c3d1264a6c54f8cdd0f30d3fea20f74f41294d972af91ad255.html"
              fontWeight="semibold"
              target="_blank"
              rel="noopener noreferrer"
              color="subtle"
            >
              Bantuan
            </Link>
          </HStack>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        pt="6"
        pb="12"
        justify="end"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
      >
        <Box>
          <Text fontSize="sm" color="subtle">
            &copy; {new Date().getFullYear()} SUKANDA ONELINK
          </Text>
        </Box>
        <Box>
          <Text fontSize="sm">Version {pacakgeInfo.version}</Text>
        </Box>
      </Stack>
    </Container>
  );
}

export default Footer;
