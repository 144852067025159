import { Box, Skeleton, Stack, useDisclosure } from '@chakra-ui/react';
import { QueryWrapper } from '@diamond/shared/data-access';
import { ProductKeyword } from '@diamond/shared/types';
import {
  type SortableItem,
  Breadcrumbs,
  Button,
  Divider,
  DropResult,
  Sortable,
  Text,
} from '@diamond/shared/ui';
import { apiInstance } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import {
  AdminLayout,
  CreateKeyword,
  KeywordItem,
} from '@diamond/sol-admin/common';
import AddIcon from '@mui/icons-material/Add';

export function KeywordsPage() {
  const session = useAuthStore();
  const { data, refetch, isLoading } = QueryWrapper(
    '/backoffice/product/keyword',
    session.access_token
  ).useQueryGet<ProductKeyword>('keywords');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Keyword Management',
      link: '/keywords',
    },
  ];

  if (isLoading) {
    return (
      <AdminLayout>
        <Box py={4}>
          <Skeleton height="540px" borderRadius="md" />
        </Box>
      </AdminLayout>
    );
  }

  const keywords: SortableItem[] = data.map((keyword) => {
    return {
      id: keyword.id,
      children: <KeywordItem data={keyword} refetch={refetch} />,
    };
  });

  const updateIndex = async (id: string, index: number) => {
    return await apiInstance(session.access_token).patch(
      `/backoffice/product/keyword/${id}/reorder`,
      { index }
    );
  };

  const handleIndexChange = (result: DropResult) => {
    if (result.destination) {
      updateIndex(result.draggableId, result.destination.index + 1);
    }
  };

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack spacing={8} direction="row" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Keyword Management
          </Text>
          <Button
            leftIcon={<AddIcon />}
            bg="blue"
            color="white"
            variant="solid"
            _hover={{ bg: 'navy' }}
            onClick={onOpen}
            isDisabled={data && data.length >= 5}
          >
            Tambah Keyword
          </Button>
        </Stack>
      </Box>

      <Divider />

      <Box my={8}>
        <Sortable data={keywords} handleChange={handleIndexChange} />
      </Box>

      <CreateKeyword
        data={data}
        refetch={refetch}
        isOpen={isOpen}
        onClose={onClose}
      />
    </AdminLayout>
  );
}

export default KeywordsPage;
