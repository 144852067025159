import { createContext, useContext, useState } from 'react';

export interface SearchContextType {
  keyword: string;
  setKeyword: (payload: string) => void;
  clearKeyword: () => void;
}

export interface SearchProps {
  children: React.ReactNode;
}

export const SearchContext = createContext<SearchContextType | null>(null);

export function SearchProvider({ children }: SearchProps) {
  const [keyword, setKeyword] = useState<string>('');
  function setSearchKeyword(payload: string) {
    setKeyword(payload);
  }

  function clearKeyword() {
    setKeyword('');
  }

  return (
    <SearchContext.Provider
      value={{
        keyword,
        clearKeyword,
        setKeyword: setSearchKeyword,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  const search = useContext(SearchContext) as SearchContextType;
  return search;
}
