import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export interface TextAreaProps extends TextareaProps {
  name: string;
  label: string;
}

export function TextArea({ name, label, ...props }: TextAreaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';
  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} htmlFor={props.id}>
          {label}
        </FormLabel>
      )}
      <Textarea {...register(name)} {...props} borderColor="gray.75" />
      <FormErrorMessage textColor="red">
        {errorMessage as string}
      </FormErrorMessage>
    </FormControl>
  );
}

export default TextArea;
