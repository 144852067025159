import { Button as ChakraButton } from '@chakra-ui/react';
import React from 'react';

type AsProp<T extends React.ElementType> = {
  as?: T;
};

type PropsToOmit<T extends React.ElementType, P> = keyof (AsProp<T> & P);

// This is the first reusable type utility we built
type PolymorphicComponentProp<
  T extends React.ElementType,
  Props = {}
> = React.PropsWithChildren<Props & AsProp<T>> &
  Omit<React.ComponentPropsWithoutRef<T>, PropsToOmit<T, Props>>;

// This is a new type utitlity with ref!
type PolymorphicComponentPropWithRef<
  T extends React.ElementType,
  Props = {}
> = PolymorphicComponentProp<T, Props> & { ref?: PolymorphicRef<T> };

// This is the type for the "ref" only
type PolymorphicRef<T extends React.ElementType> =
  React.ComponentPropsWithRef<T>['ref'];

/**
 * This is the updated component props using PolymorphicComponentPropWithRef
 */
type ButtonProps<T extends React.ElementType> = PolymorphicComponentPropWithRef<
  T,
  {
    type?: 'button' | 'submit';
    variant?: 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled';
    disabled?: boolean;
  }
>;

/**
 * This is the type used in the type annotation for the component
 */
type ButtonComponent = <T extends React.ElementType = typeof ChakraButton>(
  props: ButtonProps<T>
) => React.ReactElement | null;

export const Button: ButtonComponent = React.forwardRef(
  <T extends React.ElementType = typeof ChakraButton>(
    {
      as,
      type = 'button',
      variant = 'outline',
      disabled,
      children,
      ...rest
    }: ButtonProps<T>,
    ref?: PolymorphicRef<T>
  ) => {
    const Component = as || ChakraButton;
    return (
      <Component
        type={type}
        variant={variant}
        ref={ref}
        fontSize={{ base: '14px', md: '16px' }}
        {...rest}
        disabled={disabled}
      >
        {children}
      </Component>
    );
  }
);
