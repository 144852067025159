export function usePaginationAdmin(
  page: number,
  setPage: (value: number) => void,
  refetch: () => void,
  pageCount: number
) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const nextPage = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
    setTimeout(scrollToTop, 200);
  };

  const previousPage = () => {
    if (page >= 1) {
      setPage(page - 1);
    }
    setTimeout(scrollToTop, 200);
  };

  const lastPage = () => {
    setPage(pageCount);

    setTimeout(scrollToTop, 200);
  };

  const firstPage = () => {
    setPage(1);

    setTimeout(scrollToTop, 200);
  };

  return { nextPage, previousPage, lastPage, firstPage };
}
