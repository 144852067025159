import {
  BackOfficeOrderChangelogs,
  BackofficeStatusHistory,
  QueryStringSearch,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import cleanDeep from 'clean-deep';

export const getProductAdditional = async (
  token: string,
  activitiesId: string,
  params: Readonly<QueryStringSearch>
) => {
  const url = `/backoffice/order/${activitiesId}/additional-products`;
  const { data } = await apiInstance(token).get(url, {
    params: cleanDeep(params),
  });
  return data;
};

export const getOrderChangelogs = async (token: string, activityId: string) => {
  const url = `/backoffice/order/${activityId}/changelog`;
  const { data } = await apiInstance(token).get<BackOfficeOrderChangelogs>(url);
  return data;
};

export const getOrderHistory = async (token: string, activityId: string) => {
  const url = `/backoffice/order/${activityId}/status-history`;
  const { data } = await apiInstance(token).get<BackofficeStatusHistory>(url);
  return data;
};
