import {
  adminCaptureException,
  apiInstance,
  buyerCaptureException,
} from '@diamond/shared/utils';
import { AxiosError, AxiosRequestConfig } from 'axios';

export function PostDataWrapper(
  url: string,
  token?: string,
  withoutStringify?: boolean,
  captureException?: 'admin' | 'buyer'
) {
  async function postData<T>(
    payload: T,
    config?: AxiosRequestConfig
  ): Promise<unknown> {
    try {
      const response = await apiInstance(token, config).post(
        url,
        withoutStringify ? payload : JSON.stringify(payload)
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (captureException === 'admin') {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'PostDataWrapper error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(url, payload);
            scope.setLevel('error');
          });
          throw new Error(error.response?.data.message);
        }
        if (captureException === 'buyer') {
          buyerCaptureException(error.response?.data, (scope) => {
            scope.setTags({
              queryKey: 'PostDataWrapper error',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setExtra(url, payload);
            scope.setLevel('error');
          });
          throw new Error(error.response?.data.message);
        }
      }
      return;
    }
  }
  return { postData };
}
