import { QueryWrapper } from '@diamond/shared/data-access';
import { BackofficeOrder } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { query } from 'urlcat';

type UseActivitiesDataProps = {
  search_by: string;
  search_query: string;
  order_by: string;
  direction: string;
  status: string;
  start_date: number | null;
  end_date: number | null;
  distribution_channel: string;
  cg_1_business_segmentation: string[];
};

export const useActivitiesData = (queryString: UseActivitiesDataProps) => {
  const session = useAuthStore();

  const queryActivities = QueryWrapper(
    '/backoffice/order',
    session.access_token
  );

  const {
    data: activitiesData,
    total,
    pageCount,
    refetch,
    isLoading,
    isFetched,
  } = queryActivities.useQueryGetAll<BackofficeOrder>(
    'Activities',
    queryString,
    undefined,
    undefined,
    {
      paramsSerializer: (p) => {
        return query(p, { arrayFormat: 'repeat' });
      },
    }
  );

  return {
    isLoading,
    isFetched,
    activitiesData,
    total,
    pageCount,
    refetch,
  };
};

export default useActivitiesData;
