/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Center,
  chakra,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  actions?: React.ReactElement;
  selectedRowAction?: (rowItem: any) => void;
  isLoading?: boolean;
};

export function DataTable<Data extends object>({
  data,
  columns,
  actions,
  isLoading,
  selectedRowAction,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  });

  return (
    <TableContainer overflowX="auto" overflowY="unset">
      {isLoading ? (
        <Progress size="xs" isIndeterminate colorScheme="messenger" />
      ) : null}
      <Table variant="striped" overflowX="auto">
        <Thead position="sticky" top={0} zIndex="docked">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    zIndex="docked"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <ArrowDropDownIcon
                            color="inherit"
                            fontSize="inherit"
                            aria-label="sorted descending"
                          />
                        ) : (
                          <ArrowDropUpIcon
                            color="inherit"
                            fontSize="inherit"
                            aria-label="sorted ascending"
                          />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
              {actions && <Th aria-label="action"></Th>}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {data.length === 0 && (
            <Tr>
              <Td colSpan={columns.length + 1}>
                <Center h="100%">
                  <Text fontSize="lg">Data tidak ditemukan</Text>
                </Center>
              </Td>
            </Tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              cursor="default"
              onClick={() => {
                const rowData = table.getRowModel().rowsById[row.id];
                selectedRowAction?.(rowData.original);
              }}
            >
              {row.getVisibleCells().map((cell) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td key={cell.id} isNumeric={meta?.isNumeric}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
              {actions && <Td isNumeric={false}>{actions}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default DataTable;
