import { ButtonGroup, Flex, IconButton, Tag, Text } from '@chakra-ui/react';
import { SelectItem } from '@diamond/shared/types';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

export interface PaginationProps {
  currentPage: number;
  totalPage: number;
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  setPageIndex?: (value: number) => void;
  setPageSize?: (value: number) => void;
  previousPage?: () => void;
  nextPage?: () => void;
  lastPage?: () => void;
  firstPage?: () => void;
  filled?: boolean;
}

export const DISPLAY_IN_LIMITS: SelectItem[] = [
  { label: '5', value: 5 },
  { label: '15', value: 15 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export function Pagination({
  currentPage,
  totalPage,
  nextPage,
  firstPage,
  lastPage,
  previousPage,
  filled = true,
}: PaginationProps) {
  return (
    <Flex align="center">
      <ButtonGroup variant="outline" size="md">
        <IconButton
          aria-label="First"
          icon={<KeyboardDoubleArrowLeftOutlinedIcon />}
          fontSize="sm"
          variant="outline"
          borderColor="gray.33"
          onClick={firstPage}
          isDisabled={currentPage === 1}
        />
        <IconButton
          aria-label="Previous"
          icon={<KeyboardArrowLeftOutlinedIcon />}
          fontSize="sm"
          variant="outline"
          borderColor="gray.33"
          onClick={previousPage}
          isDisabled={currentPage === 1}
        />
        <Flex
          justifyContent="center"
          alignItems="center"
          borderWidth="1px"
          borderRadius="md"
          borderColor="gray.33"
          fontSize="14px"
          px={{ base: 6, md: 8 }}
          h={10}
        >
          <Text display={{ base: 'none', md: 'flex' }}>Halaman</Text>
          {filled ? (
            <Tag bgColor="blue" textColor="white" mx={1} id="current-page">
              {currentPage ? currentPage : '1'}
            </Tag>
          ) : (
            <Text>&nbsp;{currentPage ? currentPage : '1'}&nbsp;</Text>
          )}
          dari{' '}
          <Text fontWeight="bold" mx={1}>
            {totalPage}
          </Text>
        </Flex>
        <IconButton
          aria-label="Next"
          icon={<KeyboardArrowRightOutlinedIcon />}
          fontSize="sm"
          variant="outline"
          borderColor="gray.33"
          onClick={nextPage}
          isDisabled={currentPage === totalPage}
        />
        <IconButton
          aria-label="Last"
          icon={<KeyboardDoubleArrowRightOutlinedIcon />}
          fontSize="sm"
          variant="outline"
          borderColor="gray.33"
          onClick={lastPage}
          isDisabled={currentPage === totalPage}
        />
      </ButtonGroup>
    </Flex>
  );
}

export default Pagination;
