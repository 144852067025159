import {
  ProductHighlightDetail,
  QueryStringSearch,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import cleanDeep from 'clean-deep';

export const getProductHighLightDetail = async (token: string, id: string) => {
  const url = `/backoffice/product/highlight/${id}`;
  const { data } = await apiInstance(token).get<ProductHighlightDetail>(url);
  return data;
};

export const getProductHighLightItem = async (
  token: string,
  id: string,
  params: Readonly<QueryStringSearch>
) => {
  const url = '/backoffice/product/highlight';
  const { data } = await apiInstance(token).get(`${url}/${id}/item`, {
    params: cleanDeep(params),
  });
  return data;
};

export const reorderProductHighLightItem = async (
  token: string,
  productHighlightId: string,
  productItemId: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/product/highlight/${productHighlightId}/item/${productItemId}/reorder`,
    JSON.stringify({ index })
  );
};
