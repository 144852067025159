import {
  Box,
  FormControl,
  HStack,
  IconButton,
  Input,
  Text,
  useNumberInput,
} from '@chakra-ui/react';
import { STOCK_UI } from '@diamond/shared/decimal-util';
import { BackOfficeProductAdditionalSAP } from '@diamond/shared/types';
import {
  adminCurrencyFormatter,
  convertToFloat,
  isEven,
  numberWithSeparator,
} from '@diamond/shared/utils';
import {
  AddOutlined,
  DeleteOutlined,
  RemoveOutlined,
} from '@mui/icons-material';
import { dinero, toDecimal } from 'dinero.js';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type CardProductAdditionalProps = {
  data: BackOfficeProductAdditionalSAP;
  index: number;
  deleteProduct: (productId: string) => void;
};

export function CardProductAdditional({
  data,
  index,
  deleteProduct,
}: CardProductAdditionalProps) {
  const [countDecimal, setCountDecimal] = useState<string>('');
  const { register } = useFormContext();

  const minOrderQty = Number(
    toDecimal(
      dinero({
        amount: data.minimum_order_quantity,
        currency: STOCK_UI,
      })
    )
  );

  const orderQtyMultiple = Number(
    toDecimal(
      dinero({
        amount: data.order_quantity_multiple,
        currency: STOCK_UI,
      })
    )
  );

  const maxStock = Number(
    toDecimal(
      dinero({
        amount: data.stock,
        currency: STOCK_UI,
      })
    )
  );

  const maxStockGanjil = Number(
    (Math.floor(maxStock / orderQtyMultiple) * orderQtyMultiple).toFixed(2)
  );

  const maxStockGenap = Number(
    (Math.round(maxStock / orderQtyMultiple) * orderQtyMultiple).toFixed(2)
  );

  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    value: count,
  } = useNumberInput({
    id: `input-${data.id}`,
    name: `input-${data.item_code}`,
    step: orderQtyMultiple,
    defaultValue: minOrderQty,
    min: minOrderQty,
    precision: orderQtyMultiple > 1 ? 2 : 0,
    max: isEven(orderQtyMultiple) ? maxStockGanjil : maxStockGenap,
    isDisabled: minOrderQty > maxStock ? true : false,
    parse: (val) => val.split('.').join('').split(',').join(''),
    format: (val) => numberWithSeparator(val),
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  useEffect(() => {
    if (orderQtyMultiple !== 1) {
      const countFixed = numberWithSeparator(convertToFloat(count).toFixed(2));
      setCountDecimal(countFixed);
    }
  }, [count, orderQtyMultiple]);

  return (
    <Box
      id={`selected-product-${index}`}
      key={`selected-product-${index}`}
      py="1"
      px="1"
      mt="2"
    >
      <HStack alignItems="flex-end" justifyContent="space-between">
        <Box>
          <Text fontSize="xs">{data.desc}</Text>
          <Text fontSize="xs">{data.item_code}</Text>
          <Text fontSize="xs">{adminCurrencyFormatter(data.price_sap)}</Text>
        </Box>
        <HStack>
          <HStack w="170px">
            <IconButton
              aria-label="min"
              name="min"
              icon={<RemoveOutlined fontSize="medium" />}
              size="xs"
              border="1px"
              bg="blue"
              color="white"
              _hover={{ bgColor: 'zure' }}
              onBlur={(e) => e.preventDefault()}
              {...dec}
            />
            <Box>
              {orderQtyMultiple !== 1 ? (
                <Box border="1px" borderRadius="lg" width="106px">
                  <Text
                    textAlign="center"
                    fontWeight="semibold"
                    {...register(`${data.item_code}`, { value: count })}
                  >
                    {countDecimal}
                  </Text>
                </Box>
              ) : (
                <FormControl id={`input-${data.item_code}`}>
                  <Input
                    fontWeight="semibold"
                    textAlign="center"
                    variant="unstyled"
                    fontSize={{ base: '14px', md: '16px' }}
                    autoComplete="off"
                    border="1px"
                    {...register(`${data.item_code}`, { value: count })}
                    {...input}
                  />
                </FormControl>
              )}
            </Box>
            <IconButton
              aria-label="plus"
              name="plus"
              icon={<AddOutlined />}
              size="xs"
              border="1px"
              bgColor="blue"
              color="white"
              _hover={{ bgColor: 'zure' }}
              onBlur={(e) => e.preventDefault()}
              {...inc}
            />
          </HStack>
          <IconButton
            name="remove-item"
            icon={<DeleteOutlined />}
            aria-label="remove-item"
            bg="white"
            color="red"
            size="xs"
            ml={2}
            _active={{ bgColor: 'white' }}
            _hover={{ bgColor: 'gray.33' }}
            onClick={() => {
              deleteProduct(data.id);
            }}
          />
        </HStack>
      </HStack>
    </Box>
  );
}
