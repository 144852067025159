import { Flex, Stack, Text } from '@chakra-ui/react';

export function HeadingSortItem() {
  return (
    <Flex
      w="full"
      justifyContent="flex-start"
      bgColor="#E6E6F6"
      p={2}
      py={5}
      fontWeight="bold"
    >
      <Stack w="13% " ml={20}>
        <Text>Banner Promo</Text>
      </Stack>
      <Stack textAlign="center" w="13%">
        <Text>Brand</Text>
      </Stack>
      <Stack w="13%">
        <Text>Mekanisme</Text>
      </Stack>

      <Stack w="12%">
        <Text>Periode</Text>
      </Stack>
      <Stack textAlign="center" w="12%">
        <Text>Kode</Text>
      </Stack>
      <Stack textAlign="center" w="12%">
        <Text>Status </Text>
      </Stack>
      <Stack textAlign="center" w="12%">
        <Text>Tampil Di SOL </Text>
      </Stack>
      <Stack textAlign="center" w="12%">
        <Text>Banner Beranda </Text>
      </Stack>
    </Flex>
  );
}
export default HeadingSortItem;
