export default {
  blue: '#0000A3',
  cyan: '#00D5FF',
  pink: '#FF5C5C',
  azure: '#006BD1',
  sky: '#85EBFF',
  maroon: '#B80000',
  navy: '#00003D',
  aqua: '#00A0E8',
  green: '#00A34B',
  orange: '#E28800',
  purple: '#5200A3',
  darkGreen: '#005527',
  blueDark: '#0068A3',
  white: '#FFFFFF',
  gray: {
    33: '#D9D9D9',
    75: '#999999',
    customGray: '#F3F4F5',
  },
  black: '#000000',
  radio: '#0000A3',
};
