import { Text as ChakraText, TextProps } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface TextChakraProps extends TextProps {}

export function Text({ children, ...props }: TextChakraProps) {
  return (
    <ChakraText fontSize={{ base: '14px', md: '16px' }} {...props}>
      {children}
    </ChakraText>
  );
}

export default Text;
