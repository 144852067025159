import {
  chakra,
  HStack,
  Stack,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { PostDataWrapper } from '@diamond/shared/data-access';
import { Banner } from '@diamond/shared/types';
import {
  Button,
  Divider,
  Dropzone,
  Modal,
  showToast,
  Text,
  TextField,
} from '@diamond/shared/ui';
import { apiInstance, isValidUrl } from '@diamond/shared/utils';
import { BannerFormValidationSchema } from '@diamond/shared/validation';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import { CustomerTypeInput } from '../CustomerTypeInput';

interface CreateBannerProps {
  isOpen: boolean;
  onClose: () => void;
  data: Banner;
}

export function CreateBanner({
  isOpen = false,
  onClose,
  data: banners,
}: CreateBannerProps) {
  const session = useAuthStore();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(BannerFormValidationSchema),
    shouldUnregister: false,
  });
  const toast = useToast();

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countTitleChars, setCountTitleChars] = useState(0);

  const { postData: postUpload } = PostDataWrapper(
    '/backoffice/banner/upload',
    session.access_token,
    true,
    'admin'
  );

  const indexes = banners.map((object) => {
    return object.index;
  });
  const latestIndex = Math.max(...indexes);
  const bannerIndex = latestIndex < 1 ? 1 : latestIndex + 1;

  const onSubmit = async (formValues: FieldValues) => {
    const formData = new FormData();
    const { title, url, banner, distribution_channel, business_segmentation } =
      formValues;

    const urlForSubmit =
      url && !validator.contains(url, 'http') ? `https://${url}` : url;

    if (url && !isValidUrl(urlForSubmit)) {
      return showToast(toast, 'error', 'URL tidak sesuai');
    }

    setIsLoading(true);
    formData.append('file', banner[0], banner[0].name);
    postUpload(formValues, {
      headers: {
        authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(async (res: any) => {
        await apiInstance(session.access_token).post('/backoffice/banner', {
          title,
          url: urlForSubmit,
          image: res.url,
          index: bannerIndex,
          business_segmentation:
            business_segmentation?.map((val: any) => val.value) ?? [],
          distribution_channel:
            distribution_channel?.map((val: any) => val.value) ?? [],
        });
        showToast(toast, 'success', 'Banner berhasil disimpan');
        navigate(0);
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
        methods.reset();
      });

    setIsLoading(false);
  };

  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-tambah-banner"
      title="Tambah Banner"
      hideAction
    >
      <Divider borderColor="black" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack py={4}>
            <Dropzone
              name="banner"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={2}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG dan JPG dengan ukuran maksimal 2MB.
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 1312x512 pixel.
            </Text>
          </Stack>
          <Stack pt={4} pb={8}>
            <TextField
              name="title"
              label="Judul Banner"
              placeholder="contoh: Diamond"
              register={methods.register}
              onChange={(e) => setCountTitleChars(e.target.value.length)}
              maxLength={100}
              errors={methods.formState.errors}
            />
            <Text fontSize="sm" color="gray" textAlign="right">
              {`${countTitleChars}/100`}
            </Text>
            <CustomerTypeInput />
            <TextField
              name="url"
              aria-label="Link"
              label={
                <>
                  Link <chakra.span color="gray.75">(Opsional)</chakra.span>
                </>
              }
              placeholder="Masukkan Link"
              register={methods.register}
              maxLength={100}
              errors={methods.formState.errors}
            />
          </Stack>
          <Divider borderColor="black" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    isDisabled={isLoading}
                  >
                    Tutup
                  </Button>
                  <Button
                    type="submit"
                    variant="solid"
                    isLoading={isLoading}
                    isDisabled={!isReady}
                  >
                    <span>Tambahkan</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
}
