import { Badge, Box, Text } from '@chakra-ui/react';

type PromoPriceProps = {
  discountAmount?: number;
  cashbackAmount?: number;
  formattedPrice: string;
  isActive?: boolean;
  isFreeGoods?: boolean;
};

export function PromoPrice({
  discountAmount,
  cashbackAmount,
  formattedPrice,
  isFreeGoods,
  isActive = false,
}: PromoPriceProps) {
  return discountAmount || cashbackAmount || isFreeGoods ? (
    <Box display="flex" gap="1" alignItems="center">
      <Text
        color="gray.75"
        textDecoration="line-through"
        fontSize={{ base: 'xs', md: 'sm' }}
      >
        {isFreeGoods ? formattedPrice : `Rp${formattedPrice}`}
      </Text>
      {discountAmount ? (
        <Badge
          color={isActive ? '#FF0000' : '#006BD1'}
          bgColor={isActive ? '#FFDADA' : '#F3F4F5'}
          rounded="4px"
          fontSize={{ base: '2xs', md: 'xs' }}
        >
          {isActive ? `${discountAmount / 100}%` : `*${discountAmount / 100}%`}
        </Badge>
      ) : isFreeGoods ? (
        <Badge
          color={isActive ? '#FF0000' : '#006BD1'}
          bgColor={isActive ? '#FFDADA' : '#F3F4F5'}
          rounded="4px"
          fontSize={{ base: '2xs', md: 'xs' }}
        >
          Gratis
        </Badge>
      ) : undefined}
    </Box>
  ) : null;
}
