import { Center, Spinner, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

/* eslint-disable-next-line */
export interface OverlayProps {
  isLoading: boolean;
  text: string;
  bgSolid?: boolean;
}

export function LoadingOverlay({ isLoading, text, bgSolid }: OverlayProps) {
  const Overlay = styled.div`
    position: fixed;
    display: ${isLoading ? 'block' : 'none'};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${bgSolid ? '#ffffff' : 'rgba(0, 0, 0, 0.5)'};
    z-index: 99;
    cursor: pointer;
  `;
  const TextStyle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  `;

  return (
    <Overlay>
      <TextStyle>
        <Center>
          <Spinner size="xl" />
        </Center>
        <Text fontSize="xl" fontWeight="bold" pt="2rem">
          {text}
        </Text>
      </TextStyle>
    </Overlay>
  );
}

export default LoadingOverlay;
