import {
  Box,
  HStack,
  Spinner,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { magnifyValue } from '@diamond/shared/decimal-util';
import {
  BackOfficeProductAdditionalData,
  BackOfficeProductAdditionalSAP,
} from '@diamond/shared/types';
import { Button, Divider, Searchbox, showToast } from '@diamond/shared/ui';
import {
  adminCaptureException,
  apiInstance,
  divisionMasking,
} from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { getProductAdditional } from '@diamond/sol-admin-context';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useDebounce } from 'usehooks-ts';

import { BrowseProductCard } from './BrowseProductCard';
import { CardProductAdditional } from './CardProductAdditional';

export interface FormAddProductProps {
  activitiesId: string;
  addProductToActivity: UseMutationResult;
  onClose: () => void;
  refetch: () => void;
  division?: string;
}

const MIN_KEYWORD_LENGTH = 3;

export function FormAddProduct({
  activitiesId,
  division,
  addProductToActivity,
  onClose,
  refetch,
}: FormAddProductProps) {
  const session = useAuthStore();
  const methods = useForm();
  const { handleSubmit, register, resetField } = methods;
  const toast = useToast();

  const [keyword, setKeyword] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [stopFetch, setStopFetch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debouncedValue = useDebounce<string>(keyword, 1000);
  const [browseProduct, setBrowseProduct] =
    useState<BackOfficeProductAdditionalData>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    BackOfficeProductAdditionalSAP[]
  >([]);

  const handleKeyDownSearchItem = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;
    setKeyword(inputElement.value.trimStart().trimEnd());
    setPage(1);
    setStopFetch(false);
  };

  useEffect(() => {
    // untuk mencegah pesan `data tidak ditemukan` muncul secara langsung
    // setelah keyword mencapai minimum len
    if (keyword.length >= MIN_KEYWORD_LENGTH) {
      setIsLoading(true);
    }
  }, [keyword]);

  useEffect(() => {
    if (debouncedValue.length >= MIN_KEYWORD_LENGTH) {
      // setIsLoading(true);
      getProductAdditional(session.access_token, activitiesId, {
        page: page,
        size: 10,
        search_query: debouncedValue,
      }).then(({ data }) => {
        setBrowseProduct(data);
        setIsLoading(false);
      });
    } else {
      setBrowseProduct([]);
      setIsLoading(false);
    }
  }, [debouncedValue]);

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    const newSelectedProducts = selectedProducts.map((product) => {
      const stringNumber = values[product.item_code.toString()];
      const toNumber = parseFloat(stringNumber.replace(',', '.'));
      const qtyMagnified = magnifyValue(toNumber, 'stock');
      return {
        id: product.id,
        quantity: qtyMagnified.magnifiedValue,
      };
    });
    const payload = {
      order_id: activitiesId,
      products: newSelectedProducts,
    };
    addProductToActivity.mutate(payload);
    refetch();
  };

  const handleSelectProduct = async (
    product: Omit<
      BackOfficeProductAdditionalSAP,
      'stock_sap' | 'price_sap' | 'valuation_type'
    >
  ) => {
    const CHECK_SAP_URL = `/backoffice/order/${activitiesId}/${product.id}/additional-products/check-price-stock-sap`;
    try {
      await apiInstance(session.access_token)
        .get(CHECK_SAP_URL)
        .then(({ data: productDataSAP }) => {
          setBrowseProduct([]);
          setSelectedProducts((prevData) => [...prevData, productDataSAP]);
        });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.error === 'EMPTY_STOCK') {
          const idToast = 'empty_stock';
          if (!toast.isActive(idToast)) {
            showToast(
              toast,
              'error',
              error.response?.data.message,
              idToast,
              undefined,
              10000
            );
          }
        } else {
          adminCaptureException(error.response?.data.message, (scope) => {
            scope.setTags({
              queryKey: 'Admin Check SAP',
              status_code:
                error instanceof AxiosError
                  ? error.response?.data.statusCode
                  : undefined,
            });
            scope.setUser({
              id: activitiesId,
              email: session.userEmail,
            });
            scope.setContext('error', {
              error:
                error instanceof AxiosError ? error.response?.data : undefined,
            });
            scope.setLevel('error');
          });
        }
      }
    }
  };

  const handleDeleteProduct = (productId: string) => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.id !== productId)
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Text>Division: {divisionMasking(String(division))?.label}</Text>;
          <Searchbox
            name="search_query"
            id="search-query"
            register={register}
            placeholder="Cari Produk"
            autoComplete="off"
            type="search"
            onChange={handleKeyDownSearchItem}
            size="md"
          />
          <Box
            justifyContent="center"
            alignItems="center"
            p="2"
            display={isLoading ? 'flex' : 'none'}
          >
            <Spinner color="blue" />
          </Box>
          <Box
            id="search-products"
            aria-label="search-products"
            zIndex={2}
            width="auto"
            maxW="container.lg"
            backgroundColor="white"
            boxShadow="md"
            borderRadius="md"
            p="2"
            display={browseProduct.length > 0 ? 'block' : 'none'}
            overflow="auto"
            maxH="700px"
            onScroll={(e) => {
              const element = e.target as HTMLDivElement;
              if (
                element.scrollHeight - element.scrollTop ===
                  element.clientHeight &&
                !stopFetch
              ) {
                setIsLoading(true);
                setPage((prevPage) => prevPage + 1);
                getProductAdditional(session.access_token, activitiesId, {
                  page: page === 1 ? page + 1 : page + 1,
                  size: 10,
                  search_query: keyword,
                }).then(({ data }) => {
                  if (data.length > 0) {
                    setBrowseProduct((prevData) => [...prevData, ...data]);
                    setIsLoading(false);
                    setStopFetch(false);
                  } else {
                    setIsLoading(false);
                    setStopFetch(true);
                  }
                });
              }
            }}
          >
            {browseProduct.length > 0
              ? browseProduct.map(
                  (
                    product: BackOfficeProductAdditionalData[0],
                    idx: number
                  ) => {
                    return (
                      <BrowseProductCard
                        idx={idx}
                        product={product}
                        selected={selectedProducts.some(
                          (selectedProduct) => selectedProduct.id === product.id
                        )}
                        handleSelectProduct={handleSelectProduct}
                        resetSearch={() => {
                          resetField('search_query');
                          setKeyword('');
                        }}
                      />
                    );
                  }
                )
              : null}
          </Box>
          <Box>
            {browseProduct.length === 0 &&
            keyword.length >= MIN_KEYWORD_LENGTH &&
            !isLoading ? (
              <Box
                id="no-result"
                aria-label="no-result"
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                p="2"
              >
                <Text>
                  Produk tidak ditemukan atau sudah terpilih. Mohon cek kembali
                  data yang Anda masukkan
                </Text>
              </Box>
            ) : null}
          </Box>
          <Box
            id="selected-products"
            aria-label="selected-products"
            display={browseProduct.length > 0 ? 'none' : 'block'}
            overflow="auto"
            maxH="700px"
          >
            {selectedProducts.length > 0 &&
              selectedProducts.map((product, index) => {
                return (
                  <CardProductAdditional
                    index={index}
                    data={product}
                    deleteProduct={handleDeleteProduct}
                  />
                );
              })}
          </Box>
        </Stack>
        <Box>
          <Stack py={5}>
            <Divider borderColor="black" />
          </Stack>
          <Wrap justify="end">
            <WrapItem>
              <HStack>
                <Button
                  size="sm"
                  onClick={() => {
                    onClose();
                    methods.reset();
                  }}
                >
                  Batalkan
                </Button>
                <Button
                  aria-label="submit"
                  size="sm"
                  type="submit"
                  variant="solid"
                  isDisabled={selectedProducts.length < 1}
                >
                  Tambahkan
                </Button>
              </HStack>
            </WrapItem>
          </Wrap>
        </Box>
      </form>
    </FormProvider>
  );
}
