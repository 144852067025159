import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface HeadingChakraProps extends HeadingProps {}

export function Heading({ children, ...props }: HeadingChakraProps) {
  return <ChakraHeading {...props}>{children}</ChakraHeading>;
}

export default Heading;
