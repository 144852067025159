import {
  Alert,
  AlertDescription,
  AlertStatus,
  Box,
  CloseButton,
  createToastFn,
  Icon,
  StyleProps,
  ToastPosition,
  ToastProps,
} from '@chakra-ui/react';

import { getStatusColorScheme, getStatusIcon, getTextColor } from '../utils';

export interface ToastChakraProps extends ToastProps {
  description?: string;
  isClosable?: boolean;
  status?: AlertStatus;
  variant?: 'solid' | 'subtle' | 'left-accent' | 'top-accent';
}

export function Toast({
  id,
  status,
  description,
  onClose,
  variant = 'subtle',
  isClosable,
}: ToastChakraProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const baseIcon = getStatusIcon(status!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const colorScheme = getStatusColorScheme(status!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const textColor = getTextColor(status!);

  const ids = id
    ? {
        root: `toast-${id}`,
        description: `toast-${id}-description`,
      }
    : undefined;
  return (
    <Alert
      id={ids?.root}
      addRole={false}
      status={status}
      variant={variant}
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={4}
      width="auto"
    >
      <Box borderRadius="lg" display="flex" p={1} mr={4} bgColor={colorScheme}>
        <Icon as={baseIcon} color="white" />
      </Box>
      <Box>
        {description && (
          <AlertDescription
            id={ids?.description}
            display="block"
            textColor={textColor}
          >
            {description}
          </AlertDescription>
        )}
      </Box>
      {isClosable && (
        <CloseButton
          size="sm"
          onClick={onClose}
          position="absolute"
          insetEnd={1}
          top={1}
        />
      )}
    </Alert>
  );
}

declare type CreateToastFnReturn = ReturnType<typeof createToastFn>;

export const showToast = (
  toast: CreateToastFnReturn,
  status: AlertStatus,
  message: string,
  idToast?: string,
  position?: ToastPosition,
  duration?: number,
  containerStyle?: StyleProps
) => {
  toast({
    id: idToast,
    position: position ? position : 'bottom',
    duration: duration ? duration : 3000,
    containerStyle,
    render: () => (
      <Toast
        id={idToast}
        description={message}
        onClick={() => toast.closeAll()}
        status={status}
      />
    ),
  });
};

export default Toast;
