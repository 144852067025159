/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Center,
  chakra,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

export type StikyTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  actions?: React.ReactElement;
  // We don't know the types of the data
  selectedRowAction?: (rowItem: any) => void;
  slices?: number;
  innerScrollbar?: boolean;
};

export function StickyTable<Data extends object>({
  data,
  columns,
  actions,
  selectedRowAction,
  slices = 1,
  // FIXME: scrollsync not working
  innerScrollbar = false,
}: StikyTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  });

  return (
    <ScrollSync>
      <Flex
        w="100%"
        maxW="inherit"
        maxH={innerScrollbar ? 800 : 'full'}
        alignItems="start"
        gap={0}
      >
        {/* Left container */}
        <TableContainer
          overflowX="hidden"
          overflowY="auto"
          // position='fixed'
          minW="max-content"
          sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
          // __css={{
          //   '&::-webkit-scrollbar': {
          //     display: 'none',
          //   },
          //   '&::-webkit-scrollbar-track': {
          //     display: 'none',
          //   },
          //   '&::-webkit-scrollbar-thumb': {
          //     display: 'none',
          //   },
          // }}
        >
          <Table variant="striped" overflowX="hidden" overflowY="auto">
            <Thead position="sticky" top={0} zIndex="docked">
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.slice(0, slices).map((header) => {
                    const meta: any = header.column.columnDef.meta;
                    return (
                      <Th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        zIndex="docked"
                        h="84px"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <ArrowDropDownIcon
                                color="inherit"
                                fontSize="inherit"
                                aria-label="sorted descending"
                              />
                            ) : (
                              <ArrowDropUpIcon
                                color="inherit"
                                fontSize="inherit"
                                aria-label="sorted ascending"
                              />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <ScrollSyncPane group="vertical">
              <Tbody>
                {data.length === 0 && (
                  <Tr>
                    <Td colSpan={columns.length + 1}>
                      <Center h="100%">
                        <Text fontSize="lg" p={3} />
                      </Center>
                    </Td>
                  </Tr>
                )}
                {table.getRowModel().rows.map((row) => (
                  <Tr
                    key={row.id}
                    cursor="default"
                    onClick={() => {
                      const rowData = table.getRowModel().rowsById[row.id];
                      selectedRowAction?.(rowData.original);
                    }}
                    height="64px"
                  >
                    {row
                      .getVisibleCells()
                      .slice(0, slices)
                      .map((cell) => {
                        const meta: any = cell.column.columnDef.meta;
                        return (
                          <Td key={cell.id} isNumeric={meta?.isNumeric}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    {actions && <Td isNumeric={false}>{actions}</Td>}
                  </Tr>
                ))}
              </Tbody>
            </ScrollSyncPane>
          </Table>
        </TableContainer>
        {/* <Spacer /> */}
        {/* Right container */}
        <TableContainer overflowX="auto" overflowY="auto">
          <Table variant="striped" overflowX="unset" overflowY="auto">
            <ScrollSyncPane group="horizontal">
              <Thead position="sticky" top={0} zIndex="docked">
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers
                      .slice(slices, headerGroup.headers.length)
                      .map((header) => {
                        const meta: any = header.column.columnDef.meta;
                        if (header.id !== 'attachment') {
                          return (
                            <Th
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              isNumeric={meta?.isNumeric}
                              zIndex="docked"
                              h="84px"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              <chakra.span pl="4">
                                {header.column.getIsSorted() ? (
                                  header.column.getIsSorted() === 'desc' ? (
                                    <ArrowDropDownIcon
                                      color="inherit"
                                      fontSize="inherit"
                                      aria-label="sorted descending"
                                    />
                                  ) : (
                                    <ArrowDropUpIcon
                                      color="inherit"
                                      fontSize="inherit"
                                      aria-label="sorted ascending"
                                    />
                                  )
                                ) : null}
                              </chakra.span>
                            </Th>
                          );
                        } else {
                          return (
                            <Th
                              key={header.id}
                              isNumeric={meta?.isNumeric}
                              zIndex="docked"
                              h="84px"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              <chakra.span pl="4" />
                            </Th>
                          );
                        }
                      })}
                    {actions && <Th aria-label="action"></Th>}
                  </Tr>
                ))}
              </Thead>
            </ScrollSyncPane>
            <ScrollSyncPane group={['horizontal', 'vertical']}>
              <Tbody>
                {data.length === 0 && (
                  <Tr>
                    <Td colSpan={columns.length + 1}>
                      <Center h="100%">
                        <Text fontSize="lg">Data tidak ditemukan</Text>
                      </Center>
                    </Td>
                  </Tr>
                )}
                {table.getRowModel().rows.map((row) => (
                  <Tr
                    key={row.id}
                    cursor="default"
                    onClick={() => {
                      const rowData = table.getRowModel().rowsById[row.id];
                      selectedRowAction?.(rowData.original);
                    }}
                  >
                    {row
                      .getVisibleCells()
                      .slice(slices, row.getVisibleCells().length)
                      .map((cell) => {
                        const meta: any = cell.column.columnDef.meta;
                        return (
                          <Td key={cell.id} isNumeric={meta?.isNumeric}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    {actions && <Td isNumeric={false}>{actions}</Td>}
                  </Tr>
                ))}
              </Tbody>
            </ScrollSyncPane>
          </Table>
        </TableContainer>
      </Flex>
    </ScrollSync>
  );
}

export default StickyTable;
