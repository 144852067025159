import { RecursiveStatic, SAPErrorSchema } from '@diamond/shared/schema';

export type SAPError = RecursiveStatic<
  typeof SAPErrorSchema
>['static']['error'];

export const sapErrors: SAPError[] = [
  'SAP_PRICE_ERROR',
  'SAP_REQUEST_TIMEOUT_ERROR',
  'SAP_STOCK_ERROR',
  'SAP_SUBMIT_ORDER_ERROR',
  'SAP_UNEXPECTED_ERROR',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSAPErrorMessage = (errorCode: any = '') => {
  switch (errorCode as SAPError) {
    case 'SAP_PRICE_ERROR':
    case 'SAP_STOCK_ERROR':
      return 'Maaf, terdapat masalah pada pengecekan harga dan stok dari SAP';
    case 'SAP_SUBMIT_ORDER_ERROR':
      return 'Maaf, terdapat masalah dalam pembuatan order dari SAP';
    case 'SAP_REQUEST_TIMEOUT_ERROR':
      return 'Maaf, waktu koneksi SAP habis';
    case 'SAP_UNEXPECTED_ERROR':
    default:
      return 'Maaf, terjadi kesalahan pada sistem SAP';
  }
};