/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Fade, Stack, useDisclosure } from '@chakra-ui/react';
import { ResetPasswordResponse } from '@diamond/shared/types';
import { Alert, Button, Divider, Text, TextField } from '@diamond/shared/ui';
import { apiInstance } from '@diamond/shared/utils';
import { EmailPemulihanValidationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, isAxiosError } from 'axios';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

export function ResetPasswordPage() {
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure();
  const [status, setStatus] = useState<boolean>(false);

  const [statusDesc, setStatusDesc] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(EmailPemulihanValidationSchema) });

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const payload = { subscriber: 'admin', ...formData };
    await apiInstance()
      .post('/auth/forgot-password', payload)
      .then((response) => {
        const successMessage = response.data.message;
        setStatus(true);
        setStatusDesc(successMessage);
        onOpen();
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          const error = e as AxiosError<ResetPasswordResponse>;
          const responseData = error.response?.data;

          setStatus(false);
          setStatusDesc(responseData?.message as string);

          return;
        }
      })
      .finally(() => {
        onOpen();
      });
  };

  return (
    <>
      <Box py="8">
        <Text fontSize="3xl" fontWeight="bold">
          Lupa Kata Sandi?
        </Text>
        <Divider />
      </Box>
      <Box
        position="fixed"
        w={{ base: '350px', lg: '480px' }}
        hidden={!isVisible}
      >
        <Fade in={isVisible}>
          <Alert
            status={status ? 'success' : 'error'}
            description={statusDesc}
            onClose={onClose}
          />
        </Fade>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <TextField
            name="email"
            label="Email Terdaftar"
            placeholder="Masukkan Email"
            register={register}
            errors={errors}
          />
        </Stack>
        <Stack mt="4">
          <Button
            bg="blue"
            color="white"
            variant="solid"
            _hover={{ bg: 'navy' }}
            type="submit"
            isLoading={isSubmitting}
            py="24px"
          >
            <span>Kirimkan Saya Email</span>
          </Button>
        </Stack>
      </form>
    </>
  );
}

export default ResetPasswordPage;
