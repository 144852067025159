/**
 * Read:
 * - https://github.com/pmndrs/zustand/issues/882
 * - https://github.com/pmndrs/zustand/blob/main/docs/guides/updating-state.md
 */

import { AccountMeta } from '@diamond/shared/types';
import create from 'zustand';
// import createContext from 'zustand/context';
import { persist } from 'zustand/middleware';

export type SessionState = {
  userId: string;
  userName: string;
  userRole: string;
  access_token: string;
  user: Pick<AccountMeta, 'id' | 'email'> | null;
};

type ActionState = {
  setUserId: (userId: SessionState['userId']) => void;
  setUserName: (userId: SessionState['userName']) => void;
  setUserRole: (userId: SessionState['userRole']) => void;
  setAccessToken: (userId: SessionState['access_token']) => void;
  setUser: (user: SessionState['user']) => void;
};

export const useAuthStore = create<SessionState & ActionState>()(
  persist(
    (set) => ({
      userId: '',
      userName: '',
      userRole: '',
      access_token: '',
      user: null,
      setUserId: (userId) => set(() => ({ userId: userId })),
      setUserName: (userName) => set(() => ({ userName: userName })),
      setUserRole: (userRole) => set(() => ({ userRole: userRole })),
      setAccessToken: (access_token) =>
        set(() => ({ access_token: access_token })),
      setUser: (user) => set(() => ({ user: user })),
    }),
    {
      name: 'session-store', // must be a unique name
      getStorage: () => localStorage, // sessionStorage or localStorage
    }
  )
);

// This will replace current AuthContext in the future
// export const AuthContext = createContext<typeof useAuthStore>();
