import {
  AspectRatio,
  Box,
  chakra,
  Flex,
  Image as ChakraImage,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { buyerCurrencyFormatter } from '@diamond/shared/utils';
import { ItemInfo } from '@diamond/sol-buyer/utils';
import { useRef } from 'react';

import { Promo } from '../label-promo';
import { LABEL_PROMO_TYPES } from '../label-promo/constants';

type CardPromoInfoBaseProps = {
  type: Promo;
  duration: string;
  text: string;
  extra?: React.ReactNode;
  isInvalid?: boolean;
};

export interface CardPromoInfoFreeItem extends CardPromoInfoBaseProps {
  type: 'free-item';
  item: ItemInfo;
}

export interface CardPromoInfoCashback extends CardPromoInfoBaseProps {
  type: 'cashback';
}

export interface CardPromoInfoDiscount extends CardPromoInfoBaseProps {
  type: 'discount';
}

type CardPromoInfoProps =
  | CardPromoInfoFreeItem
  | CardPromoInfoCashback
  | CardPromoInfoDiscount;

const LABEL_PROMO_DISPLAY_TYPES = {
  [LABEL_PROMO_TYPES.FREE_ITEM]: 'Produk Gratis',
  [LABEL_PROMO_TYPES.DISCOUNT]: 'Diskon',
  [LABEL_PROMO_TYPES.CASHBACK]: 'Cashback',
};

export function CardPromoInfo(props: CardPromoInfoProps) {
  return (
    <Box>
      <Box
        borderTopLeftRadius="4px"
        borderTopRightRadius="12px"
        px={4}
        py={2}
        bgColor={props.isInvalid ? 'gray.33' : '#007AFF'}
        color={props.isInvalid ? 'gray.75' : 'white'}
        display="flex"
        gap="2"
        justifyContent="space-between"
      >
        <Text fontWeight="bold" fontSize="xs">
          {LABEL_PROMO_DISPLAY_TYPES[props.type]}
        </Text>
        <Text fontSize="xs">{props.duration}</Text>
      </Box>
      <Box
        px={4}
        py={2}
        bgColor={props.isInvalid ? '#F3F4F5' : '#F1F8FF'}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottomRadius="4px"
        gap="4"
      >
        {props.type === 'free-item' ? (
          <Flex display="flex" alignItems="center" gap="4" flex="1">
            <AspectRatio w="full" maxW="48px" ratio={1 / 1} flex="none">
              <ChakraImage
                src={props.item.img || '/images/product_placeholder.png'}
                alt="Product Promo Image"
                w="48px"
              />
            </AspectRatio>
            <Box>
              <PromoText text={props.text} />
              <Text>
                <chakra.span
                  textDecoration="line-through"
                  color="gray.75"
                  fontWeight="semibold"
                  fontSize="xs"
                >
                  {buyerCurrencyFormatter(props.item.price)}
                </chakra.span>
                <chakra.span
                  fontWeight="bold"
                  ml={2}
                  color="#FF0000"
                  fontSize="xs"
                >
                  Gratis{' '}
                </chakra.span>
                <chakra.span fontWeight="medium" color="blue" fontSize="xs">
                  {props.item.qty} {props.item.uom}
                </chakra.span>
              </Text>
            </Box>
          </Flex>
        ) : (
          <PromoText text={props.text} />
        )}
        {props.extra ? <Box flex="none">{props.extra}</Box> : null}
      </Box>
    </Box>
  );
}

const PromoText = ({ text }: { text: string }) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  const isTruncated = () => {
    if (textRef.current) {
      return textRef.current.scrollHeight > textRef.current.clientHeight;
    }
    return false;
  };

  return (
    <Box>
      {isTruncated() ? (
        <Tooltip label={text} hasArrow>
          <Text
            ref={textRef}
            fontWeight="semibold"
            color="black"
            fontSize="xs"
            noOfLines={2}
            flex="1"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {text}
          </Text>
        </Tooltip>
      ) : (
        <Text
          ref={textRef}
          fontWeight="semibold"
          color="black"
          fontSize="xs"
          noOfLines={2}
          flex="1"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {text}
        </Text>
      )}
    </Box>
  );
};
