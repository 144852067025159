import { Box, BoxProps } from '@chakra-ui/react';

export interface CardProps extends BoxProps {
  children?: React.ReactNode;
}

export function Card({ children, ...props }: CardProps) {
  return (
    <Box
      rounded="md"
      overflow="hidden"
      m="2"
      {...props}
    >
      {children}
    </Box>
  );
}

export default Card;
