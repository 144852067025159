export const paymentMethodFormatter = (value?: string) => {
  const rawData = value ? value.split('_') : [''];
  const formattedData = rawData
    .map((value) => (value ? value[0].toUpperCase() + value.slice(1) : ''))
    .join(' ');
  return formattedData;
};

export const paymentMethodFormatterTruncate = (paymentMethod?: string) => {
  switch (paymentMethod) {
    case 'term_of_payment':
      return 'TOP';
    case 'cash_on_delivery':
      return 'COD';
    default:
      return 'Transfer Bank';
      break;
  }
};
