import { apiInstance } from '@diamond/shared/utils';

export const getBanners = async (token: string) => {
  const { data } = await apiInstance(token).get(`/backoffice/banner`);
  return data;
};

export const getBannerById = async (token: string, id: string) => {
  const { data } = await apiInstance(token).get(`/backoffice/banner/${id}`);
  return data;
};

export const reorderBanner = async (
  token: string,
  id: string,
  index: number
) => {
  return await apiInstance(token).patch(
    `/backoffice/banner/${id}/reorder`,
    JSON.stringify({ index })
  );
};

export const deleteBanner = async (token: string, id: string) => {
  return await apiInstance(token).delete(`/backoffice/banner/${id}`);
};
