import { CSSProperties } from 'react';
import ReactSelect, { components, MultiValueProps, Props } from 'react-select';

const MoreSelectedBadge = ({ items }: { items: string[] }) => {
  const style: CSSProperties = {
    marginLeft: 'auto',
    background: 'hsl(0, 0%, 90%)',
    fontFamily: 'Open Sans',
    order: 99,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: '2px',
    color: 'hsl(0, 0%, 20%)',
    fontSize: '85%',
    padding: '3px',
    paddingInline: '6px',
    boxSizing: 'border-box',
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = (props: MultiValueProps) => {
  const { index, getValue } = props;
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label as string);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

export const MultiSelect = ({ ...props }: Props) => (
  <ReactSelect
    isMulti
    hideSelectedOptions={false}
    closeMenuOnSelect={false}
    components={{ MultiValue }}
    {...props}
  />
);
