import { Badge as ChakraBadge, BadgeProps } from '@chakra-ui/react';

export interface BadgeChakraProps extends BadgeProps {
  variants?:
    | 'gray'
    | 'blue'
    | 'red'
    | 'aqua'
    | 'orange'
    | 'purple'
    | 'blueDark'
    | 'green'
    | 'darkGreen';
}

export function Badge({ children, ...props }: BadgeChakraProps) {
  return (
    <ChakraBadge my={1} px={4} py={2} {...props}>
      {children}
    </ChakraBadge>
  );
}

export default Badge;
