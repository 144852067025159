import {
  Box,
  Button,
  Center,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Avatar } from '@diamond/shared/ui';
import {
  useAuthentication,
  useAuthStore,
} from '@diamond/sol-admin/authentication';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';

export function UserMenu() {
  const { logout } = useAuthentication();
  const session = useAuthStore();
  const access_token = session.access_token;

  let roleLabel;
  switch (session.userRole) {
    case 'cs_katalog':
      roleLabel = 'CS Katalog';
      break;
    case 'cs_cabang':
      roleLabel = 'CS Cabang';
      break;
    default:
      roleLabel = 'Admin Diamond';
      break;
  }

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <HStack
          spacing={4}
          py={2}
          px={3}
          bg="transparent"
          borderRadius="md"
          cursor="pointer"
          _hover={{ bg: 'blue' }}
        >
          <Box bg="white" borderRadius="md" w="40px" h="40px">
            <Avatar borderRadius="md" size="40px" />
          </Box>
          <Box>
            <Text color="white" fontSize="md" fontWeight="semibold">
              {roleLabel}
            </Text>
            <Text color="white" fontSize="sm">
              {session.userEmail}
            </Text>
          </Box>
        </HStack>
      </PopoverTrigger>

      <Portal>
        <Box zIndex="popover" w="full" h="full" border="none">
          <PopoverContent backgroundColor="white" mt={-16}>
            <PopoverHeader>
              <HStack spacing={4} p={4} my={1} bg="blue" borderRadius="md">
                <Box bg="white" w="40px" borderRadius="md" minH="40px">
                  <Center
                    w="40px"
                    h="40px"
                    bg="white"
                    color="blue"
                    borderRadius="md"
                  >
                    <Avatar borderRadius="md" />
                  </Center>
                </Box>
                <Box>
                  <Text color="white" fontWeight="semibold">
                    {roleLabel}
                  </Text>
                  <Text color="white" fontSize="sm">
                    {session.userEmail}
                  </Text>
                </Box>
              </HStack>
            </PopoverHeader>

            <PopoverBody pt={4}>
              <Stack direction="column">
                <Button
                  to="/change-password"
                  as={Link}
                  paddingInlineStart={2}
                  leftIcon={
                    <Center border="1px" borderRadius="md" p="1" color="blue">
                      <LockOutlinedIcon />
                    </Center>
                  }
                  variant="ghost"
                  justifyContent="start"
                >
                  Ganti Kata Sandi
                </Button>
              </Stack>
            </PopoverBody>

            <PopoverFooter border={0} pb={4}>
              <Button
                bg="red"
                color="white"
                w="full"
                _hover={{ opacity: 0.8 }}
                onClick={() => logout(access_token)}
              >
                Keluar
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

export default UserMenu;
