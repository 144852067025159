import {
  REMEMBER_SESSION_EXPIRY,
  SESSION_EXPIRY,
} from '@diamond/shared/environments';
import { Db } from '@diamond/shared/schema';
import { decodeJwt, JWTPayload } from 'jose';

import { convertJwtIatToIsoDate } from './date';

/**
 * Set token expiration time. Default expiration time is 12 hours,
 * when `remember` checked will expand to 15 days.
 *
 * @param token
 * @param remember
 * @returns
 */
export function generateJwtExpiryDate(token: string, remember = false): Date {
  const payload = jwtPayload(token);
  const expTime = remember
    ? Number(REMEMBER_SESSION_EXPIRY)
    : Number(SESSION_EXPIRY);
  const expiry = convertJwtIatToIsoDate(payload.iat!, expTime);

  return expiry;
}

interface CustomJWTPayload extends JWTPayload {
  id: string;
  role: Db['users']['role'];
  status: string;
  first_name: string;
  last_name: string;
  email_status: string;
  payment_method: string;
}

export function jwtPayload(token: string): CustomJWTPayload {
  return decodeJwt(token) as CustomJWTPayload;
}
