import { Db } from '@diamond/shared/schema';
import {
  useAuthentication,
  useAuthStore,
} from '@diamond/sol-admin/authentication';
import { getCurrentScope } from '@sentry/react';
import { ReactNode, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import ErrorPage from '../pages/ErrorPage';

export type ConditionalRouteProps = {
  /**
   * Must be true for the route to be created.
   * For example, `condition={isLoggedIn}`
   */
  condition: boolean;

  /** The route to redirect to if `condition` is false */
  redirectTo: string;

  children?: ReactNode;

  /** Captures any other props which might be passed in and applied to the internal <Route> */
  [key: string]: any;
};

/**
 * Only renders (or allows navigation to) a route if a condition is met.
 * Otherwise, it redirects to a different specified route.
 *
 * This is an abstract foundation for specific types of routing protection, including:
 * - [./ProtectedRoute.tsx](./ProtectedRoute.tsx)
 */
function ConditionalRoute({
  condition,
  redirectTo,
  children,
}: ConditionalRouteProps) {
  return condition ? <>{children}</> : <Navigate to={redirectTo} replace />;
}

interface ProtectedRouteProps {
  requiredRoles: Db['users']['role'][];
}

/**
 * A custom component which binds a specific condition (user is logged in),
 * and a specific redirect (access-denied), to the abstract `ConditionalRoute`
 */
export function ProtectedRoute({
  requiredRoles = ['super_admin'],
}: ProtectedRouteProps) {
  const {
    loggedIn,
    isAdmin,
    isCsKatalog,
    isCsCabang,
    isTokenValid,
    logoutTokenNotValid,
  } = useAuthentication();
  const { userRole, userId, userEmail } = useAuthStore();
  const navigate = useNavigate();
  const currentRole = userRole as Db['users']['role'];
  const isAuthenticated = loggedIn && (isAdmin || isCsKatalog || isCsCabang);
  const invalidRole = !requiredRoles.includes(currentRole);
  const tokenInvalid = isTokenValid !== 200 ? true : false;

  useEffect(() => {
    const scope = getCurrentScope();
    scope.setUser(isAuthenticated ? { id: userId, email: userEmail } : null);
  }, [userId, userEmail, isAuthenticated]);

  if (isAuthenticated && tokenInvalid) {
    return (
      <ErrorPage
        message="Sesi Anda telah berakhir"
        subtitle="Silahkan masuk ulang untuk melanjutkan"
        buttonTitle="Lanjutkan"
        action={() => logoutTokenNotValid()}
      />
    );
  } else if (isAuthenticated && invalidRole) {
    return (
      <ErrorPage
        message="Anda Tidak Memiliki Akses "
        subtitle="Silahkan hubungi customer service kami"
        buttonTitle="Kembali ke Beranda"
        action={() => navigate('/')}
      />
    );
  }

  return (
    <ConditionalRoute condition={isAuthenticated} redirectTo="/login">
      <Outlet />
    </ConditionalRoute>
  );
}

export default ProtectedRoute;
