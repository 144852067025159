import { As, Icon as ChakraIcon, IconProps } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface ChakraIconProps extends IconProps {
  as: As;
}

export function Icon({ as, ...props }: ChakraIconProps) {
  return <ChakraIcon as={as} {...props} />;
}

export default Icon;
