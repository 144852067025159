import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectSearch, { SelectSearchProps } from 'react-select-search';

export interface SelectAutocompleteProps extends SelectSearchProps {
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  labelProps?: FormLabelProps;
}

export function SelectAutocomplete({
  name,
  label,
  options,
  placeholder,
  defaultValue,
  onChange,
  labelProps,
  ...props
}: SelectAutocompleteProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <FormControl id={props.id} isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} htmlFor={props.id} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue ?? ''}
        render={({ field }) => {
          return (
            <SelectSearch
              {...props}
              {...field}
              search
              options={options}
              placeholder={placeholder}
              onChange={(value, options, snapshoot) => {
                field.onChange(value);
                if (onChange) onChange(value, options, snapshoot);
              }}
            />
          );
        }}
      />
      <FormErrorMessage textColor="red">
        {errorMessage as string}
      </FormErrorMessage>
    </FormControl>
  );
}
