import * as Yup from 'yup';

export const InviteUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email harus email yang valid')
    .required('Email harus di isi')
    .label('Email'),
  first_name: Yup.string().required('Nama depan harus di isi'),
  last_name: Yup.string().required('Nama belakang harus di isi'),
  shiptos: Yup.array()
    .min(1, 'Pilih salah satu ship to')
    .required('Pilih salah satu ship to')
    .nullable(),
});
