import { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      borderRadius: 'sm',
      borderColor: 'gray.75',
      _checked: {
        bg: 'azure',
        borderColor: 'azure',
      },
    },
  },
};
