import {
  AspectRatio,
  Box,
  Flex,
  Image as ChakraImage,
  Link,
  Spacer,
  Stack,
  Switch,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  BackofficePromoQuery,
  BackofficePromoResponse,
} from '@diamond/shared/types';
import { showToast, Text } from '@diamond/shared/ui';
import { promoValidFromToFormat } from '@diamond/shared/utils';
import {
  BACKOFFICE_PROMO_QUERY_KEY,
  updatePromoDetail,
  updateStatusBranda,
} from '@diamond/sol-admin-context';
import { useAuthStore } from '@diamond/sol-buyer-context';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface PromoItemProps {
  item: BackofficePromoResponse['data'][0];
  nomor: number;
}
export function PromoItem(props: PromoItemProps) {
  const session = useAuthStore();
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleChangeIsShowBeranda = useMutation({
    mutationFn: () =>
      updateStatusBranda(
        session.access_token,
        props.item.id,
        !props.item.is_display_on_homepage
      ),
    onSuccess: () => {
      if (!props.item.is_display_on_homepage === true) {
        showToast(toast, 'success', 'Berhasil update tampil di Banner Beranda');
      } else {
        showToast(
          toast,
          'success',
          'Berhasil update tidak tampil di Banner Beranda'
        );
      }
    },
    onError: (err) => {
      const error = err.message.substring(err.message.indexOf('422'));
      if (error === '422') {
        return showToast(
          toast,
          'error',
          'Jumlah banner promo sudah melebihi batas maksimum yang diizinkan. Batas maksimum adalah 12 banner'
        );
      }
      showToast(toast, 'success', err.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_PROMO_QUERY_KEY],
      });
    },
  });

  const handleChangeStatusInHomePage = useMutation({
    mutationFn: () =>
      updatePromoDetail(session.access_token, props.item.id, {
        is_enabled: !props.item.is_enabled,
        promo_name: props.item.promo_name,
        banner_url: props.item.banner_url,
        terms_and_conditions: props.item.terms_and_conditions,
      }),
    onSuccess: () => {
      if (!props.item.is_enabled === true) {
        showToast(toast, 'success', 'Berhasil update tampil di SOL');
      } else {
        showToast(toast, 'success', 'Berhasil update tidak tampil di SOL');
      }
    },
    onError: (err) => {
      showToast(toast, 'error', err.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_PROMO_QUERY_KEY],
      });
    },
  });
  const handleColorDotsStatus = (status: BackofficePromoQuery) => {
    switch (status) {
      case 'active':
        return '#00A34B';
      case 'inactive':
        return '#9747FF';

      default:
        return '#9747FF';
    }
  };
  return (
    <Flex alignItems="center" gap={4} px={2} maxW="container.xl">
      <Spacer />
      <Flex alignItems="center" fontSize="sm" gap="2" w="full">
        <Stack w={{ lg: '20%', xl: '18%' }}>
          <Box rounded="4xl" h="100px" mr="3">
            <AspectRatio ratio={1312 / 512} w="full" h="full">
              <ChakraImage
                rounded="md"
                w="100%"
                objectFit="cover"
                mt={1}
                alt="logo"
                src={
                  props.item.banner_url === null
                    ? '/assets/images/admin-banner-home.png'
                    : props.item.banner_url
                }
              />
            </AspectRatio>
          </Box>
        </Stack>
        <Stack w={{ lg: '12%', xl: '10%' }}>
          <Link
            href={`/promo/${props.item.id}`}
            wordBreak="break-all"
            textDecoration="ActiveBorder"
          >
            {props.item.promo_name}
          </Link>
        </Stack>
        <Tooltip label={props.item.mechanism}>
          <Stack w={{ lg: '19%', xl: '15%' }} fontWeight="medium">
            <Text noOfLines={2}>{props.item.mechanism}</Text>
          </Stack>
        </Tooltip>
        <Stack w={{ lg: '21%', xl: '19%' }}>
          <Text fontSize="sm">
            {promoValidFromToFormat(
              props.item.valid_from,
              props.item.valid_until
            )}
          </Text>
        </Stack>
        <Stack w={{ lg: '15%', xl: '12%' }} textDecoration="underline">
          <Link
            href={`/promo/${props.item.id}`}
            wordBreak="break-all"
            textDecoration="ActiveBorder"
          >
            {props.item.promo_code}
          </Link>
        </Stack>
        <Flex w={{ lg: '12%', xl: '12%' }}>
          <Box
            mt="7px"
            mr={2}
            width="10px"
            height="10px"
            backgroundColor={handleColorDotsStatus(props.item.status)} // Warna dot
            borderRadius="50%" // Membuatnya menjadi lingkaran
          />
          <Text>
            {props.item.status === 'active'
              ? 'Aktif'
              : props.item.status === 'inactive'
              ? 'Tidak Aktif'
              : 'Belum Aktif'}
          </Text>
        </Flex>
        <Stack w={{ lg: '12%', xl: '12%' }}>
          <Flex gap={3}>
            <Text>{props.item.is_enabled === true ? 'Ya' : 'Tidak'}</Text>

            <Switch
              size="md"
              bgColor="white"
              colorScheme="linkedin"
              name="show-in-sol"
              disabled={props.item.status !== 'active'}
              isChecked={props.item.is_enabled === true}
              onChange={() => {
                handleChangeStatusInHomePage.mutate();
                if (props.item.is_display_on_homepage === true) {
                  return handleChangeIsShowBeranda.mutate();
                }
              }}
            />
          </Flex>
        </Stack>{' '}
        <Stack w={{ lg: '12%', xl: '10%' }}>
          <Flex gap={3}>
            <Text>{props.item.is_display_on_homepage ? 'Ya' : 'Tidak'}</Text>

            <Switch
              size="md"
              bgColor="white"
              colorScheme="linkedin"
              name="show-in-beranda"
              isChecked={props.item.is_display_on_homepage}
              disabled={
                props.item.is_enabled === false &&
                props.item.status !== 'active'
              }
              onChange={() => handleChangeIsShowBeranda.mutate()}
            />
          </Flex>
        </Stack>
      </Flex>
      <Spacer />
    </Flex>
  );
}

export default PromoItem;
